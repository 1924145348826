import { communityApi } from 'store/services/communityApi'

const fetchQuery = async ({
  url,
  method = 'GET',
  headers = undefined,
  body = undefined
}) => {
  try {
    const result = await fetch(url, {
      method,
      ...(headers && { headers }),
      ...(body && { body })
    })

    if (!result.ok) {
      return {
        error: {
          status: result.status,
          data: await result.json()
        }
      }
    }

    const data = await result.json()
    return { data }
  } catch (error) {
    return {
      error: {
        status: 'FETCH_ERROR',
        data: error.message
      }
    }
  }
}

export const fileQuery = communityApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * 미디어 파일 업로드 (이미지 /동영상)
     * @param {object} params {
     *  "url": String
     *  "method": String
     *  "headers": Object
     *  "body": Formdata
     * }
     */
    postUploadMedia: build.mutation({
      queryFn: fetchQuery
    }),
    /**
     * 미디어 파일 정보 호출
     * @param {object} params {
     *  "url": String
     *  "method": String
     *  "headers": Object
     *  "body": Formdata
     * }
     */
    getMediaFile: build.query({
      queryFn: fetchQuery
    })
  })
})

export const { usePostUploadMediaMutation, useLazyGetMediaFileQuery } =
  fileQuery
