export const hideTextClip = `
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
`

export const hideTextIndent = `
  text-align: left;
  text-indent: -200%;
  white-space: nowrap;
  overflow: hidden;
  font-size: 0;
  color: transparent;
`

export const lineClamp = (line) => `
overflow: hidden;
text-overflow: ellipsis;
-webkit-line-clamp: ${line};
-webkit-box-orient: vertical;
word-wrap: break-word;
word-break: break-word;
display: -webkit-box;
`
export const ellipsis = `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const clearfix = `
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
`
