import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

// Component
import LayerWrapper from 'components/Popup/components/common/LayerWrapper'
import LayerBody from 'components/Popup/components/common/LayerBody'
import LayerHeader from 'components/Popup/components/common/LayerHeader'
import ReviewList from 'components/Store/Game/Review/ReviewList'

import { moveGameView } from 'routes/store/locations'

function AllReviewsPopup(props) {
  const { layerClose, gameInfo, ...rest } = props
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <LayerWrapper width="888px" maxHeight="720px">
      <LayerHeader
        type="large-modal"
        title={t('view all reviews')}
        onClickClose={() =>
          history.push({
            pathname: moveGameView({
              gameCode: gameInfo.game_code
            })
          })
        }
      />
      <LayerBody type="large-modal">
        <ReviewList gameInfo={gameInfo} {...rest} type="large-modal" />
      </LayerBody>
    </LayerWrapper>
  )
}

export default AllReviewsPopup
