import React, { useEffect, useRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Icons, ContainedButton, ActionButton } from '@purple/design/react'
import { buttonBlocking } from '@purple/common/utils/eventsUtility'

const Container = styled.div`
  width: 320px;
  background: ${({ theme }) => theme.color.bg300};
  border-radius: 8px;
  text-align: center;
  margin: auto;
  overflow: hidden;
  ${({ theme }) => theme.elevation['24']};

  &:focus {
    outline: none;
  }
`

const Header = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 4px 4px 0 4px;
  padding: 8px 0;
`
const ContentWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 72px;
  margin: 0 16px;
`

const Content = styled.p`
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: pre-line;
  ${({ theme }) => theme.typography.body3};
  color: ${({ theme }) => theme.color.glyphs200};
`

const ButtonWrap = styled.div`
  display: flex;
  padding: 16px;
  width: 100%;

  & > button {
    width: 100%;
  }

  & > button:nth-of-type(2) {
    margin-left: 8px;
  }
`

const SystemPopup = (props) => {
  const {
    layerClose,
    onLeft,
    onRight,
    type = 'alert',
    msg,
    showCloseIcon = true
  } = props

  const containerRef = useRef()
  const { t } = useTranslation()
  const { leftButtonLabel, rightButtonLabel } = useMemo(() => {
    const leftLabel = props.leftButtonLabel || t('cancel')
    const rightLabel = props.rightButtonLabel || t('confirm')

    switch (type) {
      case 'error':
      case 'self':
        return {
          leftButtonLabel: leftLabel,
          rightButtonLabel: rightLabel
        }
      case 'confirm':
        return { leftButtonLabel: leftLabel, rightButtonLabel: rightLabel }
      case 'alert':
      default:
        return { leftButtonLabel: '', rightButtonLabel: rightLabel }
    }
  }, [props.leftButtonLabel, props.rightButtonLabel, type, t])

  const isShowButton = !!(leftButtonLabel || rightButtonLabel)

  const close = () => {
    layerClose()
  }
  const onClickLeft = () => {
    if (onLeft) {
      onLeft()
    }
    close()
  }
  const onClickRight = () => {
    if (onRight) {
      onRight()
    }
    close()
  }
  const onClickClose = () => {
    if (type === 'alert' && onRight) {
      onRight()
    } else if (type === 'confirm' && onLeft) {
      onLeft()
    }
    close()
  }

  useEffect(() => {
    containerRef.current && containerRef.current.focus()
  }, [])

  return (
    <Container tabIndex="0" ref={containerRef}>
      <Header>
        {showCloseIcon && (
          <ActionButton type="basic" size="medium" onClick={onClickClose}>
            <Icons name="Close" />
          </ActionButton>
        )}
      </Header>
      <ContentWrap>
        <Content
          dangerouslySetInnerHTML={{
            __html: msg || ''
          }}
        />
      </ContentWrap>
      {isShowButton && (
        <ButtonWrap>
          {leftButtonLabel && (
            <ContainedButton
              type="tertiary"
              size="mediumFlexible"
              onClick={onClickLeft}
            >
              <span>{leftButtonLabel}</span>
            </ContainedButton>
          )}
          {rightButtonLabel && (
            <ContainedButton
              type={type === 'error' ? 'error' : 'primary'}
              size="mediumFlexible"
              onClick={
                type === 'confirm'
                  ? buttonBlocking({
                      func: onClickRight,
                      delay: 1500,
                      key: 'confirmPopup'
                    })
                  : onClickRight
              }
            >
              <span>{rightButtonLabel}</span>
            </ContainedButton>
          )}
        </ButtonWrap>
      )}
    </Container>
  )
}

export default SystemPopup
