import { checkLauncherVersion } from 'native/nativeUtility'

/**
 * 퍼플 스타일 정의
 * 사용 예시
 * height: `calc(100% - ${styleConfig.topBar.height}px)`};
 */
export const styleConfig = {
  // 네이티브
  native: {
    launcher: {
      lnb: {
        width: 200
      },
      solidLnb: {
        width: 200
      },
      install: {
        height: 172
      },
      topbar: {
        height: 36
      }
    }
  },
  // 퍼플 스토어
  store: {
    mobileBanner: {
      height: 68
    },
    cartBottomStickey: {
      height: 80
    },
    gameTopMenu: {
      height: 64
    },
    gameTabMenu: {
      height: 57
    }
  }
}
