import * as pathToRegexp from 'path-to-regexp'
import { STORE_PATH } from 'routes/store/path'
import { APP_PATH } from 'routes/path'
import { isPurpleApp } from '@purple/common/utils/checkUtility'

// 스토어 메인 이동
export const moveMain = (param) => {
  const location = pathToRegexp.compile(
    isPurpleApp ? APP_PATH.ROOT : APP_PATH.STORE
  )
  return location(param)
}

// 게임 상세 이동
export const moveGameView = (param) => {
  const location = pathToRegexp.compile(STORE_PATH.GAME)
  return location(param)
}

// DLC 상품 상세 이동
export const moveGoodsView = (param) => {
  const location = pathToRegexp.compile(STORE_PATH.GOODS)
  return location(param)
}

// 묶음 상품 상세 이동
export const moveBundleView = (param) => {
  const location = pathToRegexp.compile(STORE_PATH.BUNDLE)
  return location(param)
}

// 주문서 페이지 이동
export const moveOrderView = (param) => {
  const location = pathToRegexp.compile(STORE_PATH.ORDER)
  return location(param)
}
