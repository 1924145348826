import React from 'react'

// Style
import styled from 'styled-components'

// Component
import { Icons, Thumbnail, Checkbox } from '@purple/design/react'
import OverlayScroll from 'components/Common/OverlayScroll'

// Utils
import { onError, defaultProfileImage } from 'utils/imageUtility'

const ScrollWrap = styled.div`
  flex: 1;
  padding: 0 16px;
`

const UserList = styled.ul``

const Item = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  padding: 0 8px;
  border-radius: 4px;

  &:not(:last-child) {
    margin-bottom: 4px;
  }

  ${({ isDisabled, theme }) =>
    !isDisabled &&
    `
    cursor: pointer;
    &:hover {
      background-color: ${theme.color.bright100};
    }
  `};
`

const SelectItem = styled(Item)`
  ${({ isDisabled, isSelected, theme }) =>
    isDisabled
      ? `
    & > svg {
      fill: ${theme.color.primaryDisable};
    }
  `
      : `
    & > svg {
      fill:  ${isSelected ? theme.color.primary : 'none'};
    }
    &:hover {
      & > svg {
        fill: ${isSelected ? theme.color.primary : theme.color.bright200};
      }
    }
  `}
`
const SelectItemFileShare = styled(SelectItem)`
  padding: 0 11px 0 8px;
`

const User = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
  opacity: ${({ isDisabled }) => (isDisabled ? 0.3 : 1)};
`

const Thumb = styled.div`
  display: flex;
  flex-shrink: 0;
  margin-right: 8px;
`

const Name = styled.span`
  ${({ theme }) => theme.typography.subtitle2};
  color: ${({ theme }) => theme.color.glyphs200};
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
`

const SelectUserItem = ({ item, onSelect, isSelected, isJoined = false }) => {
  return (
    <SelectItem
      isDisabled={isJoined}
      isSelected={isSelected}
      onClick={isJoined ? null : () => onSelect(item)}
    >
      <User isDisabled={isJoined}>
        <Thumb>
          <Thumbnail
            type="character"
            size="200"
            imgBg="point7"
            alt=""
            onError={onError}
            src={item.profileImageUrl || defaultProfileImage}
          />
        </Thumb>
        <Name>{item.characterName}</Name>
      </User>
      <Icons name="Check" width="24" height="24" />
    </SelectItem>
  )
}

const FileShareUserItem = ({ item, onSelect, isSelected }) => {
  return (
    <SelectItemFileShare isSelected={isSelected} onClick={() => onSelect(item)}>
      <User>
        <Thumb>
          <Thumbnail
            type="character"
            size="200"
            imgBg="point7"
            alt=""
            onError={onError}
            src={item.profileImageUrl || defaultProfileImage}
          />
        </Thumb>
        <Name>{item.characterName}</Name>
      </User>
      <Checkbox checked={isSelected} />
    </SelectItemFileShare>
  )
}

const GuildUserList = (props) => {
  const { type, myUserInfo, guildUserList, selectedList, onSelectCharacter } =
    props

  return (
    <OverlayScroll>
      <ScrollWrap>
        <UserList>
          {guildUserList.map((item, index) => {
            const { gameUserId } = myUserInfo
            const isMyCharacter = gameUserId === item.gameUserId
            const isSelected = selectedList.some((prop) => {
              return prop.characterKey === item.characterKey
            })
            const isJoined = item.joined || false

            // 내 캐릭터 혈맹목록에서 제외
            if (isMyCharacter) {
              return null
            }

            switch (type) {
              case 'zoneFileShare':
                return (
                  <FileShareUserItem
                    key={index}
                    item={item}
                    isSelected={isSelected}
                    onSelect={onSelectCharacter}
                  />
                )
              case 'searchInvite':
              case 'create':
              default:
                return (
                  <SelectUserItem
                    key={index}
                    item={item}
                    isSelected={isSelected}
                    isJoined={isJoined}
                    onSelect={onSelectCharacter}
                  />
                )
            }
          })}
        </UserList>
      </ScrollWrap>
    </OverlayScroll>
  )
}

export default GuildUserList
