import { createGlobalStyle } from 'styled-components'
import { Config as config } from '@purple/common/utils/globalUtility'
import { getFontFamily } from '@purple/common/utils/locationUtility'

const envNameCode = `
&::before{
  position: fixed;
  content: '${config.envName || ''} ${config['build.buildNumber'] || ''}';
  top: 0;
  left: 50%;
  color: #fff;
  text-shadow: 1px 1px 1px #000;
  z-index: 1000000;
  font-size: 11px;
  transform: translateX(-50%);
}
`
export const CommonCSS = createGlobalStyle`
  html {
      box-sizing: border-box;
      line-height: 1.5;
      height: 100%;
      -webkit-tap-highlight-color: ragb(0,0,0,0.5);
  }
  body {
    font-family: ${getFontFamily()};
    height: 100%;
    width: 100%;
    ${
      config?.envName === 'live' || config?.envName === 'fgt-rc'
        ? ''
        : envNameCode
    }
  }
  *{
    margin:0;
    padding:0;
  }
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  .hidden {
    display: none;
  }

  h1,h2,h3,h4,h5,h6{
    font-weight:normal;
  }
  button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    -webkit-font-smoothing: inherit;
    cursor: pointer;
  }
  figure  {
    display: block;
    margin: 0;
    padding: 0;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  p {
    margin: 0;
  }

  optgroup:focus, textarea:focus, button:focus, select:focus, input:focus, a:focus {
    outline: none;
  }

  /* input 기본 스타일 초기화 */
  input {
    margin:0;
    border:0;
    padding:0;
    display:inline-block;
    vertical-align:middle;
    white-space:normal;
    background:none;
    line-height:1;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  a {
    -webkit-user-drag: none;
  }
  /* a 기본 스타일 초기화 */
  a:link, a:visited {
    color: inherit;
    text-decoration: none;
  }
  a:active, a:hover {
    color: inherit;
    text-decoration: none;
  }
    
  /* textarea 기본 스타일 초기화 */
  textarea {
    border: 0;
    background: none;
    resize: none;
    overflow: overlay;
  }
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color:rgba(255,255,255,0.35);
    font-size: '13px';
    font-weight: 'normal';
  }
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color:rgba(255,255,255,0.35);
      font-size: '13px';
      font-weight: 'normal';
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
      color:rgba(255,255,255,0.35);
      font-size: '13px';
      font-weight: 'normal';
  }

  /* em 기본 스타일 초기화 */
  em ,i{
    font-style: normal;
  }
  
  /* table 기본 스타일 초기화 */
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  svg {
    flex-shrink: 0;
  }

  img{
    image-rendering: -webkit-optimize-contrast;
    /* MEMO: 퍼플라이브 시청페이지 썸네일 렌더링 이슈로 그래픽 가속 사용하는 속성 주석합니다. */
    /* transform: translateZ(0);
    backface-visibility: hidden; */
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

/* IE10 이상에서 input box 에 추가된 지우기 버튼 제거 */
input::-ms-clear { display: none; }

/* input type number 에서 화살표 제거 */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
  
/* scroll */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

html:hover::-webkit-scrollbar,
*:hover::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-resizer{
  display: none;
}

::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  border-radius: 5px;
  background-color: rgba(131, 137, 153, 0.3);

  &:horizontal {
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 0;
    border-right: 0;
  }
}

#root {
  height: 100%;
}

  /* Sortable */
  .SortableHelper {
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.3);
    cursor: row-resize;
  }
  /* Sortable 개선 */
   .talk__channel--sortable-acctive {
    z-index: 650;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 8%);
    cursor: grab !important;
  }
  /* Sortable 퍼플톡셋팅 */
  .talk__setting--sortable-active {
    z-index: 7000;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 8%);
    cursor: grab !important;
  }

  .popper-container[data-popper-reference-hidden] {
    visibility: hidden;
    pointer-events: none;
  }

  /* ellipsis */
  .ellipsis {
    overflow: hidden; 
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap:break-word;
    word-break:break-all;
  }

  .modal-wrap-container {
    outline: none;
  }
`
