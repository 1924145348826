import React, { useMemo } from 'react'
import { ThemeProvider } from 'styled-components'
import { Theme, PopupManager } from '@purple/design/react'

// Context
import { isStorePage } from 'utils/storeServiceUtility'
import { ToastProvider } from 'context/common/ToastContext'

import { useSelector, shallowEqual } from 'react-redux'

const PopupManagerContainer = () => {
  const { storeTheme } = useSelector(
    (state) => ({
      storeTheme: state.storeSlice.storeTheme
    }),
    shallowEqual
  )

  const theme = useMemo(() => {
    if (isStorePage()) {
      return storeTheme
    } else {
      return 'dark'
    }
  }, [storeTheme])

  return (
    <ThemeProvider theme={Theme(theme)}>
      <ToastProvider>
        <PopupManager />
      </ToastProvider>
    </ThemeProvider>
  )
}

export default PopupManagerContainer
