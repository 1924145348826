import { confirmPopup } from 'components/Popup'

class UiAlert {
  constructor() {
    console.log('- purple UiAlert ')
  }

  open = ({ title = '' }) => {
    confirmPopup({
      msg: title,
      type: 'alert'
    })
  }
}

class UiConfirm {
  constructor() {
    console.log('- purple UiConfirm ')
  }
  open = ({ title = '' }) => {
    return new Promise((resolve, reject) => {
      try {
        confirmPopup({
          msg: title,
          type: 'confirm',
          onRight: () => {
            resolve({ isConfirmed: true, isCancel: false })
          },
          onLeft: () => {
            resolve({ isConfirmed: false, isCancel: true })
          }
        })
      } catch (e) {
        reject()
      }
    })
  }
}

if (!window.ncui) {
  window.ncui = {
    modal: {}
  }
}
window.ncui.modal.uiAlert = UiAlert
window.ncui.modal.uiConfirm = UiConfirm

export default UiAlert
