import { isWeb, checkEnabledStatus } from '@purple/common/utils/checkUtility'
import { COUNTRY_TYPE } from '@purple/common/constants/locationTypes'

/* 광고 스크립트 관련 유틸 */

// 광고 스크립트 연결 가능 조건: 한국 IP 웹에서만 삽입 + 설정
const isAdScriptEnabled =
  document.querySelector('html').getAttribute('data-country') ===
    COUNTRY_TYPE.KR &&
  isWeb &&
  checkEnabledStatus('adScriptEnabled')

// 공통 스크립트 추가
export const appendAdScript = () => {
  if (isAdScriptEnabled) {
    // gtag 공통 스크립트
    const element = document.querySelector('head')
    const gScript = document.createElement('script')
    gScript.async = true
    gScript.src = 'https://www.googletagmanager.com/gtag/js?id=AW-16690224299'
    gScript.onload = () => {
      window.dataLayer = window.dataLayer || []
      gtag()
      gtag('js', new Date())
      gtag('config', 'AW-16690224299')
      gtag('event', 'conversion', {
        send_to: 'AW-16690224299/yev3CO2KpdAZEKu5wpY-'
      })
    }
    element.appendChild(gScript)

    // 카카오 픽셀 공통 스크립트
    const kScript = document.createElement('script')
    kScript.type = 'text/javascript'
    kScript.charset = 'UTF-8'
    kScript.src = '//t1.daumcdn.net/kas/static/kp.js'
    kScript.onload = () => {
      window.kakaoPixel && window.kakaoPixel('1565494274904668791').pageView()
    }
    element.appendChild(kScript)
  }
}

// gtag
export function gtag() {
  isAdScriptEnabled && window.dataLayer?.push(arguments)
}
