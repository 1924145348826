import React from 'react'

// Style
import styled from 'styled-components'
import { Icons, CircleButton } from '@purple/design/react'
import { media } from 'assets/styles/media'

// hooks
import useScrollPosition from 'hooks/store/useScrollPosition'

const Button = styled.span`
  position: absolute;
  bottom: ${({ type }) => (type === 'page' ? '48px' : '16px')};
  right: 16px;
  transition: opacity 300ms ease-in;
  opacity: ${({ isShow }) => (isShow ? 1 : 0)};
  visibility: ${({ isShow }) => (isShow ? 'visible' : 'hidden')};

  ${media.tablet`
    bottom: ${(type) => (type === 'page' ? '10px' : '16px')};
  `};
`

const TopButton = ({
  type,
  scroll = true,
  scrollRef = null,
  showTopButton = false,
  ...otherProps
}) => {
  const { animateScrollTop } = useScrollPosition({ scrollRef })

  return (
    <Button onClick={animateScrollTop} isShow={showTopButton} type={type}>
      <CircleButton type="basic" size="medium">
        <Icons name="ChevronUp" width="16" height="16" />
      </CircleButton>
    </Button>
  )
}

export default TopButton
