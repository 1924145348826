import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// RTK
import { appActions } from 'store/features/appSlice'

// Constants

// Utils
import SessionCheck from 'utils/sessionCheckUtility'

/**
 * 공통 프로세스
 * @description 앱 단위에서 공통으로 실행해야하는 로직
 * @return appProcessTrigger
 */
const useAppProcess = () => {
  const dispatch = useDispatch()

  // 서버시간으로 현재시간 셋팅
  const setSeverDateTime = useCallback(() => {
    const currentTime = window.NGP_VARS
      ? new Date(window.NGP_VARS.app.server.datetime)
      : new Date()
    dispatch(appActions.setCurrentTimeInfo({ currentTime }))
  }, [dispatch])

  const appProcessTrigger = useCallback(() => {
    setSeverDateTime()
  }, [setSeverDateTime])

  return {
    appProcessTrigger
  }
}

/**
 * 페이지별 프로세스
 * @description 앱 실행 시 최초 진입한 페이지 기준으로 필요한 로직
 * @param {object} params {
 *  sessionCheck: 세션 체크 인터벌 실행
 * }
 */
const usePageProcess = ({ sessionCheck = false }) => {
  const pageProcessStatus = useSelector(
    (state) => state.appSlice.pageProcessStatus
  )

  const dispatch = useDispatch()

  const processTrigger = useCallback(async () => {
    try {
      // 세션체크 인터벌
      if (sessionCheck) {
        SessionCheck.startInterval()
      }

      dispatch(appActions.setPageProcessStatus(true))
    } catch {
      console.log('PageProcess error')
      dispatch(appActions.setPageProcessStatus(false))
    }
  }, [dispatch, sessionCheck])

  useEffect(() => {
    if (!!!pageProcessStatus) {
      console.log(`PageProcess init : sessionCheck ${sessionCheck}`)
      processTrigger()
    }
  }, [])
}

export { useAppProcess, usePageProcess }
