export const PURPLE_BOARD_NOTICE_PATH = {
  LIST: '/notice',
  VIEW: '/notice/:articleId'
}

export const PURPLE_BOARD_FAQ_PATH = {
  LIST: '/faq',
  VIEW: '/faq/:articleId'
}

export const GAME_BOARD_STORE_PATH = {
  LIST: '/game/:gameCode/board/:boardAlias/list',
  VIEW: '/game/:gameCode/board/:boardAlias/view/:articleId',
  WRITE: '/game/:gameCode/board/:boardAlias/write',
  EDIT: '/game/:gameCode/board/:boardAlias/edit/:articleId'
}

export const GAME_BOARD_LAUNCHER_PATH = {
  LIST: '/publish/:gameCode/board/:boardAlias/list',
  VIEW: '/publish/:gameCode/board/:boardAlias/view/:articleId',
  WRITE: '/publish/:gameCode/board/:boardAlias/write',
  EDIT: '/publish/:gameCode/board/:boardAlias/edit/:articleId'
}
