import i18n from 'i18next'
// Components
import { confirmPopup } from 'components/Popup'
// Utils
import { sendCustomEvent } from 'utils/customEventUtility'
// Constants
import { CUSTOM_EVENT_KEY } from 'constants/customEventTypes'

/**
 * 클립보드 복사
 * @param text
 */

export async function copyText(text) {
  if (navigator.clipboard) {
    await _clipboardApi(text)
  } else {
    await _clipboardApiFallback(text)
  }

  return text
}

// clibboardApi 사용
async function _clipboardApi(text) {
  // 사파리는 navigator.permissions이 없음.
  // navigator.permissions 를 체크하는 이유는 iframe 상태에서는 permission이 허용 상태가 아니기 때문이다.

  if (navigator.permissions) {
    let permission = await navigator.permissions.query({
      name: 'clipboard-write'
    }) // https://github.com/microsoft/TypeScript-DOM-lib-generator/issues/1245

    // permission.state가 granted인 상태에서만 navigator.clipboard에 접근 가능
    // 스크립트가 실행되는 window가 브라우저에서 활성화된 탭이라면 자동 허용상태 이지만,
    if (permission.state === 'granted') {
      try {
        await navigator?.clipboard?.writeText(text)

        sendCustomEvent(CUSTOM_EVENT_KEY.SHOW_TOAST, {
          message: i18n.t('copied url'),
          position: 'bottom'
        })
      } catch (err) {
        await _clipboardApiFallback(text)
      }

      // iframe 은 별도로 설정해 주지 않으면 비허용 상태이거나, 퍼플 인베디드 인경우 비허용 상태이다.
      // 즉, 분기를 태운이유는 iframe 때문임.
    } else {
      await _clipboardApiFallback(text)
    }
  } else {
    // 구형 IOS safari와 MAC safari가 navigator.permissions 이 없다.
    // navigator.clipboard가 있는데 navigator.permissions이 없으면 그냥 바로 navigator.clipboard 실행
    try {
      // (추가) webView(퍼플 인베디드) 는 navigator.permission 이 undefined 이면서, navigator.clipboard 은 또 사용이 가능하다.
      // 는 navigator.permission이 undefied 이니까 permission 도 받지 못하고 따라서 clipboad.wirteText 를 사용지 못한다.
      // https://stackoverflow.com/questions/61243646/clipboard-api-call-throws-notallowederror-without-invoking-onpermissionrequest
      await navigator?.clipboard?.writeText(text)

      sendCustomEvent(CUSTOM_EVENT_KEY.SHOW_TOAST, {
        message: i18n.t('copied url'),
        position: 'bottom'
      })
    } catch (err) {
      await _clipboardApiFallback(text)
    }
  }
}

// clipboardApi 를 쓸수 없는 경우
async function _clipboardApiFallback(text) {
  // 임시 textarea 생성
  const textArea = document.createElement('textarea')
  textArea.style.position = 'fixed'
  textArea.style.top = '-9999px'
  textArea.style.left = '-9999px'
  textArea.style.width = '2em'
  textArea.style.height = '2em'
  textArea.value = text
  textArea.contentEditable = 'true' // IOS 대응
  textArea.readOnly = true // IOS 키보드 올라오는 방지

  document.body.appendChild(textArea)

  // range 객체 생성
  const range = document.createRange()
  range.selectNodeContents(textArea)

  // selection 객체 생성
  const selection = window.getSelection()
  selection.removeAllRanges()
  selection.addRange(range)

  // IOS, Edge 등 input/textarea에 selection객체가 잘 동작안하는것 대응
  // https://stackoverflow.com/questions/34045777/copy-to-clipboard-using-javascript-in-ios
  textArea.focus()
  // textArea.select()
  textArea.setSelectionRange(0, 999999)

  try {
    document.execCommand('copy')

    sendCustomEvent(CUSTOM_EVENT_KEY.SHOW_TOAST, {
      message: i18n.t('copied url'),
      position: 'bottom'
    })
  } catch (e) {
    sendCustomEvent(CUSTOM_EVENT_KEY.SHOW_TOAST, {
      message: i18n.t(
        'an error has occurred. Please try again in a few minutes.'
      ),
      position: 'bottom'
    })
  } finally {
    document.body.removeChild(textArea)
  }
}

// lime api 공통 에러함수
export const limeErrorAlert = (error, callbackFunc) => {
  const viewMessage = error?.response
    ? error?.response?.data?.viewMessage // axios 에러응답
    : error?.data?.viewMessage // rtk-query 에러응답

  confirmPopup(
    {
      type: 'alert',
      msg: viewMessage ? viewMessage.message : i18n.t('error occurred'),
      onRight: callbackFunc || null
    },
    { stringKey: 'limeErrorAlert' }
  )
}
