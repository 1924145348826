import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import reducer, { rootQuery } from 'store'

// create store
// const sagaMiddleware = createSagaMiddleware()
const configure = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false
      //process.env.NODE_ENV !== 'production'
    }).concat(rootQuery)
})

setupListeners(configure.dispatch)

export default configure
