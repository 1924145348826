import React, { useEffect, useCallback, useRef } from 'react'
import styled from 'styled-components'

// Utils
import { Icons, ActionButton, Thumbnail } from '@purple/design/react'

import { onError, defaultProfileImage } from 'utils/imageUtility'

// Component
import OverlayScroll from 'components/Common/OverlayScroll'

const BoxWrapper = styled.div`
  margin-bottom: 24px;
  border-bottom: ${({ theme }) => `1px solid ${theme.color.bright200}`};
  flex-shrink: 0;
  padding-bottom: 12px;
`

const ScrollWrap = styled.div`
  padding: 0 4px;
  height: 100%;
  max-height: 80px;
  flex: 1;
`

const List = styled.ul`
  padding: 0 20px;
`

const Item = styled.li`
  height: 32px;
  display: inline-flex;
  align-items: center;
  padding: 0 8px 0 4px;
  border: ${({ theme }) => `1px solid ${theme.color.bright200}`};
  border-radius: 70px;
  margin-right: 8px;
  margin-bottom: 4px;

  &:hover {
    background-color: ${({ theme }) => theme.color.bright100};
  }
`

const Name = styled.div`
  ${({ theme }) => theme.typography.body4};
  color: ${({ theme }) => theme.color.glyphs200};
  margin: 0 4px;
`

const SelectedItemBox = (props) => {
  const { selectedItems, deleteItem } = props
  const scrollboxRef = useRef()
  const prevSelectedItemLength = useRef()

  // 선택된 캐릭터 리스트에서 삭제
  const handleClickDelete = useCallback(
    (e, item) => {
      e.stopPropagation()
      deleteItem(item)
    },
    [deleteItem]
  )

  // 새로 추가될 때 스크롤 하단 이동
  useEffect(() => {
    if (
      prevSelectedItemLength.current !== selectedItems.length &&
      prevSelectedItemLength.current < selectedItems.length
    ) {
      scrollboxRef.current.scrollTop = scrollboxRef.current.scrollHeight
    }
    prevSelectedItemLength.current = selectedItems.length
  }, [selectedItems])

  return (
    <BoxWrapper>
      <OverlayScroll>
        <ScrollWrap ref={scrollboxRef}>
          <List>
            {selectedItems.map((item, index) => (
              <Item key={index}>
                <Thumbnail
                  type="character"
                  size="100"
                  imgBg="point7"
                  alt=""
                  onError={onError}
                  src={item.profileImageUrl || defaultProfileImage}
                />
                <Name>{item.characterName}</Name>
                <ActionButton
                  type="basicSub"
                  size="small"
                  onClick={(e) => handleClickDelete(e, item)}
                >
                  <Icons name="Close" />
                </ActionButton>
              </Item>
            ))}
          </List>
        </ScrollWrap>
      </OverlayScroll>
    </BoxWrapper>
  )
}

export default SelectedItemBox
