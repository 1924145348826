import React, { useCallback, useEffect, useMemo } from 'react'

// RTK
import {
  useGetSubCategoriesQuery,
  usePostArticleMutation
} from 'store/services/query/boardQuery'

// Components
import BoardForm from 'components/Board/Common/Core/BoardForm'

// Hooks
import { useParams, useHistory, useLocation } from 'react-router-dom'
import useSearchParams from 'hooks/common/useSearchParams'

// Utils
import { commonBoardError, removePrevBoardForm } from 'utils/boardUtilsForStore'
import { getLocale } from '@purple/common/utils/commonUtility'

const BoardWriteContainer = ({
  boardType,
  serviceAlias = 'purple',
  layoutType = ''
}) => {
  const searchParams = useSearchParams()
  const { boardAlias, gameCode } = useParams()
  const history = useHistory()

  const boardForm = useMemo(() => {
    const boardForm = JSON.parse(sessionStorage.getItem('board-form') || '{}')
    if (searchParams.get('isExpiredSession')) {
      removePrevBoardForm()
      return boardForm
    }
  }, [])

  // query
  const {
    data: categoryData,
    error: categoryError,
    isSuccess: isCategorySuccess,
    isError: isCategoryError
  } = useGetSubCategoriesQuery({
    serviceAlias,
    boardAlias,
    categoryAlias: `${boardAlias}_${gameCode}` || ''
  })

  const [postArticleTrigger, result] = usePostArticleMutation()

  const categoryList = useMemo(() => {
    return (
      categoryData?.map((category) => {
        return {
          alias: category.board?.board_alias,
          name:
            category.board?.board_name_i18_n?.[getLocale()] ||
            category.board?.board_name_i18_n?.['en-US'],
          id: category.board?.id
        }
      }) || []
    )
  }, [categoryData])

  const handleSubmit = useCallback(
    async ({ title, contents, selectedBoardAlias }) => {
      const category = categoryList.find(
        (category) => category.alias === selectedBoardAlias
      )

      if (!category) {
        throw new Error('not defined category_id')
      }

      return await postArticleTrigger({
        serviceAlias,
        boardAlias,
        params: {
          article: {
            title,
            contents
          },
          category_id: category.id,
          parent_article_id: null
        }
      }).unwrap()
    },
    [postArticleTrigger, serviceAlias, boardAlias, isCategorySuccess]
  )

  const handleCancel = useCallback(() => {
    history.goBack()
  }, [])

  useEffect(() => {
    if (isCategoryError) {
      commonBoardError(categoryError).execute()
      history.goBack()
    }
  }, [isCategoryError])

  return (
    <>
      {isCategorySuccess && (
        <BoardForm
          layoutType={layoutType}
          boardType={boardType}
          categoryList={categoryList}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          defaultContents={boardForm?.contents}
          defaultTitle={boardForm?.title}
          defaultBoardAlias={''}
          loading={result.isLoading}
        />
      )}
    </>
  )
}

export default BoardWriteContainer
