import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Icons } from '@purple/design/react'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'

// Style
import { media } from 'assets/styles/media'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  border-radius: 4px;

  ${media.tablet`
  margin-top: 20px;
  `};

  &:first-child {
    margin-top: 0;
  }
`

const ImgWrraper = styled.div`
  width: 64px;
  height: 64px;
  margin-right: 16px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color.bright300};

  ${media.phone`
    width: 64px;
    height: 64px;
    margin-right: 12px;
  `};
  img {
    border: 1px solid ${({ theme }) => theme.color.bright300};
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
  }
  svg {
    fill: ${({ theme }) => theme.color.bright300};
  }
`

const InfoWrraper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .title {
    padding: 8px 0;
    ${({ theme }) => theme.typography.subtitle3};
    color: ${({ theme }) => theme.color.glyphs200};
    &.disabled {
      color: ${({ theme }) => theme.color.glyphs300};
    }
    ${media.phone`
      padding: 0;
    `};
  }
  .description {
    padding-bottom: 8px;
    color: ${({ theme }) => theme.color.glyphs250};
    ${({ theme }) => theme.typography.body4};
    &.disabled {
      color: ${({ theme }) => theme.color.glyphs300};
    }
    .dot-web {
      margin: 0 9px;
    }
    .info-mob,
    .percent-info-mob {
      display: none;
    }
    .info-mob {
      margin-top: 2px;
    }
    .percent-info-mob {
      color: ${({ theme }) => theme.color.glyphs300};
      margin-top: 4px;
    }

    ${media.phone`
      padding-bottom: 0px;
      .dot-web,.info-web,.percent-info-web{
        display:none;
      }
      .info-mob,
      .percent-info-mob {
        display: block;
      }
    `};
  }
`

const CheckMark = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-left: 40px;
  background-color: ${({ theme }) => theme.color.primaryDisable};
  ${media.phone`
    margin-left: 12px;
    width: 24px;
    height: 24px;
  `};
  svg {
    fill: ${({ theme }) => theme.color.glyphs600Disable};
    width: 16px;
    height: 16px;
  }
  &.checked {
    background-color: ${({ theme }) => theme.color.primary};
    svg {
      fill: ${({ theme }) => theme.color.glyphs600};
    }
  }
`

function AchievementsItem(props) {
  const { t } = useTranslation()
  const { item, onClickItem, index, isNotLogin } = props
  const [isThumbnailError, setIsThumbnailError] = useState(false)

  const isChecked = useMemo(() => {
    //미로그인 or 미구매시
    if (isNotLogin || !item.hasLaunched) {
      return true
    }
    //로그인 and 구매시
    return item.isFinished
  }, [item, isNotLogin])

  return (
    <Wrapper
      index={index}
      onClick={() => {
        if (onClickItem) {
          onClickItem(item)
        }
      }}
      className="achievement-item-wrap"
    >
      <ImgWrraper>
        {isThumbnailError === false && item.thumbnailUrl ? (
          <img
            src={item.thumbnailUrl}
            alt=""
            onError={(e) => {
              setIsThumbnailError(true)
            }}
          />
        ) : (
          <Icons name="PurpleLogotype" width="50" height="10" />
        )}
      </ImgWrraper>

      <InfoWrraper>
        <div className={cn('title', { disabled: !isChecked })}>
          {item.name || item.title}
        </div>
        <div className={cn('description', { disabled: !isChecked })}>
          <span className="info-web">{item.description}</span>
          <span className="dot-web">·</span>
          <span className="percent-info-web">
            {t('achieved by percent of gamers', { rate: item.rate })}
          </span>
          <div className="info-mob">{item.description}</div>
          <div className="percent-info-mob">
            {t('achieved by percent of gamers', { rate: item.rate })}
          </div>
        </div>
      </InfoWrraper>
      {isNotLogin === false && item.hasLaunched && (
        <CheckMark className={cn({ checked: isChecked })}>
          <Icons name="Check" />
        </CheckMark>
      )}
    </Wrapper>
  )
}

export default AchievementsItem
