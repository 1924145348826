import { useLayoutEffect, useState } from 'react'

// Utils
import { createPortalNode } from '@purple/common/utils/commonUtility'

function usePortal(portalId, ...arg) {
  const [portalElement, setPortalElement] = useState(null)

  useLayoutEffect(() => {
    const portalNode = createPortalNode(portalId, ...arg)
    setPortalElement(portalNode)
    return () => {
      if (
        !portalNode?.hasChildNodes() &&
        portalNode.parentNode.id !== 'pageWrap'
      ) {
        portalNode?.remove()
      }
    }
  }, [portalId])

  return { portalElement }
}

export default usePortal
