import { isWeb } from '@purple/common/utils/checkUtility'

export const STORE_PATH = {
  INDEX: isWeb ? '/' : '/main',
  NOTICE: '/notice/:articleId?',
  FAQ: '/faq/:articleId?',
  GAME: '/game/:gameCode/:gameMenu?/:boardAlias?/:boardMenu?/:articleId?', // 게임 타이틀 상세
  GOODS: '/goods/:gameCode/:goodsId/:gameMenu?', // dlc 상세
  BUNDLE: '/bundle/:goodsId', // 묶음 상품 상세
  FAVORITE: '/favorite', // 찜 목록
  CART: '/cart', // 장바구니
  ORDER: '/order/:orderKey', // 주문서
  NOT_FOUND: '/not-found'
}

export const STORE_GAME_MENU_PATH = {
  INDEX: '/game/:gameCode',
  REVIEW: '/game/:gameCode/review',
  ACHIEVEMENT: '/game/:gameCode/achievement',
  BOARD: '/game/:gameCode/board/:boardAlias?/:boardMenu?/:articleId?'
}

export const STORE_GOODS_MENU_PATH = {
  INDEX: '/goods/:gameCode/:goodsId',
  REVIEW: '/goods/:gameCode/:goodsId/review'
}
