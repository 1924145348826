import React, {
  useRef,
  useMemo,
  useReducer,
  useCallback,
  useEffect
} from 'react'
import styled, { css, keyframes } from 'styled-components'
import { useTranslation } from 'react-i18next'

// Utils
import storageUtility from 'utils/storageUtility'
import { secondToTime } from '@purple/common/utils/commonUtility'
import { isMobile, isIOS } from '@purple/common/utils/checkUtility'

import {
  handleVideoAddEvent,
  addFullscreenEventListner,
  removeFullscreenEventListner,
  requestFullscreen,
  exitFullscreen,
  getIsFullscreen
} from '@purple/common/utils/mediaUtility'

// Constants
import { STORAGE_KEY } from 'constants/storageType'
import { CUSTOM_EVENT_KEY } from 'constants/customEventTypes'
import { ICON_TYPE, KEY_TYPE } from 'constants/videoPlayerTypes'

// Components
import { Icons, Range, RangeIcon, ContentLoading } from '@purple/design/react'

// Style
import { media } from 'assets/styles/media'

const zoomIn = keyframes`
  from {
    transform: scale(1)
  }
  to {
    transform: scale(1.25)

  }
`

const zoomOut = keyframes`
  from {
    transform: scale(1.25)
  }
  to {
    transform: scale(1)
  }
`

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`

const ControlBar = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 78px;
  padding: 0 24px;
  opacity: 0;
  transition: opacity 0.1s;
  z-index: 3;
`

const VideoContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  outline: none;

  &:hover {
    ${ControlBar} {
      opacity: 1;
    }
  }
`

const Thumbnail = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 1;
`

const ThumbnailImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const Video = styled.video`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const BezelIcon = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${({ theme }) => theme.color.gray600};
  transition: 0.08s ease-in-out;

  svg {
    fill: ${({ theme }) => theme.color.glyphs600Active};
  }

  ${({ icon }) =>
    icon === ICON_TYPE.PLAY
      ? css`
          // PLAY 아이콘 가운데 맞춤
          svg {
            margin-left: 4px;
          }
        `
      : [ICON_TYPE.PREV, ICON_TYPE.NEXT].includes(icon) &&
        css`
          // 점프 타임 아이콘 간격 맞춤, 각각 애니메이션 적용
          svg {
            margin: 0 -5px;
            opacity: 0;

            &:first-of-type {
              animation: 0.08s ease-in-out 0.08s forwards ${fadeIn};
            }
            &:nth-of-type(2) {
              animation: 0.08s ease-in-out 0.16s forwards ${fadeIn};
            }
          }
        `}

  ${({ animation }) =>
    animation
      ? css`
          animation: ${zoomIn} 0.05s ease-in-out forwards,
            ${zoomOut} 0.2s ease-in-out 0.16s forwards;
        `
      : `
        &:hover {
          transform: scale(1.25);
        }
    `}
`

const Bezel = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  transition: 0.05s ease-in-out;
  transform: translateX(-50%) translateY(-50%);
  z-index: 2;

  ${({ animation }) =>
    animation &&
    css`
      animation: ${fadeOut} 0.2s ease-in-out forwards;
    `}
`

const BezelText = styled.p`
  text-align: center;
  margin-top: 6px;
  ${({ theme }) => theme.typography.body1};
  color: ${({ theme }) => theme.color.glyphs600Active};
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
`

const ProgressBar = styled.div`
  margin-bottom: 10px;

  div[name='progressBar'] {
    &:before {
      transition: 0.15s;
    }
  }
`

const ControlMenu = styled.div`
  display: flex;
  justify-content: space-between;
`

const Times = styled.div`
  ${({ theme }) => theme.typography.caption2};
  color: ${({ theme }) => theme.color.glyphs600};
  padding-top: 4px;
  ${media.tablet`
    padding-top: 16px;
  `};
`

const Time = styled.span`
  &:last-of-type {
    color: ${({ theme }) => theme.color.glyphs650};
    &:before {
      content: '/';
      margin: 0 8px;
    }
  }
`

const Menus = styled.ul`
  display: flex;
  margin-top: 5px;
`

const Menu = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  cursor: pointer;

  svg {
    fill: ${({ theme }) => theme.color.glyphs600};
  }

  &:hover {
    svg {
      fill: ${({ theme }) => theme.color.glyphs600Hover};
    }
  }
  &:active {
    svg {
      fill: ${({ theme }) => theme.color.glyphs600Active};
    }
  }
`

const initState = {
  isLoading: true,
  isStarted: false,
  isPaused: true,
  currentTime: 0,
  progressPercent: 0,
  isPlayAfterProgress: false,
  isShowBezel: false,
  isFadeoutBezel: false,
  bezelIcon: '',
  isAnimateBezel: false,
  isShowThumbnail: true,
  isFullscreen: false
}

function VideoPlayer({
  id,
  src,
  type,
  autoplay,
  thumbnail,
  onError,
  controlTime = 5,
  controlVolume = 10,
  sendVideoState
}) {
  const { t } = useTranslation()
  const { isMuted, volume } = storageUtility.get(STORAGE_KEY.VIDEO_PLAYER_INFO)

  const [states, setStates] = useReducer(
    (prev, next) => {
      return { ...prev, ...next }
    },
    { ...initState, duration: 0, isMuted, volume }
  )

  const videoContainerRef = useRef(null)
  const videoRef = useRef(null)
  const controlBarRef = useRef(null)
  const bezelTimerRef = useRef(0)
  const bezelIconTimerRef = useRef(0)
  const isWillUnmountRef = useRef(false)
  const isMousedownProgressBarRef = useRef(false)
  const changingProgressBarRef = useRef(false)

  const isJumpTime = useMemo(
    () => [ICON_TYPE.PREV, ICON_TYPE.NEXT].includes(states.bezelIcon),
    [states.bezelIcon]
  )

  const animateBezel = useCallback(() => {
    setStates({
      isAnimateBezel: true
    })

    if (bezelTimerRef.current) {
      clearTimeout(bezelTimerRef.current)
    }
    bezelTimerRef.current = setTimeout(() => {
      setStates({
        isFadeoutBezel: true
      })
      bezelTimerRef.current = 0
    }, 410)

    if (!bezelIconTimerRef.current) {
      bezelIconTimerRef.current = setTimeout(() => {
        setStates({
          isAnimateBezel: false
        })
        bezelIconTimerRef.current = 0
      }, 610) // :hover 때문에 fadeOut 애니메이션 끝나는 시간에 맞춤
    }
  }, [])

  const showBezel = useCallback(
    (icon, animation = true) => {
      setStates({
        bezelIcon: icon,
        isShowBezel: true,
        isFadeoutBezel: false
      })
      if (animation) {
        animateBezel()
      }
    },
    [animateBezel]
  )

  const init = useCallback(() => {
    if (!videoRef.current.paused) videoRef.current.pause()
    videoRef.current.currentTime = 0
    setStates({
      ...initState
    })
    if (!autoplay) {
      showBezel(ICON_TYPE.PLAY, false)
    }
  }, [autoplay, showBezel])

  const play = useCallback(() => {
    const promise = videoRef.current.play()
    if (promise !== undefined) {
      promise.then(() => {}).catch((error) => {})
    }
  }, [])

  const pause = useCallback(() => {
    const promise = videoRef.current.pause()
    if (promise !== undefined) {
      promise.then(() => {}).catch((error) => {})
    }
  }, [])

  const mute = useCallback(() => {
    videoRef.current.muted = true
  }, [])

  const setPlay = useCallback(() => {
    const { currentTime, paused } = videoRef.current
    if (!states.isPlayAfterProgress) {
      setStates({
        isStarted: true,
        isPlayAfterProgress: true
      })
    }
    setStates({
      isPaused: paused
    })
    // 영상 시작 시 PLAY 베젤, 종료 시 타임점프 베젤 숨김
    // 시작할 때 currentTime이 0.00x일 때가 있어서 timeupdate 단위인 0.25 미만으로 수정
    if (currentTime < 0.25 || states.progressPercent === 100) {
      setStates({
        isShowBezel: false
      })
    }
    // 첫 재생 시 썸네일 제거
    if (!paused && states.isShowThumbnail) {
      setStates({
        isShowThumbnail: false
      })
    }

    sendVideoState && sendVideoState(paused ? 'paused' : 'playing')
  }, [
    states.isPlayAfterProgress,
    states.progressPercent,
    states.isShowThumbnail,
    sendVideoState
  ])

  const setCurrentTime = useCallback(
    (currentTime) => {
      setStates({
        currentTime,
        progressPercent: Math.ceil((currentTime * 100) / states.duration)
      })
    },
    [states.duration]
  )

  const setVolume = useCallback((volume) => {
    videoRef.current.volume = volume / 100
  }, [])

  const togglePlay = useCallback(() => {
    videoRef.current.paused ? play() : pause()
  }, [play, pause])

  const toggleMuted = useCallback(() => {
    const { muted } = videoRef.current
    videoRef.current.muted = !muted

    if (muted) setVolume(states.volume || 10)
  }, [states.volume, setVolume])

  const toggleFullscreen = useCallback(() => {
    getIsFullscreen()
      ? exitFullscreen()
      : requestFullscreen(isIOS ? videoRef.current : videoContainerRef.current)
  }, [])

  const onEvent = useCallback(
    (e) => {
      if (e && e.detail) {
        const { subType } = e.detail
        if (subType === 'play') {
          const { isMuted, volume } = storageUtility.get(
            STORAGE_KEY.VIDEO_PLAYER_INFO
          )
          setVolume(volume)
          videoRef.current.muted = isMuted
          videoContainerRef.current.focus()
          play()
        } else if (subType === 'parse') {
          pause()
        } else if (subType === 'reset') {
          init()
        } else if (subType === 'mute') {
          mute()
        }
      }
    },
    [init, play, pause, mute]
  )

  const handleLoadedmetadata = useCallback((e) => {
    setStates({
      duration: e.target.duration
    })
  }, [])

  const handleSeeked = useCallback(() => {
    if (states.isLoading) {
      setStates({
        isLoading: false
      })
    }
  }, [states.isLoading])

  const handleTimeupdate = useCallback(
    (e) => {
      if (!e.target || isWillUnmountRef.current) return

      const newCurrentTime = e.target.currentTime
      setCurrentTime(newCurrentTime)
      if (states.isShowThumbnail) {
        setStates({
          isShowThumbnail: false
        })
      }
      if (changingProgressBarRef.current) {
        changingProgressBarRef.current = false
      }
    },
    [states.isShowThumbnail, setCurrentTime]
  )

  const handleAutoplay = useCallback(() => {
    setStates({
      isPaused: false,
      isMuted: true,
      volume: 0
    })
  }, [])

  const handleCanPlay = useCallback(
    (e) => {
      if (states.isLoading) {
        setStates({
          isLoading: false
        })
      }
      // 첫 자동재생만 muted로 재생하고 다음 자동재생부터는 소리 재생 가능
      if (!states.isStarted && autoplay) {
        handleVideoAddEvent(e, handleAutoplay)
      }
    },
    [states.isLoading, states.isStarted, autoplay, handleAutoplay]
  )

  const handleWaiting = useCallback(() => {
    setStates({
      isLoading: true
    })
  }, [])

  //비디오 볼륨값이 변경된 경우
  const handleVolumeChange = useCallback(() => {
    const { muted, volume } = videoRef.current
    const newVolume = Math.floor(volume * 100)

    if (volume === 0) {
      videoRef.current.muted = true
    }

    setStates({
      isMuted: volume === 0 || muted,
      volume: newVolume
    })
    storageUtility.set(STORAGE_KEY.VIDEO_PLAYER_INFO, {
      isMuted: volume === 0 || muted,
      volume: newVolume
    })
  }, [])

  const handleEnded = useCallback(() => {
    // 프로그레스바 드래그 중에는 리턴, 마우스 뗐을 때 처리
    if (isMousedownProgressBarRef.current) return

    setTimeout(() => {
      // 동영상 끝나면 썸네일 다시 보여줌
      setStates({
        isPaused: true,
        isShowThumbnail: true
      })
      showBezel(ICON_TYPE.REPLAY, false)
    }, 250)
  }, [showBezel])

  const handleClickVideo = useCallback(
    (e) => {
      const { paused, currentTime } = videoRef.current
      if (states.isLoading) return

      // 컨트롤바 클릭 시는 togglePlay 하지 않음
      let target = e.target
      while (target !== videoContainerRef.current) {
        if (target === controlBarRef.current) return
        target = target.parentNode
      }

      // currentTime === duration이지만 videoRef.current.ended가 아닌 경우 togglePlay로 리플레이가 되지 않아 대응
      if (states.duration > 0 && currentTime === states.duration) {
        init()
      }
      togglePlay()
      showBezel(paused ? ICON_TYPE.PLAY : ICON_TYPE.PAUSE)
    },
    [states.isLoading, states.duration, init, showBezel, togglePlay]
  )

  const handleKeydown = useCallback(
    ({ key }) => {
      if (isWillUnmountRef.current) return

      const { paused, muted, currentTime } = videoRef.current

      switch (key) {
        case KEY_TYPE.ENTER:
          toggleFullscreen()
          break
        case KEY_TYPE.SPACE:
        case KEY_TYPE.SPACEBAR:
          togglePlay()
          showBezel(paused ? ICON_TYPE.PLAY : ICON_TYPE.PAUSE)
          break
        case KEY_TYPE.UP:
          if (states.volume < 100) {
            const nextVolume =
              states.volume + controlVolume < 100
                ? states.volume + controlVolume
                : 100
            setVolume(nextVolume)
            if (muted) {
              videoRef.current.muted = !muted
              setStates({
                isMuted: !muted
              })
            }
          }
          showBezel(ICON_TYPE.VOLUME_ON)
          break
        case KEY_TYPE.DOWN:
          if (states.volume > 0) {
            const prevVolume =
              states.volume > controlVolume ? states.volume - controlVolume : 0
            setVolume(prevVolume)
          }
          showBezel(ICON_TYPE.VOLUME_ON)
          break
        case KEY_TYPE.LEFT:
          const prevTime =
            currentTime > controlTime ? currentTime - controlTime : 0
          videoRef.current.currentTime = prevTime
          showBezel(ICON_TYPE.PREV)
          break
        case KEY_TYPE.RIGHT:
          if (currentTime >= states.duration) return
          const nextTime =
            currentTime + controlTime < states.duration
              ? currentTime + controlTime
              : states.duration
          videoRef.current.currentTime = nextTime
          showBezel(ICON_TYPE.NEXT)
          break
        default:
          break
      }
    },
    [
      states.duration,
      states.volume,
      controlTime,
      controlVolume,
      setVolume,
      showBezel,
      togglePlay,
      toggleFullscreen
    ]
  )

  const handleMouseDownProgressBar = useCallback(() => {
    isMousedownProgressBarRef.current = true

    // 프로그레스바 조작 시 비디오 일시정지
    if (!videoRef.current.paused) {
      pause()
    } else {
      setStates({
        isPlayAfterProgress: false
      })
    }
    if (states.isShowBezel) {
      setStates({
        isShowBezel: false
      })
    }
  }, [states.isShowBezel, pause])

  const handleChangeProgressBar = useCallback(
    (e) => {
      if (changingProgressBarRef.current) return
      const newCurrentTime = (e.target.value * states.duration) / 100
      videoRef.current.currentTime = newCurrentTime
      changingProgressBarRef.current = true
    },
    [states.duration]
  )

  const handleMouseUpProgressBar = useCallback(() => {
    isMousedownProgressBarRef.current = false

    const { currentTime, seeking } = videoRef.current

    // 재생 상태에서 프로그레스바 움직이면 이어서 재생
    if (states.isPlayAfterProgress && states.progressPercent < 100) {
      play()
    }
    if (currentTime === states.duration) {
      handleEnded()
    }
    if (seeking && !states.isLoading) {
      setStates({
        isLoading: true
      })
    }
  }, [
    states.isLoading,
    states.progressPercent,
    states.isPlayAfterProgress,
    states.duration,
    play,
    handleEnded
  ])

  //볼륨바 변경
  const handleChangeVolumeBar = useCallback(
    (e) => {
      if (videoRef.current.muted) {
        toggleMuted()
      }
      setVolume(parseInt(e.target.value))
    },
    [setVolume, toggleMuted]
  )

  const handleChangeFullscreen = useCallback(() => {
    setStates({
      isFullscreen: getIsFullscreen()
    })
    // IOS에서 전체화면 토글 시 재생상태 동기화 되지 않아 추가
    if (isIOS && videoRef.current.paused)
      setStates({
        isPaused: true
      })
  }, [])

  useEffect(() => {
    isWillUnmountRef.current = false
    const videoEl = videoRef.current

    // 스토리지 값 초기 세팅
    videoEl.muted = isMuted
    videoEl.volume = volume / 100

    // MEMO: ios 에서 수동 load 해야만 로드가 시작되는 이슈
    if (isIOS) {
      videoEl.load()
    }

    if (!autoplay) {
      showBezel(ICON_TYPE.PLAY, false)
    }

    addFullscreenEventListner(handleChangeFullscreen)

    return () => {
      isWillUnmountRef.current = true

      if (bezelTimerRef.current) {
        clearTimeout(bezelTimerRef.current)
        bezelTimerRef.current = 0
      }
      removeFullscreenEventListner(handleChangeFullscreen)
    }
  }, [])

  useEffect(() => {
    const videoEl = videoRef.current
    videoEl.addEventListener(CUSTOM_EVENT_KEY.VIDEO_CONTROLLER, onEvent)

    return () => {
      videoEl.removeEventListener(CUSTOM_EVENT_KEY.VIDEO_CONTROLLER, onEvent)
    }
  }, [onEvent])

  return (
    <VideoContainer
      tabIndex="0"
      ref={videoContainerRef}
      onClick={handleClickVideo}
      onKeyDown={handleKeydown}
    >
      <ContentLoading isLoading={states.isLoading} zIndex={1500} />
      {!states.isLoading && states.isShowBezel && (
        <Bezel animation={states.isFadeoutBezel}>
          <BezelIcon icon={states.bezelIcon} animation={states.isAnimateBezel}>
            {isJumpTime ? (
              <>
                <Icons
                  name={
                    states.bezelIcon === ICON_TYPE.NEXT
                      ? 'ArrowRight'
                      : 'ArrowLeft'
                  }
                  width={31}
                  height={31}
                />
                <Icons
                  name={
                    states.bezelIcon === ICON_TYPE.NEXT
                      ? 'ArrowRight'
                      : 'ArrowLeft'
                  }
                  width={31}
                  height={31}
                />
              </>
            ) : (
              <Icons name={states.bezelIcon} width={40} height={40} />
            )}
          </BezelIcon>
          {states.bezelIcon === ICON_TYPE.VOLUME_ON && (
            <BezelText>{volume}</BezelText>
          )}
          {isJumpTime && (
            <BezelText>
              {t('seconds', {
                count: controlTime
              })}
            </BezelText>
          )}
        </Bezel>
      )}
      {thumbnail && states.isShowThumbnail && (
        <Thumbnail>
          <ThumbnailImage src={thumbnail} />
        </Thumbnail>
      )}
      <Video
        id={id || 'videoPlayer'}
        ref={videoRef}
        onLoadedMetadata={handleLoadedmetadata}
        onTimeUpdate={handleTimeupdate}
        onCanPlay={handleCanPlay}
        onPlay={setPlay}
        onPause={setPlay}
        onWaiting={handleWaiting}
        onVolumeChange={handleVolumeChange}
        onSeeked={handleSeeked}
        onEnded={handleEnded}
        onError={onError}
        playsInline
      >
        <source src={src} type={type}></source>
      </Video>
      <ControlBar ref={controlBarRef} className="ControlBar">
        <ProgressBar
          onMouseDown={handleMouseDownProgressBar}
          onMouseUp={handleMouseUpProgressBar}
        >
          <Range
            name="progressBar"
            min={0}
            max={100}
            value={states.progressPercent}
            step={0}
            onChange={handleChangeProgressBar}
            onKeyDown={(e) => e.preventDefault()}
          />
        </ProgressBar>
        <ControlMenu>
          <Times>
            <Time>{secondToTime(states.currentTime)}</Time>
            <Time>{secondToTime(states.duration)}</Time>
          </Times>
          <Menus>
            <Menu onClick={togglePlay}>
              <Icons
                name={states.isPaused ? ICON_TYPE.PLAY : ICON_TYPE.PAUSE}
                width={24}
                height={24}
              />
            </Menu>
            <Menu>
              <RangeIcon
                icon={
                  <Icons
                    name={
                      states.isMuted ? ICON_TYPE.MUTED : ICON_TYPE.VOLUME_ON
                    }
                    width={24}
                    height={24}
                    onClick={toggleMuted}
                  />
                }
                range={
                  <Range
                    name="volumeBar"
                    min={0}
                    max={100}
                    value={states.isMuted ? 0 : volume}
                    step={10}
                    onChange={handleChangeVolumeBar}
                    onKeyDown={(e) => e.preventDefault()}
                  />
                }
                isSlide={true}
                isHideRange={isMobile}
                rangeWidth={112}
              />
            </Menu>
            <Menu onClick={toggleFullscreen}>
              <Icons
                name={
                  states.isFullscreen
                    ? ICON_TYPE.EXIT_FULLSCREEN
                    : ICON_TYPE.FULLSCREEN
                }
                width={24}
                height={24}
              />
            </Menu>
          </Menus>
        </ControlMenu>
      </ControlBar>
    </VideoContainer>
  )
}

export default VideoPlayer
