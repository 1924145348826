import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Route, Switch, useParams } from 'react-router-dom'

// Routes
import { GAME_BOARD_STORE_PATH } from 'routes/board/path'

// Constants
import { MAINTENENCE_TYPE } from 'constants/appTypes'
import { BOARD_TYPE } from 'constants/boardTypes'

// Utils
import { isMaintenance } from 'utils/storeUtility'

// Components
import PageStatusTemplate from 'components/Common/PageStatusTemplate'
import BoardMaintenance from 'components/Maintenance/BoardMaintenance'
import BoardViewContainer from 'components/Board/Common/BoardViewContainer'
import BoardListContainer from 'components/Board/Common/BoardListContainer'
import BoardWriteContainer from 'components/Board/Common/BoardWriteContainer'
import BoardEditContainer from 'components/Board/Common/BoardEditContainer'

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  & > div[data-overlayscrollbars] {
    height: 100%;
  }
  min-width: 0px;
`

function RouteContainer(props) {
  const { gameCode = props.gameCode, boardAlias = props.boardAlias } =
    useParams()

  const injectBoardType = useMemo(() => {
    switch (boardAlias) {
      case 'news': {
        return BOARD_TYPE.NEWS
      }
      case 'game': {
        return BOARD_TYPE.COMMUNITY
      }
      default: {
        return undefined
      }
    }
  }, [boardAlias])

  if (!injectBoardType) {
    console.error('boardType 주입이 필요 합니다.')
    return ''
  }

  return (
    <Container>
      <PageStatusTemplate
        component={
          <Switch>
            <Route
              path={GAME_BOARD_STORE_PATH.LIST}
              render={() => (
                <BoardListContainer boardType={injectBoardType} {...props} />
              )}
            />
            <Route
              path={GAME_BOARD_STORE_PATH.VIEW}
              render={() => (
                <BoardViewContainer boardType={injectBoardType} {...props} />
              )}
            />
            <Route
              path={GAME_BOARD_STORE_PATH.WRITE}
              render={() => (
                <BoardWriteContainer boardType={injectBoardType} {...props} />
              )}
            />
            <Route
              path={GAME_BOARD_STORE_PATH.EDIT}
              render={() => (
                <BoardEditContainer boardType={injectBoardType} {...props} />
              )}
            />
          </Switch>
        }
        maintenanceComponent={<BoardMaintenance />}
        isMaintenanceStatus={isMaintenance(MAINTENENCE_TYPE.PURPLE_BOARD)}
      />
    </Container>
  )
}

export default RouteContainer
