import { createEntityAdapter, createSelector } from '@reduxjs/toolkit'
import { notiApi } from 'store/services/notiApi'

import { getLocale } from '@purple/common/utils/commonUtility'

import { setNotifyCount } from 'native/nativeUtility'

export const notiQuery = notiApi
  .enhanceEndpoints({
    addTagTypes: ['getNotificationCount']
  })
  .injectEndpoints({
    endpoints: (build) => ({
      /**
       * 통합 알림 목록 조회 (필터 별 읽음 처리)
       */
      getNotificationList: build.query({
        query: ({ filter, markAsRead, pageSize, cursor, language }) => ({
          url: `/api/common/list.json?filter=${filter}${
            markAsRead !== undefined ? '&markAsRead=' + markAsRead : ''
          }${cursor ? '&cursor=' + cursor : ''}&pageSize=${
            pageSize || 20
          }&language=${language || getLocale()}`,
          method: 'GET'
        }),
        keepUnusedDataFor: 0,
        serializeQueryArgs: ({
          queryArgs: { filter },
          endpointDefinition,
          endpointName
        }) => ({ filter }),
        transformResponse: (response, meta, arg) => {
          return response.result.notiResult
        },
        merge: (currentCache, newItems, _meta) => {
          if (_meta?.arg?.cursor) {
            currentCache.notiList.push(...newItems.notiList)
            currentCache.nextCursor = newItems.nextCursor
          }
          return currentCache
        }
      }),
      /**
       * 통합 알림 읽음 카운트 조회
       */
      getNotificationCount: build.query({
        query: ({ filter }) => ({
          url: `/api/common/count.json?filter=${filter}`,
          method: 'GET'
        }),
        providesTags: ['getNotificationCount'],
        transformResponse: (response, meta, arg) => {
          return response.result.notiResult?.countList[0]
        },
        async onQueryStarted(
          { filter, param, ...patch },
          { dispatch, queryFulfilled }
        ) {
          try {
            // 알림 카운트 런처 전달
            const res = await queryFulfilled
            const count = param === null ? 0 : res?.data?.notiCount
            await setNotifyCount(count)
          } catch (response) {
            if (response.error) {
            }
          }
        }
      }),
      /**
       * 통합 알림 단건 확인
       */
      postNotificationConfirm: build.mutation({
        query: ({ notiID }) => ({
          url: `/api/common/confirm.json?notiID=${notiID}`,
          method: 'POST'
        }),
        // invalidatesTags: ['getNotificationCount'],
        async onQueryStarted(
          { params, ...patch },
          { dispatch, queryFulfilled }
        ) {
          // const patchResult = dispatch(
          //   notiQuery.util.updateQueryData(
          //     'getNotificationList',
          //     { filter: params },
          //     (draft) => {
          //       draft.notiList.forEach((noti) => {
          //         if (noti.id === patch.notiID) {
          //           noti.isNew = false
          //         }
          //       })
          //     }
          //   )
          // )

          try {
            await queryFulfilled
          } catch {
            // patchResult.undo()
          }
        }
      })
    })
  })

export const {
  useGetNotificationListQuery,
  useGetNotificationCountQuery,
  usePostNotificationConfirmMutation,
  useLazyGetNotificationListQuery,
  useLazyGetNotificationCountQuery
} = notiQuery

const selectNotiQueryResult = (endpointName, params) =>
  notiQuery.endpoints[endpointName].select(params)

export const selectNotiQuery = ({ endpointName, params, initialData }) =>
  createSelector(
    selectNotiQueryResult(endpointName, params),
    (notiQueryResult) => notiQueryResult.data ?? initialData
  )
