import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import { useHistory } from 'react-router-dom'
import { moveGameView } from 'routes/store/locations'

// Style
import styled from 'styled-components'

// Component
import LayerWrapper from 'components/Popup/components/common/LayerWrapper'
import LayerBody from 'components/Popup/components/common/LayerBody'
import LayerHeader from 'components/Popup/components/common/LayerHeader'
import AchievementsItem from 'components/Store/Game/Achievements/AchievementsItem'
import TemplateFC from 'components/Common/TemplateFC'
import CommonError from 'components/Error/CommonError'

// RTK
import { useCheckLoginUserQuery } from 'store/services/query/npQuery'
import { useGetAchievementsProgressQuery } from 'store/services/query/achievementQuery'

// Style
import { media } from 'assets/styles/media'

const CommonErrorWrap = styled.div`
  display: flex;
  align-items: center;
  height: 90%;
`

const AchievementInfoWrap = styled.div`
  margin-left: 10px;
  .total,
  .slash {
    ${({ theme }) => theme.typography.subtitle3};
    color: ${({ theme }) => theme.color.glyphs250};

    ${media.phone`
      ${({ theme }) => theme.typography.subtitle3};
    `};
  }
  .slash {
    margin: 0 4px;
    ${media.phone`
      ${({ theme }) => theme.typography.subtitle3};
    `};
  }
  .count {
    ${({ theme }) => theme.typography.subtitle3};
    color: ${({ theme }) => theme.color.glyphs200};
    ${media.phone`
      ${({ theme }) => theme.typography.subtitle3};
    `};
  }

  &.not-info {
    .total {
      color: ${({ theme }) => theme.color.glyphs250};
      ${({ theme }) => theme.typography.subtitle3};
    }
    .slash,
    .count {
      display: none;
    }
  }
  ${media.tablet`
      display: none;
  `}
`

const AchievementInfoSmallWrap = styled.div`
  padding: 20px 24px;
  position: sticky;
  display: none;
  .total,
  .slash {
    ${({ theme }) => theme.typography.subtitle3};
    color: ${({ theme }) => theme.color.glyphs250};

    ${media.phone`
      ${({ theme }) => theme.typography.subtitle3};
    `};
  }
  .slash {
    margin: 0 4px;
    ${media.phone`
      ${({ theme }) => theme.typography.subtitle3};
    `};
  }
  .count {
    ${({ theme }) => theme.typography.subtitle3};
    color: ${({ theme }) => theme.color.glyphs200};
    ${media.phone`
      ${({ theme }) => theme.typography.subtitle3};
    `};
  }

  &.not-info {
    .total {
      color: ${({ theme }) => theme.color.glyphs200};
      ${({ theme }) => theme.typography.subtitle3};
    }
    .slash,
    .count {
      display: none;
    }
  }
  ${media.tablet`
      display: block;
  `}
  ${media.phone`
    padding: 20px;
  `}
`

const ContentWrap = styled.div`
  .achievement-item-wrap:last-child {
    ${media.tablet`
      margin-bottom: 40px;
  `}
    ${media.phone`
      margin-bottom: 20px;
    `};
  }
`

const AchievementPopup = ({ layerClose, gameInfo }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const loginQueryData = useCheckLoginUserQuery()

  const { data, isError } = useGetAchievementsProgressQuery(
    {
      gameCode: gameInfo.game_code
    },
    { refetchOnMountOrArgChange: 10 }
  )

  const isNotLogin = useMemo(() => {
    if (!!loginQueryData?.data?.user_id === false) {
      return true
    }

    return false
  }, [loginQueryData])

  const hasLaunched = useMemo(() => {
    if (data?.achievementList && data?.achievementList[0]) {
      return data?.achievementList[0].hasLaunched
    }
    return false
  }, [data])

  return (
    <LayerWrapper width="888px" maxHeight="720px">
      <LayerHeader
        type="large-modal"
        title={t('See all achievements')}
        onClickClose={() =>
          history.push({
            pathname: moveGameView({
              gameCode: gameInfo.game_code
            })
          })
        }
        customTitleRender={() => {
          return (
            <AchievementInfoWrap
              className={cn('layer-header-custom-title', {
                'not-info': isNotLogin || !hasLaunched
              })}
            >
              <span className="total">
                {t('total count', { count: data?.totalCount || 0 })}
              </span>
              <span className="slash">/</span>
              <span className="count">
                {t('achieved count', { count: data?.finishCount || 0 })}
              </span>
            </AchievementInfoWrap>
          )
        }}
      />
      <AchievementInfoSmallWrap className={cn({ 'not-info': isNotLogin })}>
        <span className="total">
          {t('total count', { count: data?.totalCount || 0 })}
        </span>
        <span className="slash">/</span>
        <span className="count">
          {t('achieved count', { count: data?.finishCount || 0 })}
        </span>
      </AchievementInfoSmallWrap>
      <TemplateFC
        isAPIError={isError}
        component={() => {
          return (
            <>
              {data?.achievementList && (
                <LayerBody type="large-modal">
                  {data.achievementList.map((item, index) => {
                    return (
                      <AchievementsItem
                        key={index}
                        item={item}
                        isNotLogin={isNotLogin}
                      />
                    )
                  })}
                </LayerBody>
              )}
            </>
          )
        }}
        apiErrorComponent={
          <CommonErrorWrap>
            <CommonError
              hasPubbie
              onClickRefresh={false}
              onClickSupport={false}
              type="component"
            />
          </CommonErrorWrap>
        }
      />
    </LayerWrapper>
  )
}

export default AchievementPopup
