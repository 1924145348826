import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Icons, Thumbnail } from '@purple/design/react'
import styled from 'styled-components'
import cn from 'classnames'

// Redux
import { userActions } from 'store/features/userSlice'

// import { userQuery } from 'store/services/query/userQuery'

// Utils
import { configGameCodeGroup } from '@purple/common/utils/globalUtility'
import { chatGameCodeList } from 'utils/gameUtility'
import { convertGameName } from '@purple/common/utils/gameUtility'
import { onError, defaultProfileImage } from 'utils/imageUtility'

// Component
// import Popper from 'components/Common/Popper'
// import OverlayScroll from 'components/Common/OverlayScroll'

const Profile = styled.div`
  max-width: 160px;
  min-width: 0;
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 8px;
  background-color: ${({ theme }) => theme.color.bright100};
  border-radius: 4px;
  cursor: pointer;
  ${({ open, theme }) => open && theme.color.bright200};

  &:hover {
    background-color: ${({ theme }) => theme.color.bright200};
    > svg {
      fill: ${({ theme }) => theme.color.glyphs100};
    }
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  & > span {
    flex: 1;
    padding: 0 8px;
    ${({ theme }) => theme.typography.body4};
    color: ${({ theme }) => theme.color.glyphs100};
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    overflow: hidden;
  }

  & > svg {
    fill: ${({ theme }) => theme.color.glyphs300};
  }
`

const PopperContent = styled.div`
  width: 245px;
  max-height: 232px;
  padding: 8px 0px;
  border: ${({ theme }) => `1px solid ${theme.color.bright200}`};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.color.bg300};
  ${({ theme }) => theme.elevation['12']};
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const PopperInner = styled.div`
  padding: 0 8px;
  flex: 1;
`

const UserSection = styled.div`
  &:not(:first-child) {
    margin-top: 8px;
  }
`
const GameTitle = styled.h3`
  height: 32px;
  padding: 8px;
  display: flex;
  align-items: center;
  ${({ theme }) => theme.typography.body3};
  color: ${({ theme }) => theme.color.glyphs300};
`
const UserList = styled.ul``
const UserItem = styled.li`
  margin-top: 2px;
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.color.bright100 : 'none'};
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 8px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.color.bright100};
  }
`

const Name = styled.div`
  ${({ theme }) => theme.typography.subtitle3};
  color: ${({ theme }) => theme.color.glyphs200};
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
  margin-left: 8px;
  margin-right: 4px;
`

const Server = styled.span`
  ${({ theme }) => theme.typography.body4};
  color: ${({ theme }) => theme.color.glyphs300};
  white-space: nowrap;
`

const ButtonNewProfile = styled.div`
  margin-top: 8px;

  & > button {
    width: 100%;
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 8px;
    border-radius: 4px;
    cursor: pointer;
    ${({ theme }) => theme.typography.subtitle3};
    color: ${({ theme }) => theme.color.glyphs300};

    .circle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 23px;
      height: 23px;
      border: 1px solid ${({ theme }) => theme.color.bright300};
      border-radius: 50%;
      margin-right: 8px;
      & > svg {
        fill: ${({ theme }) => theme.color.glyphs300};
      }
    }

    &:hover {
      background-color: ${({ theme }) => theme.color.bright100};
    }
  }
`

const SelectProfile = (props) => {
  const {
    onChangeStep = () => {},
    placement = 'bottom-end',
    popperStyle = {},
    isShowAddNewProfile = true,
    disabled = false,
    isForceProfileShow = false,
    isLiveProfile = false
  } = props

  // const selectProfileList = useMemo(
  //   () => userQuery.endpoints.getProfileInfoList.select(),
  //   []
  // )
  // const { data: myProfileList = [] } = useSelector(selectProfileList)

  const selectedProfile = useSelector(
    (state) => state.userSlice.selectedProfile
  )

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [gameCharacters, setGameCharacters] = useState([])
  const [popper, setPopper] = useState({
    open: false,
    target: null,
    preventElements: []
  })

  const gameCodeList = useMemo(() => chatGameCodeList(), [])

  const isEmptyList = useMemo(() => {
    return gameCodeList.findIndex((gameCode) => gameCharacters[gameCode]) === -1
  }, [gameCodeList, gameCharacters])

  const handleProfileToggleClick = useCallback(
    (e) => {
      if (disabled) {
        return
      }
      const target = e.currentTarget
      setPopper((state) => {
        if (!state.open) {
          return {
            ...state,
            open: true,
            target,
            preventElements: [e.target]
          }
        } else {
          return {
            ...state,
            open: false,
            target: null
          }
        }
      })
    },
    [disabled]
  )

  const handlePopperClose = useCallback(() => {
    setPopper((state) => ({
      ...state,
      open: false,
      target: null
    }))
  }, [])

  // update selectedProfile
  const handleSelectProfile = useCallback(
    (user) => {
      if (selectedProfile.gameUserId !== user.gameUserId) {
        dispatch(userActions.setSelectedProfile(user))
      }
      handlePopperClose()
    },
    [selectedProfile, handlePopperClose, dispatch]
  )

  // 게임코드별로 재배열
  // useEffect(() => {
  //   if (myProfileList.length) {
  //     const gameCodeGroupings = myProfileList.reduce((groups, item) => {
  //       const gameCode = configGameCodeGroup(item.gameCode)
  //       if (!groups[gameCode]) {
  //         groups[gameCode] = []
  //       }
  //       groups[gameCode].push(item)
  //       return groups
  //     }, {})

  //     const gameCharacters = Object.keys(gameCodeGroupings).reduce(
  //       (groups, key) => {
  //         const gameCode = key
  //         if (!groups[gameCode]) {
  //           groups[gameCode] = {}
  //         }
  //         let list = gameCodeGroupings[gameCode]
  //         groups[gameCode] = {
  //           gameCode,
  //           list
  //         }
  //         return groups
  //       },
  //       {}
  //     )
  //     setGameCharacters(gameCharacters)
  //   }
  // }, [myProfileList])

  let isShowProfile = Object.keys(selectedProfile).length > 0

  return (
    <>
      <span>보내는 사람</span>
      <Profile
        onClick={handleProfileToggleClick}
        open={popper.open}
        className={cn({ disabled: disabled })}
      >
        <Thumbnail
          type="character"
          size="100"
          imgBg="point7"
          alt=""
          onError={onError}
          src={selectedProfile.profileImageUrl || defaultProfileImage}
        />
        <span>{selectedProfile.characterName}</span>
        <Icons
          name={popper.open ? 'ArrowUp' : 'ArrowDown'}
          width="12"
          height="12"
        />
      </Profile>

      {/* <Popper
        open={popper.open}
        target={popper.target}
        strategy={'absolute'}
        placement={placement}
        onClose={handlePopperClose}
        preventElements={popper.preventElements}
        portal={false}
      >
        {() => (
          <PopperContent style={popperStyle}>
            <OverlayScroll>
              <PopperInner>
                {selectedProfile &&
                  gameCodeList.map((gameCode) => {
                    return (
                      gameCharacters[gameCode] && (
                        <UserSection key={gameCode}>
                          <GameTitle>
                            {convertGameName(gameCharacters[gameCode].gameCode)}
                          </GameTitle>
                          <UserList>
                            {gameCharacters[gameCode].list.map(
                              (item, index) => {
                                const isSelected =
                                  selectedProfile.gameUserId === item.gameUserId
                                return (
                                  <UserItem
                                    key={index}
                                    isSelected={isSelected}
                                    onClick={() => handleSelectProfile(item)}
                                  >
                                    <Thumbnail
                                      type="character"
                                      size="100"
                                      imgBg="point7"
                                      alt=""
                                      onError={onError}
                                      src={
                                        item.profileImageUrl ||
                                        defaultProfileImage
                                      }
                                    />
                                    <Name>{item.characterName}</Name>
                                    {item.serverName && (
                                      <Server>{item.serverName}</Server>
                                    )}
                                  </UserItem>
                                )
                              }
                            )}
                          </UserList>
                        </UserSection>
                      )
                    )
                  })}
                {isShowAddNewProfile && (
                  <ButtonNewProfile onClick={() => onChangeStep(2)}>
                    <button>
                      <span className="circle">
                        <Icons name="Add" width="9" height="9" />
                      </span>
                      {t('create a new profile')}
                    </button>
                  </ButtonNewProfile>
                )}
              </PopperInner>
            </OverlayScroll>
          </PopperContent>
        )}
      </Popper> */}
    </>
  )
}

export default React.memo(
  SelectProfile,
  (prevProps, nextProps) =>
    prevProps.selectedProfile === nextProps.selectedProfile &&
    prevProps.disabled === nextProps.disabled
)
