import { npApi } from 'store/services/npApi'
import SessionCheck from 'utils/sessionCheckUtility'
import { onReload } from '@purple/common/utils/commonUtility'

export const npQuery = npApi
  .enhanceEndpoints({
    addTagTypes: ['getMyCharacterList']
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getMyCharacterList: build.query({
        query: (params) => ({
          url: '/api/lime/chat/getMyCharacterList',
          method: 'POST',
          body: params
        }),
        serializeQueryArgs: ({
          queryArgs,
          endpointDefinition,
          endpointName
        }) => {
          return endpointName
        }
        // providesTags: ['getMyCharacterList'],
        // transformResponse: (response, meta, arg) => response.characters
      }),
      /**
       * SSO URL 셋팅
       *
       */
      getSSOUrl: build.mutation({
        query: (params) => ({
          url: '/api/session/sso',
          method: 'POST',
          body: params
        })
      }),
      /**
       * 로그인 세션 체크
       * 'polling': 주기적 세션 체크
       * 'reload': 수동 세션 체크 및 리로드
       */
      sessionCheck: build.query({
        query: () => ({
          url: '/api/session/check',
          method: 'GET'
        }),
        serializeQueryArgs: ({ endpointName }) => {
          return endpointName
        },
        async onQueryStarted(params, { queryFulfilled }) {
          switch (params) {
            case 'polling':
              console.log('세션 재연결 시도')
              try {
                SessionCheck.clearTimer()

                const result = await queryFulfilled
                const { data, meta } = result
                const { status } = meta.response

                if (status === 200) {
                  SessionCheck.sessionSuccess(data)
                } else {
                  console.log(`Error:[${status}]`)
                }
              } catch (response) {
                console.log('세션 연결 실패', response)
                // (Time Out or CORS)시 isUnhandledError : true
                const error = response.isUnhandledError ? false : response.error
                if (error && 'status' in error) {
                  if (error.status === 401) {
                    SessionCheck.sessionRecovery()
                  }
                }
              }
              break
            case 'reload':
              console.log('세션 체크')
              try {
                await queryFulfilled
                console.log('세션 유효')
                onReload()
              } catch {
                console.log('세션 만료')
                SessionCheck.sessionRecovery()
              }
              break
            default:
              try {
                await queryFulfilled
                console.log('세션 유효')
              } catch {
                console.log('세션 만료')
              }
              break
          }
        }
      }),
      /**
       * 로그인 체크
       */
      checkLoginUser: build.query({
        query: () => ({
          url: '/api/session/user',
          method: 'GET'
        })
      })
    })
  })

export const {
  useGetSSOUrlMutation,
  useGetMyCharacterListQuery,
  useLazyGetMyCharacterListQuery,
  useLazySessionCheckQuery,
  useCheckLoginUserQuery,
  useLazyCheckLoginUserQuery
} = npQuery
