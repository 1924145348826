import { isLauncher, isIndex } from '@purple/common/utils/checkUtility'
import { compareVersion } from '@purple/common/utils/commonUtility'

/* XXXX : https://jira.ncsoft.com/browse/MPLATFORM-2476 - 2022.09.21 일부 삭제 */

const isChildWindow = () => {
  const searchParams = new URLSearchParams(window.location.search)
  return (
    searchParams.has('viewTitle') ||
    searchParams.has('viewMinWidth') ||
    searchParams.has('viewMinHeight') ||
    searchParams.has('viewName')
  )
}

export const getGameInstalled = (gameCode, cb) => {
  if (
    !isLauncher ||
    !window.ngpHomeBindObject ||
    !window.ngpHomeBindObject.getIsGameInstalled
  ) {
    return
  }

  new Promise(
    async (resolve, reject) =>
      await window.ngpHomeBindObject.getIsGameInstalled(
        gameCode,
        resolve,
        reject
      )
  )
    .then(cb)
    .catch(console.log)
}

//퍼플톡 활동 이동
export const goToFromNotify = (params) => {
  if (
    !isLauncher ||
    !window.ngpNotificationBindObject ||
    !window.ngpNotificationBindObject.goToFromNotify
  )
    return

  new Promise(
    async (resolve, reject) =>
      await window.ngpNotificationBindObject.goToFromNotify(
        params,
        resolve,
        reject
      )
  )
    .then(console.log)
    .catch(console.error)
}

//PC방 여부 체크
export const isFamilyZone = () => {
  if (
    !isLauncher ||
    !window.ngpHomeBindObject ||
    !window.ngpHomeBindObject.getIsInternetCafe
  )
    return

  return new Promise(
    async (resolve, reject) =>
      await window.ngpHomeBindObject.getIsInternetCafe(resolve, reject)
  )
}

// CBT PC방 여부 체크
export const isCBTFamilyZone = (gameCode) => {
  if (
    !isLauncher ||
    !window.ngpHomeBindObject ||
    !window.ngpHomeBindObject.getIsCBTInternetCafe
  )
    return

  return new Promise(async (resolve, reject) => {
    await window.ngpHomeBindObject.getIsCBTInternetCafe(
      gameCode,
      resolve,
      reject
    )
  })
}

// [런처] 딤드 on
export const handleDimmedTurnOn = () => {
  console.log('handleDimmedTurnOn')
  if (
    !isLauncher ||
    isChildWindow() ||
    isIndex() ||
    !window.ngpInteractionBindObject ||
    !window.ngpInteractionBindObject.turnOn
  )
    return

  new Promise(
    async (resolve, reject) =>
      await window.ngpInteractionBindObject.turnOn(resolve, reject)
  ).then(
    (result) => console.log(result),
    (reason) => console.log(reason)
  )
}

// [런처] 딤드 off
export const handleDimmedTurnOff = () => {
  console.log('handleDimmedTurnOff')
  if (
    !isLauncher ||
    isChildWindow() ||
    isIndex() ||
    !window.ngpInteractionBindObject ||
    !window.ngpInteractionBindObject.turnOff
  )
    return

  new Promise(
    async (resolve, reject) =>
      await window.ngpInteractionBindObject.turnOff(resolve, reject)
  ).then(
    (result) => console.log(result),
    (reason) => console.log(reason)
  )
}

// [런처] 초기화 리로드
export const onNativeReload = (url = window.location.href) => {
  if (
    !isLauncher ||
    !window.ngpHomeBindObject ||
    !window.ngpHomeBindObject.refresh
  ) {
    return window.location.reload()
  } else {
    return new Promise(
      async (resolve, reject) =>
        await window.ngpHomeBindObject.refresh(url, resolve, reject)
    ).then(
      (result) => {
        console.log(result)
        // 초기화 리로드 시 좌측메뉴 딤드 OFF
        handleDimmedTurnOff()
      },
      (reason) => console.log(reason)
    )
  }
}

//메뉴 활성화 설정
export const nativeSetMenuActive = (CODE) => {
  if (
    !isLauncher ||
    !window.ngpHomeBindObject ||
    !window.ngpHomeBindObject.setMenuActive
  )
    return
  // Native Bridge - 활성화 채널 정보 전달

  new Promise(
    async (resolve, reject) =>
      //NGP.ngpHomeBindObject.setMenuActive('NOTIFY')
      await window.ngpHomeBindObject.setMenuActive(CODE, resolve, reject)
  ).then(
    (result) => {
      console.log(`런처 setMenuActive 호출완료: ${result}`)
    },
    (reason) => {
      console.log(`런처 setMenuActive 호출 실패: ${reason}`)
    }
  )
}

// 런처 팝업 close
export const closeNativePopup = () => {
  // TODO: 네이티브 인터페이스 추가
  console.log('런처 팝업 닫기')
}

/**
 * 런처 버전 체크
 * @param {string} criterionVersion
 * @param {optional} optional
 * @returns {string || Boolean}
 */
export const checkLauncherVersion = (
  criterionVersion,
  optional,
  defaultResult = false
) => {
  let result = defaultResult

  const regx = /NGPClient\/([0-9.]+)/g.exec(navigator.userAgent)
  const launcherVersion = regx ? regx[1] : ''

  if (!launcherVersion || !criterionVersion) return result

  result = compareVersion(criterionVersion, launcherVersion, optional)
  return result
}

/**
 * 통합 알림 카운트 런처 전달
 * @param {number} count
 * @returns {Promise}
 */
export const setNotifyCount = (count) => {
  if (
    !isLauncher ||
    !window.ngpHomeBindObject ||
    !window.ngpHomeBindObject.setNotifyCount
  )
    return console.log('setNotifyCount: ', count)

  new Promise(
    async (resolve, reject) =>
      await window.ngpHomeBindObject.setNotifyCount(count, resolve, reject)
  )
    .then((resolve) => console.log('success setNotifyCount: ', count))
    .catch(console.error)
}

/**
 * NGP.goTo 인터페이스 실행 요청
 * @param {string} url
 * @returns {Promise}
 */
export const goToNGP = (url) => {
  if (
    !isLauncher ||
    !window.ngpHomeBindObject ||
    !window.ngpHomeBindObject.goToNGP
  )
    return

  new Promise(
    async (resolve, reject) =>
      await window.ngpHomeBindObject.goToNGP(url, resolve, reject)
  )
    .then((resolve) => console.log('success goToNGP: ', url))
    .catch(console.error)
}

/**
 * TALK.goTo 인터페이스 실행 요청
 * @param {object} params
 * @returns {Promise}
 */
export const goToTALK = (params) => {
  if (
    !isLauncher ||
    !window.ngpHomeBindObject ||
    !window.ngpHomeBindObject.goToTALK
  )
    return

  new Promise(
    async (resolve, reject) =>
      await window.ngpHomeBindObject.goToTALK(params, resolve, reject)
  )
    .then((resolve) => console.log('success goToTALK: ', params))
    .catch(console.error)
}

/**
 * 런처 튜토리얼 활성화 확인
 */
export const getIsShowTutorial = () => {
  if (
    !isLauncher ||
    !window.ngpHomeBindObject ||
    !window.ngpHomeBindObject.isShowTutorial
  )
    return

  return new Promise(
    async (resolve, reject) =>
      await window.ngpHomeBindObject.isShowTutorial(resolve, reject)
  )
}

/**
 * NP 로그인창 호출
 */
export const showNPLogin = (gameCode) => {
  if (
    !isLauncher ||
    !window.ngpHomeBindObject ||
    !window.ngpHomeBindObject.showNPLogin
  )
    return

  return new Promise(
    async (resolve, reject) =>
      await window.ngpHomeBindObject.showNPLogin(gameCode, resolve, reject)
  )
}

/**
 * 게시판 레드닷 호출
 */
export const sendRecentArticleInfo = (gameCode, articles) => {
  if (
    !isLauncher ||
    !window.ngpHomeBindObject ||
    !window.ngpHomeBindObject.sendRecentArticleInfo
  )
    return

  return new Promise(
    async (resolve, reject) =>
      await window.ngpHomeBindObject.sendRecentArticleInfo(
        gameCode,
        articles,
        resolve,
        reject
      )
  )
    .then((resolve) => console.log('success sendRecentAritcleInfo: ', articles))
    .catch(console.error)
}

/**
 * 런처 내 게임으로 이동
 */
export const goToMyGames = () => {
  if (
    !isLauncher ||
    !window.ngpHomeBindObject ||
    !window.ngpHomeBindObject.goToMyGames
  )
    return

  return new Promise(
    async (resolve, reject) =>
      await window.ngpHomeBindObject.goToMyGames(resolve, reject)
  )
}
