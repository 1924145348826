import React from 'react'

// Style
import styled from 'styled-components'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { media } from 'assets/styles/media'

// Util
import { Icons, ActionButton } from '@purple/design/react'

// Component
import SelectProfile from 'components/Store/Game/Search/SelectProfile'

const CommonHeader = styled.header`
  display: flex;
  align-items: center;
  position: relative;
  flex-shrink: 0;
  transition-property: padding-left;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.175, 0.665, 0.32, 1), linear;
`

const Header = styled(CommonHeader)`
  height: 44px;
  margin: 12px 12px 8px;
  padding-left: ${({ isBackActive }) => (isBackActive ? '40px' : '8px')};
  ${media.tablet`
    height: 60px;
    margin: 0 8px 0 40px;
  `}
  ${media.phone`
    height: 44px; 
  `}
`

const BackButton = styled.button`
  width: 40px;
  height: 40px;
  position: absolute;
  left: 0;
  top: 0;
  & > svg {
    vertical-align: middle;
  }
`

const Title = styled.h3`
  ${({ theme }) => theme.typography.headline6};
  padding-left: 4px;
  color: ${({ theme }) => theme.color.glyphs200};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const TitleWrap = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  overflow: hidden;

  & > div {
    display: flex;
    align-items: center;
    overflow: hidden;

    ${media.tablet`
      width:100%;
      justify-content: center;
    `}
  }

  & .item-enter {
    opacity: 0.01;
  }

  & .item-enter.item-enter-active {
    opacity: 1;
    transition-property: opacity;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.175, 0.665, 0.32, 1), linear;
  }
`

const LargeHeader = styled(CommonHeader)`
  padding: 24px 32px 16px;
  height: 84px;

  & > button {
    position: absolute;
    right: 32px;
    top: 26px;
  }

  ${media.tablet`
    height: 84px;
    padding: 30px 56px;
    ${TitleWrap} {
      justify-content: center;
    }
    & > button {
      right: 8px;
      top: 22px;
    }
  `}

  ${media.phone` 
    height: 44px;
    padding: 10px 56px;
    & > button {
      top: 2px;
    } 
  `}
`

const LargeTitleWrap = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  ${media.tablet`
    justify-content: center;
  `}

  & > h3 {
    padding-left: 0;
  }
`

const LayerHeader = ({
  onClickBack,
  onClickClose,
  title,
  headerStyle,
  bottomSpace = null,
  customTitleRender = null,
  isShowCloseIcon = true,
  isShowSelectProfile = false
}) => {
  return (
    <Header
      className="layer-header"
      isBackActive={onClickBack ? true : false}
      bottomSpace={bottomSpace}
      style={headerStyle}
    >
      {onClickBack && (
        <BackButton onClick={onClickBack}>
          <Icons name="ChevronLeft" width="20" height="20" />
        </BackButton>
      )}
      <TitleWrap>
        <TransitionGroup>
          <CSSTransition classNames="item" timeout={{ enter: 300, exit: 300 }}>
            <>
              <Title key={title}>{title}</Title>
              {customTitleRender && customTitleRender()}
            </>
          </CSSTransition>
        </TransitionGroup>
      </TitleWrap>

      {isShowSelectProfile && <SelectProfile />}

      {isShowCloseIcon && (
        <ActionButton type="basic" size="large" onClick={onClickClose}>
          <Icons name="Close" width="24" height="24" />
        </ActionButton>
      )}
    </Header>
  )
}

const LargeLayerHeader = ({
  onClickClose,
  title,
  headerStyle,
  bottomSpace = null,
  customTitleRender = null,
  isShowCloseIcon = true
}) => {
  return (
    <LargeHeader
      className="layer-header"
      bottomSpace={bottomSpace}
      style={headerStyle}
    >
      <LargeTitleWrap>
        <Title key={title}>{title}</Title>
        {customTitleRender && customTitleRender()}
      </LargeTitleWrap>

      {isShowCloseIcon && (
        <ActionButton type="basic" size="large" onClick={onClickClose}>
          <Icons name="Close" width="24" height="24" />
        </ActionButton>
      )}
    </LargeHeader>
  )
}

const LayerHeaderSwitch = ({ type = 'modal', ...rest }) => {
  switch (type) {
    case 'large-modal':
      return <LargeLayerHeader type={type} {...rest} />
    case 'modal':
    default:
      return <LayerHeader type={type} {...rest} />
  }
}

export default LayerHeaderSwitch
