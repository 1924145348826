import React, {
  createContext,
  useState,
  useCallback,
  useEffect,
  useMemo
} from 'react'
import { createPortal } from 'react-dom'

// Utils
import { generateUUID } from '@purple/common/utils/commonUtility'

// Components
import Toast from 'components/Context/Common/Toast'

// Hooks
import usePortal from 'hooks/common/usePortal'
// Constants
import { CUSTOM_EVENT_KEY } from 'constants/customEventTypes'

const Constants = {
  CHANGE: 'change',
  INFO: 'info',
  BUTTON: 'button',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error'
}

export const ToastContext = createContext()
export const ToastHandlerContext = createContext()
export let ToastHandlers = null

export const ToastProvider = ({ children, portalId = 'toasts', ...props }) => {
  const [toasts, setToasts] = useState([])
  const { portalElement } = usePortal(portalId)

  const evtCreateToast = useCallback((toast) => {
    const defaultToast = {
      id: generateUUID(),
      type: 'info',
      message: null,
      timeOut: 5000
    }
    setToasts([Object.assign(defaultToast, toast)])
  }, [])

  const handleInfoToast = useCallback(
    ({
      message,
      iconName,
      buttonName,
      onClickButton,
      timeOut,
      priority,
      position
    }) => {
      evtCreateToast({
        type: Constants.INFO,
        message,
        iconName,
        buttonName,
        onClickButton,
        timeOut,
        priority,
        position
      })
    },
    [evtCreateToast]
  )

  // const handleSuccessToast = useCallback(
  //   (message, timeOut, onClick, priority) => {
  //     evtCreateToast({
  //       type: Constants.SUCCESS,
  //       message,
  //       timeOut,
  //       onClick,
  //       priority
  //     })
  //   },
  //   [evtCreateToast]
  // )

  // const handleWarningToast = useCallback(
  //   (message, timeOut, onClick, priority) => {
  //     evtCreateToast({
  //       type: Constants.WARNING,
  //       message,
  //       timeOut,
  //       onClick,
  //       priority
  //     })
  //   },
  //   [evtCreateToast]
  // )

  // const handleErrorToast = useCallback(
  //   (message, timeOut, onClick, priority) => {
  //     evtCreateToast({
  //       type: Constants.ERROR,
  //       message,
  //       timeOut,
  //       onClick,
  //       priority
  //     })
  //   },
  //   [evtCreateToast]
  // )

  const handleRemoveToast = useCallback((toast) => {
    setToasts((prevToats) => prevToats.filter((n) => toast.id !== n.id))
  }, [])

  const contextValue = useMemo(() => toasts, [toasts])
  const contextHandlers = useMemo(() => {
    const handlers = {
      onInfoToast: handleInfoToast,
      // onSuccessToast: handleSuccessToast,
      // onWarningToast: handleWarningToast,
      // onErrorToast: handleErrorToast,
      onRemoveToast: handleRemoveToast
    }
    ToastHandlers = handlers
    return handlers
  }, [
    handleInfoToast,
    // handleSuccessToast,
    // handleWarningToast,
    // handleErrorToast,
    handleRemoveToast
  ])

  const openToast = useCallback((e) => {
    handleInfoToast({
      message: e?.detail?.message,
      position: e?.detail?.position
    })
  }, [])

  useEffect(() => {
    window.addEventListener(CUSTOM_EVENT_KEY.SHOW_TOAST, openToast)
    return () => {
      window.removeEventListener(CUSTOM_EVENT_KEY.SHOW_TOAST, openToast)
    }
  }, [openToast])

  return (
    <ToastContext.Provider value={contextValue}>
      <ToastHandlerContext.Provider value={contextHandlers}>
        {children}
        {portalElement &&
          // !!toasts.length &&
          createPortal(
            <Toast
              context={contextValue}
              onRemoveToast={handleRemoveToast}
              {...props}
            />,
            portalElement
          )}
      </ToastHandlerContext.Provider>
    </ToastContext.Provider>
  )
}
