import React, { useCallback, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

// Style
import styled from 'styled-components'
import { media } from 'assets/styles/media'
import { RadioButton } from '@purple/design/react'

// Component
import LayerWrapper from 'components/Popup/components/common/LayerWrapper'
import LayerBody from 'components/Popup/components/common/LayerBody'
import LayerHeader from 'components/Popup/components/common/LayerHeader'
import { LayerFooter } from 'components/Popup/components/common/LayerFooter'
import { closePopup } from 'components/Popup/index'
import { confirmPopup } from 'components/Popup'

// RTK
import {
  useSetArticleReportMutation,
  useGetTodayReportedCountQuery,
  useSetCommentReportMutation
} from 'store/services/query/boardQuery'

// hooks
import useToast from 'hooks/common/useToast'

// utils
import { commonBoardError } from 'utils/boardUtilsForStore'

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 17px 8px;
  border-bottom: 1px solid ${({ theme }) => theme.color.bright300};

  strong {
    ${({ theme }) => theme.typography.subtitle2};
    color: ${({ theme }) => theme.color.glyphs200};
  }

  span {
    ${({ theme }) => theme.typography.body4};
    color: ${({ theme }) => theme.color.glyphs300};
  }
`

const Reason = styled.div`
  padding: 16px 8px;
  p {
    margin-bottom: 16px;
    ${({ theme }) => theme.typography.subtitle2};
    color: ${({ theme }) => theme.color.glyphs200};
  }

  ul {
    ${({ theme }) => theme.typography.body3};
    color: ${({ theme }) => theme.color.glyphs300};
  }
  li {
    cursor: pointer;

    label {
      display: flex;
      cursor: pointer;
      justify-content: space-between;
      align-items: center;
      height: 32px;
      padding: 0 8px;
    }

    & + li {
      margin-top: 12px;
    }
  }
`

const BoardReport = ({ name, boardAlias, articleId, commentId }) => {
  const { t } = useTranslation()
  const { onInfoToast } = useToast()

  const reasons = [
    { value: 1, text: t('indecent') },
    { value: 2, text: t('curse') },
    { value: 3, text: t('advertisement') },
    { value: 4, text: t('cash transactions') },
    { value: 5, text: t('illegal program') },
    { value: 6, text: t('plaster') },
    { value: 7, text: t('personal information') },
    { value: 8, text: t('copyright infringement') },
    { value: 9, text: t('slander') },
    { value: 0, text: t('etc') }
  ] // 남은횟수 호출

  const [setArticleReport] = useSetArticleReportMutation()
  const [setCommentReport] = useSetCommentReportMutation()
  const [selected, setSelected] = useState(reasons[0].value)
  const [count, setCount] = useState(null)

  const { data, isError } = useGetTodayReportedCountQuery({})

  //팝업닫기
  const handleClose = useCallback(() => closePopup('BoardReport'), [])

  //신고하기
  const handleReport = useCallback(async () => {
    try {
      let res = null
      if (articleId) {
        res = await setArticleReport({
          boardAlias,
          articleId,
          params: { reason: selected }
        }).unwrap()
      } else if (commentId) {
        res = await setCommentReport({
          boardAlias,
          commentId,
          params: { reason: selected }
        }).unwrap()
      }
      console.log('res', res)
      if (!res) return

      if (res?.report_count === -1) {
        // 이미 신고했을때
        confirmPopup({
          msg: t('already reported'),
          type: 'alert'
        })
      } else {
        //성공
        onInfoToast({
          message: t('reported'),
          position: 'bottom'
        })

        setCount(count - 1)
        handleClose()
      }
    } catch (error) {
      console.error(error)
      commonBoardError(error).execute()
    }
  }, [count])

  useEffect(() => {
    if (!data) return

    const { report_count, report_limit } = data
    setCount(report_limit - report_count)

    if (report_limit - report_count === 0) {
      //신고횟수 없을때
      confirmPopup({
        msg: t('exceeded the number of declaration'),
        type: 'alert',
        onRight: handleClose
      })
    }
    console.log(typeof count)
  }, [data])

  const handleSelected = useCallback((value) => {
    setSelected(value)
  })

  return (
    <LayerWrapper maxHeight="720px">
      <LayerHeader title={t('report')} onClickClose={handleClose} />

      <LayerBody>
        <Header>
          <strong>
            {t('can report a post up to count times per day', {
              count: data?.report_limit
            })}
          </strong>
          <span>{count}</span>
        </Header>
        <Header>
          <strong>{t('declaration target')}</strong>
          <span>{name}</span>
        </Header>

        <Reason>
          <p>{t('select a reason for declaration')}</p>
          <ul>
            {reasons.map((item, i) => (
              <li key={i}>
                <label>
                  {item.text}
                  <RadioButton
                    name="reason"
                    checked={item.value === selected}
                    onClick={() => handleSelected(item.value)}
                  />
                </label>
              </li>
            ))}
          </ul>
        </Reason>
      </LayerBody>
      <LayerFooter
        leftButtonLabel={t('cancel')}
        rightButtonLabel={t('declaration')}
        onClickLeft={handleClose}
        onClickRight={handleReport}
      />
    </LayerWrapper>
  )
}

export default BoardReport
