import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import cn from 'classnames'

// Style
import { Input, Dropdown } from '@purple/design/react'
import { media } from 'assets/styles/media'

// Router
import { moveBoard } from 'routes/board/locations'

// Hooks
import useSearchParams from 'hooks/common/useSearchParams'
import useToast from 'hooks/common/useToast'

// Constants
import { keyCode } from '@purple/common/constants/keyCodeTypes'
import { CUSTOM_EVENT_KEY } from 'constants/customEventTypes'

const Container = styled.div`
  display: flex;
  width: 100%;
`

const DropWrap = styled.div`
  width: 150px;
  flex-shrink: 0;

  ${media.phone`
    width: 104px;
  `};
`
const InputWrap = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.color.textfield};
  border-radius: 4px;
  span {
    svg {
      fill: ${({ theme }) => theme.color.glyphs200};
    }
  }

  &:not(:first-child) {
    margin-left: 8px;
  }
`

function Search({
  categoryList,
  leftIconOnClick,
  placeholder,
  onChange,
  onKeyUp,
  onResetInput,
  maxLength,
  boardType
}) {
  const [query, setQuery] = useState('')
  const history = useHistory()
  const { articleId } = useParams()
  const { onInfoToast } = useToast()
  const { t } = useTranslation()

  const searchParams = useSearchParams()
  const categoryAlias = useMemo(
    () => searchParams.get('category'),
    [searchParams]
  )

  // STATE - 선택된 카테고리
  const [selectedCategory, setSelectedCategory] = useState(
    categoryList
      ? !!categoryAlias
        ? categoryList?.find(
            (category) => category.category_alias === categoryAlias
          ) ?? categoryList[0]
        : categoryList[0] || {}
      : null
  )

  //카테고리 선택
  const handleCategory = useCallback((category) => {
    setSelectedCategory(category)
  }, [])

  //onChange
  const handleChangeInput = useCallback((e) => {
    if (e?.target?.value) {
      if (e?.target?.value?.length > 100) {
        return
      }
    }
    const inputValue = e.target?.value
    setQuery(inputValue)
  }, [])

  //leftIconOnClick
  const handleSearchQuery = useCallback(() => {
    if (selectedCategory) {
      if (selectedCategory?.category_id !== 0 && !!!query?.length) {
        onInfoToast({
          message: t('enter the details')
        })
        return
      }
    }

    if (query) {
      searchParams.set('query', query)
    } else {
      searchParams.delete('query')
    }
    if (selectedCategory) {
      if (selectedCategory?.category_id === 0) {
        searchParams.delete('category')
      } else {
        searchParams.set('category', selectedCategory.category_alias)
      }
    }

    if (articleId) {
      // 상세에서 검색 시 리스트 화면으로 이동
      history.push(
        {
          pathname: moveBoard(null, `${boardType}_LIST`),
          search: searchParams.toString()
        },
        { refetch: true }
      )
    } else {
      // 목록에서 검색 시 리스트 갱신
      history.replace({ search: searchParams.toString() })
    }
  }, [
    t,
    boardType,
    articleId,
    searchParams,
    history,
    query,
    selectedCategory,
    onInfoToast
  ])

  //onKeyUp
  const handleKeyUp = useCallback(
    (e) => {
      if (e.keyCode === keyCode.ENTER) {
        handleSearchQuery({})
      }
      if (e.keyCode === keyCode.ESC) {
        setQuery('')
      }
    },
    [handleSearchQuery]
  )

  //onResetInput
  const handleResetInput = useCallback(() => {
    setQuery('')
  }, [])

  useEffect(() => {
    const initQuery = searchParams.get('query')
    initQuery && setQuery(initQuery)
  }, [])

  useEffect(() => {
    categoryList &&
      setSelectedCategory(
        !!categoryAlias
          ? categoryList?.find(
              (category) => category.category_alias === categoryAlias
            ) ?? categoryList[0]
          : categoryList[0] || {}
      )
  }, [categoryList, categoryAlias])

  //search query 초기화 이벤트
  useEffect(() => {
    const onEvent = (e) => {
      if (e.detail.type === 'initSearch') {
        setQuery('')
        searchParams.delete('query')
        if (!!categoryList?.length) searchParams.delete('category')
        history.replace({ search: searchParams.toString() })
      }
    }
    window.addEventListener(CUSTOM_EVENT_KEY.BOARD_SEARCH, onEvent)

    return () => {
      window.removeEventListener(CUSTOM_EVENT_KEY.BOARD_SEARCH, onEvent)
    }
  }, [categoryList, searchParams, history])

  return (
    <Container>
      {categoryList && (
        <DropWrap>
          <Dropdown selected={selectedCategory?.category_name} allowScroll>
            {categoryList.map((category, index) => {
              return (
                <button
                  key={index}
                  className={cn({
                    select:
                      category?.category_id === selectedCategory?.category_id
                  })}
                  onClick={() => handleCategory(category)}
                >
                  {category?.category_name}
                </button>
              )
            })}
          </Dropdown>
        </DropWrap>
      )}
      <InputWrap>
        <Input
          leftIcon="Search"
          leftIconOnClick={leftIconOnClick || handleSearchQuery}
          type="text"
          onChange={onChange || handleChangeInput}
          onKeyUp={onKeyUp || handleKeyUp}
          onResetInput={onResetInput || handleResetInput}
          value={query}
          placeholder={placeholder || t('search')}
          maxLength={maxLength || 100}
        />
      </InputWrap>
    </Container>
  )
}

export default Search
