export const ICON_TYPE = {
  PLAY: 'ArrowRight',
  PAUSE: 'Pause',
  VOLUME_ON: 'VolumeOn',
  MUTED: 'VolumeOff',
  REPLAY: 'Replay',
  PREV: 'DobbleArrowLeft',
  NEXT: 'DobbleArrowRight',
  FULLSCREEN: 'FullScreen',
  EXIT_FULLSCREEN: 'Restore'
}

export const KEY_TYPE = {
  ENTER: 'Enter',
  SPACE: ' ',
  SPACEBAR: 'Space Bar',
  UP: 'ArrowUp',
  DOWN: 'ArrowDown',
  LEFT: 'ArrowLeft',
  RIGHT: 'ArrowRight'
}
