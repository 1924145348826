import { useContext } from 'react'

// Context
import { ToastHandlerContext } from 'context/common/ToastContext'

export default function useToast() {
  const handlers = useContext(ToastHandlerContext)
  if (handlers === undefined) {
    throw new Error('useToast must be used within a ToastProvider')
  }
  return handlers
}
