import React from 'react'
import { useLocation } from 'react-router-dom'

import styled, { ThemeProvider } from 'styled-components'
import { ContentLoading, Theme, Icons } from '@purple/design/react'
import { getTheme } from '@purple/common/utils/commonUtility'

// hooks
import { usePageProcess } from 'hooks/app/useAppAsync'
import { useGetNotifyData } from 'hooks/notify/useNotifyAsync'

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.bg000};
  & > svg {
    fill: rgba(104, 106, 113, 0.4);
  }
`

const Inner = styled.div`
  position: relative;
  width: 48px;
  height: 48px;
  margin-bottom: 8px;
`
// 런처에서 최초에 실행되어야하는 프로세스를 해당 페이지에 정의합니다.
function IndexPage() {
  const { search } = useLocation()

  usePageProcess({ sessionCheck: true })
  useGetNotifyData()

  return (
    <ThemeProvider theme={Theme(getTheme())}>
      <Container>
        {!!search && (
          <>
            <Inner>
              <ContentLoading isLoading={true} />
            </Inner>
            <Icons name="PurpleLogotype" width="110" height="22" />
          </>
        )}
      </Container>
    </ThemeProvider>
  )
}

export default IndexPage
