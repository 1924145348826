import React, { useRef, useMemo, useState, useCallback, useEffect } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import styled from 'styled-components'
import YouTube from 'react-youtube'

// Utils
import { getYoutubeId } from '@purple/common/utils/mediaUtility'
import storageUtility from 'utils/storageUtility'

// Constants
import { STORAGE_KEY } from 'constants/storageType'
import { CUSTOM_EVENT_KEY } from 'constants/customEventTypes'

const Container = styled.section`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .youtube-embed {
    width: 100%;
    height: 100%;

    iframe {
      width: 100%;
      height: 100%;
    }
  }
`

function YoutubePlayer({
  id = `youtubePlayer_${Math.floor(Math.random() * 10000)}`,
  src = null,
  autoplay = false,
  viewerStatus,
  sendVideoState
}) {
  if (!src || !getYoutubeId(src)) return
  const containerRef = useRef(null)
  const youtubeRef = useRef(null)
  const videoId = getYoutubeId(src)

  const handleReady = useCallback(
    (e) => {
      youtubeRef.current = e.target
      const { isMuted, volume } = storageUtility.get(
        STORAGE_KEY.VIDEO_PLAYER_INFO
      )
      if (viewerStatus === 'active') {
        youtubeRef.current.seekTo(0)
        youtubeRef.current.setVolume(volume)
        isMuted ? youtubeRef.current.mute() : youtubeRef.current.unMute()
        youtubeRef.current.playVideo()
      }
    },
    [viewerStatus]
  )

  const handleStateChange = useCallback(
    (params) => {
      if (sendVideoState) {
        switch (params?.data) {
          case 1:
            sendVideoState('playing')
            break
          case 2:
            sendVideoState('paused')
            break
          default:
            // MEMO 추가 상태 정의 필요시 case 추가하여 사용
            sendVideoState('default')
        }
      }
    },
    [sendVideoState]
  )

  const onEvent = useCallback((e) => {
    if (!e || !e.detail || !youtubeRef.current) return
    const player = youtubeRef.current

    const { subType } = e.detail
    const { isMuted, volume } = storageUtility.get(
      STORAGE_KEY.VIDEO_PLAYER_INFO
    )

    if (subType === 'play') {
      player.setVolume(volume)
      isMuted ? player.mute() : player.unMute()

      if (player.getPlayerState() === 1) return //이미 play중이면 중지
      player.seekTo(0)
      player.playVideo()
    } else if (subType === 'parse') {
      player.pauseVideo()
    } else if (subType === 'reset') {
      player.pauseVideo()

      const Yvolume = player.getVolume()
      const Ymuted = player.isMuted()

      storageUtility.set(STORAGE_KEY.VIDEO_PLAYER_INFO, {
        isMuted: Yvolume === 0 || Ymuted,
        volume: Yvolume
      })
    } else if (subType === 'mute') {
      player.mute()
    }
  }, [])

  useEffect(() => {
    const el = containerRef.current
    el.addEventListener(CUSTOM_EVENT_KEY.VIDEO_CONTROLLER, onEvent)

    return () => {
      el.removeEventListener(CUSTOM_EVENT_KEY.VIDEO_CONTROLLER, onEvent)
    }
  }, [onEvent, containerRef])

  return (
    <Container id={id || 'youtubePlayer'} ref={containerRef}>
      <YouTube
        videoId={videoId}
        className="youtube-embed"
        onReady={handleReady}
        onStateChange={handleStateChange}
        opts={{
          // host: 'https://www.youtube-nocookie.com', // MEMO: 유튜브 쿠키 생성 이슈로 host 셋팅
          playerVars: {
            autoplay: autoplay,
            controls: 1,
            modestbranding: 1,
            rel: 0
          }
        }}
      />
    </Container>
  )
}

export default YoutubePlayer
