/*
  [TYPE CODE]
  NEXT: 다음
  PREV: 이전
 */
export const MEDIA_DIRECTION_TYPE = {
  NEXT: 'next',
  PREV: 'prev'
}

/*
  [TYPE CODE]
  VIDEO: 비디오
  IMAGE: 이미지
  YOUTUBE: 유트브 동영상
 */
export const MEDIA_TYPE = {
  VIDEO: 'VIDEO',
  IMAGE: 'IMAGE',
  YOUTUBE: 'YOUTUBE'
}

/*
  [업로드 허용 이미지 TYPE CODE]
  ['.jpg', '.gif', '.png', '.jpeg']
 */
export const ALLOW_IMG_TYPE_LIST = ['.jpg', '.gif', '.png', '.jpeg']

/*
  [업로드 허용 비디오 TYPE CODE]
  ['.mp4']
  */
export const ALLOW_VIDEO_TYPE_LIST = ['.mp4']
