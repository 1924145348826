import { purpleApi } from 'store/services/purpleApi'

export const achievementQuery = purpleApi.injectEndpoints({
  endpoints: (build) => ({
    getAchievementsSummary: build.query({
      query: (params) => ({
        url: `/achievements/v1.0/${params.gameCode}/summary`,
        method: 'GET'
      })
    }),
    getAchievementsInfos: build.query({
      query: (params) => ({
        url: `/achievements/v1.0/${params.gameCode}/infos`,
        method: 'GET'
      })
    }),
    getAchievementsProgress: build.query({
      query: (params) => ({
        url: `/achievements/v1.0/${params.gameCode}/progress`,
        method: 'GET'
      })
    }),
    getAchievementsUserProgress: build.query({
      query: (params) => ({
        url: '/achievements/v1.0/progress',
        method: 'GET'
      })
    })
  })
})

export const {
  useGetAchievementsSummaryQuery,
  useGetAchievementsInfosQuery,
  useLazyGetAchievementsInfosQuery,
  useGetAchievementsProgressQuery,
  useLazyGetAchievementsProgressQuery,
  useGetAchievementsUserProgressQuery,
  useLazyGetAchievementsUserProgressQuery
} = achievementQuery
