import { createSlice, current } from '@reduxjs/toolkit'

// initial state
export const initialState = {
  // selectedProfile: {
  //   gameCode: 'l2m',
  //   npAccountId: '8A7529FE-FCD6-4F82-8951-4FEB284CC50D',
  //   gameAccountId: '20E0DDE6-A1F0-42E3-B471-985EC74C7287',
  //   characterId: '281474976711210',
  //   characterName: '솜뭉치',
  //   serverId: '1',
  //   serverName: 'RC01',
  //   gameUserId: '511540665822217522',
  //   intro: '',
  //   anonymous: false,
  //   guildId: null,
  //   selected: true,
  //   gameData: {}
  // }, // [test]: selectedProfile 임시 값 삭제 필요
  // npUserId: window.NGP_VARS?.user?.id,
  // npUserStoreType: window.NGP_VARS?.user?.userStoreType
}

// reducer Slice
const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  // 생성한 액션 (동기 처리 리듀서)
  reducers: {
    setSelectedProfile: (state, action) => {
      state.selectedProfile = action.payload
    }
  },
  // 생선한 액션 외 외부 액션 참조 (비동기 처리 리듀서)
  extraReducers: (builder) => {}
})

const { actions, reducer } = userSlice

// Actions 내보내기
export const userActions = actions
// Selector 내보내기
export const userSelector = (state) => state.userSlice
// Reducer 내보내기
export default reducer
