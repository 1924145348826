import React from 'react'

// Constants
import { PAGE_STATUS } from 'constants/appTypes'

// Hooks
import { usePageStatusSimple } from 'hooks/common/usePageStatus'

// Components
import CommonError from 'components/Error/CommonError'

const renderPage = ({
  pageStatus,
  component,
  maintenanceComponent,
  errorComponent
}) => {
  switch (pageStatus) {
    case PAGE_STATUS.NORMAL:
      return component
    case PAGE_STATUS.MAINTENANCE:
      return maintenanceComponent
    default:
      return errorComponent
  }
}

function PageStatusTemplate({
  component,
  isMaintenanceStatus = false,
  maintenanceComponent = <div></div>,
  isError = false,
  errorComponent = <CommonError hasPubbie />
}) {
  const { pageStatus } = usePageStatusSimple(isMaintenanceStatus, isError)

  return renderPage({
    pageStatus,
    component,
    maintenanceComponent,
    errorComponent
  })
}

export default PageStatusTemplate
