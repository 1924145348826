import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { ContentLoading, Theme, Icons } from '@purple/design/react'
import { getTheme } from '@purple/common/utils/commonUtility'

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.bg000};
  & > svg {
    fill: rgba(104, 106, 113, 0.4);
  }
`

const Inner = styled.div`
  position: relative;
  width: 48px;
  height: 48px;
  margin-bottom: 8px;
`

// 기본 로딩 페이지
function LoadingPage() {
  return (
    <ThemeProvider theme={Theme(getTheme())}>
      <Container>
        <Inner>
          <ContentLoading isLoading={true} />
        </Inner>
        <Icons name="PurpleLogotype" width="110" height="22" />
      </Container>
    </ThemeProvider>
  )
}

export default LoadingPage
