import { createGlobalStyle, keyframes } from 'styled-components'

export const SwiperCSS = createGlobalStyle`
  :root {
    --swiper-theme-color: #007aff;
  }
  .swiper {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    /* Fix of Webkit flickering */
    z-index: 1;
  }
  .swiper-vertical > .swiper-wrapper {
    flex-direction: column;
  }
  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
  }
  .swiper-android .swiper-slide,
  .swiper-wrapper {
    transform: translate3d(0px, 0, 0);
  }
  .swiper-pointer-events {
    touch-action: pan-y;
    &.swiper-vertical {
      touch-action: pan-x;
    }
  }
  .swiper-slide {
    flex-shrink: 0;
    width: auto;
    height: auto;
    position: relative;
    transition-property: transform;
  }
  .swiper-slide-invisible-blank {
    visibility: hidden;
  }
  /* Auto Height */
  .swiper-autoheight {
    &,
    .swiper-slide {
      height: auto;
    }

    .swiper-wrapper {
      align-items: flex-start;
      transition-property: transform, height;
    }
  }
  .swiper-backface-hidden .swiper-slide {
    transform: translateZ(0);
    backface-visibility: hidden;
  }
  /* 3D Effects */
  .swiper-3d {
    &,
    &.swiper-css-mode .swiper-wrapper {
      perspective: 1200px;
    }
    .swiper-wrapper,
    .swiper-slide,
    .swiper-slide-shadow,
    .swiper-slide-shadow-left,
    .swiper-slide-shadow-right,
    .swiper-slide-shadow-top,
    .swiper-slide-shadow-bottom,
    .swiper-cube-shadow {
      transform-style: preserve-3d;
    }
    .swiper-slide-shadow,
    .swiper-slide-shadow-left,
    .swiper-slide-shadow-right,
    .swiper-slide-shadow-top,
    .swiper-slide-shadow-bottom {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      z-index: 10;
    }
    .swiper-slide-shadow {
      background: rgba(0, 0, 0, 0.15);
    }
    .swiper-slide-shadow-left {
      background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
    .swiper-slide-shadow-right {
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
    .swiper-slide-shadow-top {
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
    .swiper-slide-shadow-bottom {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
  }

  /* CSS Mode */
  .swiper-css-mode {
    > .swiper-wrapper {
      overflow: auto;
      scrollbar-width: none; /* For Firefox */
      -ms-overflow-style: none; /* For Internet Explorer and Edge */
      &::-webkit-scrollbar {
        display: none;
      }
    }
    > .swiper-wrapper > .swiper-slide {
      scroll-snap-align: start start;
    }
  }
  .swiper-horizontal.swiper-css-mode {
    > .swiper-wrapper {
      scroll-snap-type: x mandatory;
    }
  }
  .swiper-vertical.swiper-css-mode {
    > .swiper-wrapper {
      scroll-snap-type: y mandatory;
    }
  }
  .swiper-centered {
    > .swiper-wrapper::before {
      content: '';
      flex-shrink: 0;
      order: 9999;
    }
    &.swiper-horizontal {
      > .swiper-wrapper > .swiper-slide:first-child {
        margin-inline-start: var(--swiper-centered-offset-before);
      }
      > .swiper-wrapper::before {
        height: 100%;
        width: var(--swiper-centered-offset-after);
      }
    }
    &.swiper-vertical {
      > .swiper-wrapper > .swiper-slide:first-child {
        margin-block-start: var(--swiper-centered-offset-before);
      }
      > .swiper-wrapper::before {
        width: 100%;
        height: var(--swiper-centered-offset-after);
      }
    }

    > .swiper-wrapper > .swiper-slide {
      scroll-snap-align: center center;
    }
  }

  /***** navigation *****/
  :root {
    --swiper-navigation-size: 44px;
    --swiper-navigation-color: var(--swiper-theme-color);
  }
  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: 50%;
    width: calc(var(--swiper-navigation-size) / 44 * 27);
    height: var(--swiper-navigation-size);
    margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--swiper-navigation-color, var(--swiper-theme-color));
    &.swiper-button-disabled {
      opacity: 0.35;
      cursor: auto;
      pointer-events: none;
    }
    &.swiper-button-hidden {
      opacity: 0;
      cursor: auto;
      pointer-events: none;
    }
    .swiper-navigation-disabled & {
      display: none !important;
    }
    &:after {
      font-family: swiper-icons;
      font-size: var(--swiper-navigation-size);
      text-transform: none !important;
      letter-spacing: 0;
      font-variant: initial;
      line-height: 1;
    }
  }
  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    &:after {
      content: 'prev';
    }
    left: 10px;
    right: auto;
  }
  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    &:after {
      content: 'next';
    }
    right: 10px;
    left: auto;
  }

  .swiper-button-lock {
    display: none;
  }

  /***** pagination - 로비에서 재정의 사용중이므로 수정할때 함께 확인 필요 *****/
  :root {
    --swiper-pagination-color: var(--swiper-theme-color);
    --swiper-pagination-bullet-size: 8px;
    --swiper-pagination-bullet-width: 8px;
    --swiper-pagination-bullet-height: 8px;
    --swiper-pagination-bullet-inactive-color: #000;
    --swiper-pagination-bullet-inactive-opacity: 0.2;
    --swiper-pagination-bullet-opacity: 1;
    --swiper-pagination-bullet-horizontal-gap: 4px;
    --swiper-pagination-bullet-vertical-gap: 6px;
  }
  .swiper-pagination {
    position: absolute;
    text-align: center;
    transition: 300ms opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
    &.swiper-pagination-hidden {
      opacity: 0;
    }
    .swiper-pagination-disabled > &,
    &.swiper-pagination-disabled {
      display: none !important;
    }
  }
  /* Common Styles */
  .swiper-pagination-fraction,
  .swiper-pagination-custom{
    bottom: 10px;
    left: 0;
    width: 100%;
  }
  /* Bullets - 로비 / 톡의 스타일이 너무 달라 공통 스타일 삭제 
  .swiper-pagination-bullets-dynamic {
    overflow: hidden;
    font-size: 0;
    .swiper-pagination-bullet {
      transform: scale(0.33);
      position: relative;
    }
    .swiper-pagination-bullet-active {
      transform: scale(1);
    }
    .swiper-pagination-bullet-active-main {
      transform: scale(1);
    }
    .swiper-pagination-bullet-active-prev {
      transform: scale(0.66);
    }
    .swiper-pagination-bullet-active-prev-prev {
      transform: scale(0.33);
    }
    .swiper-pagination-bullet-active-next {
      transform: scale(0.66);
    }
    .swiper-pagination-bullet-active-next-next {
      transform: scale(0.33);
    }
  }
  .swiper-pagination-bullet {
    width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 8px));
    height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 8px));
    display: inline-block;
    border-radius: 50%;
    background: var(--swiper-pagination-bullet-inactive-color, #000);
    opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.2);
    .swiper-pagination-clickable & {
      cursor: pointer;
    }

    &:only-child {
      display: none !important;
    }
  }
  button.swiper-pagination-bullet {
    border: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    appearance: none;
  }
  .swiper-pagination-bullet-active {
    opacity: var(--swiper-pagination-bullet-opacity, 1);
    background: var(--swiper-pagination-color, var(--swiper-theme-color));
  }

  .swiper-vertical > .swiper-pagination-bullets,
  .swiper-pagination-vertical.swiper-pagination-bullets {
    right: 10px;
    top: 50%;
    transform: translate3d(0px, -50%, 0);
    .swiper-pagination-bullet {
      margin: var(--swiper-pagination-bullet-vertical-gap, 6px) 0;
      display: block;
    }
    &.swiper-pagination-bullets-dynamic {
      top: 50%;
      transform: translateY(-50%);
      width: 8px;
      .swiper-pagination-bullet {
        display: inline-block;
        transition: 200ms transform, 200ms top;
      }
    }
  }
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-horizontal.swiper-pagination-bullets {
    .swiper-pagination-bullet {
      margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
    }
    &.swiper-pagination-bullets-dynamic {
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
      .swiper-pagination-bullet {
        transition: 200ms transform, 200ms left;
      }
    }
  }
  .swiper-horizontal.swiper-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    transition: 200ms transform, 200ms right;
  }

  */
  /* Progress */
  .swiper-pagination-progressbar {
    background: rgba(0, 0, 0, 0.25);
    position: absolute;
    .swiper-pagination-progressbar-fill {
      background: var(--swiper-pagination-color, var(--swiper-theme-color));
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transform: scale(0);
      transform-origin: left top;
    }
    .swiper-rtl & .swiper-pagination-progressbar-fill {
      transform-origin: right top;
    }
    .swiper-horizontal > &,
    &.swiper-pagination-horizontal,
    .swiper-vertical > &.swiper-pagination-progressbar-opposite,
    &.swiper-pagination-vertical.swiper-pagination-progressbar-opposite {
      width: 100%;
      height: 4px;
      left: 0;
      top: 0;
    }
    .swiper-vertical > &,
    &.swiper-pagination-vertical,
    .swiper-horizontal > &.swiper-pagination-progressbar-opposite,
    &.swiper-pagination-horizontal.swiper-pagination-progressbar-opposite {
      width: 4px;
      height: 100%;
      left: 0;
      top: 0;
    }
  }
  .swiper-pagination-lock {
    display: none;
  }

  /***** Scrollbar *****/
  .swiper-scrollbar {
    border-radius: 5px;
    position: relative;
    -ms-touch-action: none;
    background: transparent;
    .swiper-scrollbar-disabled > &,
    &.swiper-scrollbar-disabled {
      display: none !important;
    }
    .swiper-horizontal > &,
    &.swiper-scrollbar-horizontal {
      /* position: absolute;
      left: 0;
      bottom: 0; */
      z-index: 50;
      height: 4px;
      width: 100%;
    }
    .swiper-vertical > &,
    &.swiper-scrollbar-vertical {
      position: absolute;
      right: 4px;
      top: 0;
      z-index: 50;
      width: 4px;
      height: 100%;
    }
  }
  .swiper-scrollbar-drag {
    height: 100%;
    width: 100%;
    position: relative;
    background: rgba(131, 137, 153, 0.3);
    border-radius: 5px;
    left: 0;
    top: 0;
  }
  .swiper-scrollbar-cursor-drag {
    cursor: move;
  }
  .swiper-scrollbar-lock {
    display: none;
  }
  .swiper-zoom-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .swiper-zoom-container > img,
  .swiper-zoom-container > svg,
  .swiper-zoom-container > canvas {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  .swiper-slide-zoomed {
    cursor: move;
  }

  /***** Preloader *****/
  :root {
    --swiper-preloader-color: var(--swiper-theme-color);
  }
  .swiper-lazy-preloader {
    width: 42px;
    height: 42px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -21px;
    margin-top: -21px;
    z-index: 10;
    transform-origin: 50%;
    box-sizing: border-box;
    border: 4px solid var(--swiper-preloader-color, var(--swiper-theme-color));
    border-radius: 50%;
    border-top-color: transparent;
  }
  .swiper:not(.swiper-watch-progress),
  .swiper-watch-progress .swiper-slide-visible {
    .swiper-lazy-preloader {
      animation: swiper-preloader-spin 1s infinite linear;
    }
  }

  .swiper-lazy-preloader-white {
    --swiper-preloader-color: #fff;
  }
  .swiper-lazy-preloader-black {
    --swiper-preloader-color: #000;
  }
  @keyframes swiper-preloader-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  /***** a11y *****/
  .swiper .swiper-notification {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    opacity: 0;
    z-index: -1000;
  }

  /***** effect-fade *****/
  .swiper-fade {
    &.swiper-free-mode {
      .swiper-slide {
        transition-timing-function: ease-out;
      }
    }
    .swiper-slide {
      pointer-events: none;
      transition-property: opacity;
      .swiper-slide {
        pointer-events: none;
      }
    }
    .swiper-slide-active {
      &,
      & .swiper-slide-active {
        pointer-events: auto;
      }
    }
  }
  
  /***** effect-cube *****/
  .swiper-cube {
    overflow: visible;
    .swiper-slide {
      pointer-events: none;
      backface-visibility: hidden;
      z-index: 1;
      visibility: hidden;
      transform-origin: 0 0;
      width: 100%;
      height: 100%;
      .swiper-slide {
        pointer-events: none;
      }
    }
    &.swiper-rtl .swiper-slide {
      transform-origin: 100% 0;
    }
    .swiper-slide-active {
      &,
      & .swiper-slide-active {
        pointer-events: auto;
      }
    }
    .swiper-slide-active,
    .swiper-slide-next,
    .swiper-slide-prev,
    .swiper-slide-next + .swiper-slide {
      pointer-events: auto;
      visibility: visible;
    }
    .swiper-slide-shadow-top,
    .swiper-slide-shadow-bottom,
    .swiper-slide-shadow-left,
    .swiper-slide-shadow-right {
      z-index: 0;
      backface-visibility: hidden;
    }
    .swiper-cube-shadow {
      position: absolute;
      left: 0;
      bottom: 0px;
      width: 100%;
      height: 100%;
      opacity: 0.6;
      z-index: 0;

      &:before {
        content: '';
        background: #000;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        -webkit-filter: blur(50px);
        filter: blur(50px);
      }
    }
  }

  /***** effect-flip *****/
  .swiper-flip {
    overflow: visible;
    .swiper-slide {
      pointer-events: none;
      backface-visibility: hidden;
      z-index: 1;
      .swiper-slide {
        pointer-events: none;
      }
    }
    .swiper-slide-active {
      &,
      & .swiper-slide-active {
        pointer-events: auto;
      }
    }
    .swiper-slide-shadow-top,
    .swiper-slide-shadow-bottom,
    .swiper-slide-shadow-left,
    .swiper-slide-shadow-right {
      z-index: 0;
      backface-visibility: hidden;
    }
  }

  
`
