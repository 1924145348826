import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Dropdown, Input } from '@purple/design/react'
import cn from 'classnames'
import styled from 'styled-components'
import i18n from 'language/i18n'

// RTK
import { useLazyGetFileTokenQuery } from 'store/services/query/boardQuery'
import {
  useLazyGetMediaFileQuery,
  usePostUploadMediaMutation
} from 'store/services/query/fileQuery'

// Components
import AlphaEditor from 'components/Board/Common/Core/AlphaEditor'
import { confirmPopup } from 'components/Popup'
import { ContainedButton } from '@purple/design/react'

// Hooks
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { BOARD_API_ERROR_TYPE } from 'constants/boardTypes'

// Utils
import { commonSessionError } from 'utils/appUtility'
import {
  addPrevBoardForm,
  commonBoardError,
  isAcceptImageExtension,
  removePrevBoardForm,
  replaceNewLine
} from 'utils/boardUtilsForStore'
import { moveBoard } from 'routes/board/locations'
import { isLauncher } from '@purple/common/utils/checkUtility'
import { media } from 'assets/styles/media'
import { onError, defaultNullImage } from 'utils/imageUtility'

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  ${({ layoutType }) => layoutType === 'popup' && 'padding: 0 12px;'}

  ${media.phone`
    ${({ layoutType }) => layoutType === 'popup' && 'padding-top: 20px;'}
  `}
`

const DropdownWrapper = styled.div`
  margin-bottom: 16px;
  flex-shrink: 0;
  width: 200px;
  ${media.phone`
    width: 100%;
    margin-bottom: 8px;
  `}
`

const InputWrapper = styled.div`
  margin-bottom: 10px;
  flex-shrink: 0;
  ${media.phone`
    margin-bottom: 8px;
  `}
`

const imageAcceptType = '.png, .jpg, .jpeg, .gif'

const ButtonContainer = styled.div`
  flex-shrink: 0;
  margin: 40px 0;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  .mobile-btn {
    display: none;
    ${media.phone`
      display: block;
    `}
  }
  .pc-btn {
    ${media.phone`
      display: none;
    `}
  }

  ${ContainedButton} {
    min-width: 100px;
    ${media.phone`
      flex: 1;
    `}
  }

  ${media.phone`
     margin: 16px 0;
  `}
`

const BoardForm = ({
  layoutType = '',
  boardType = '',
  disabledDropdown = false,
  categoryList,
  handleSubmit,
  handleCancel,
  defaultTitle = '',
  defaultBoardAlias,
  defaultContents = '',
  serviceAlias = 'purple',
  loading,
  cancelText = '',
  submitText = ''
}) => {
  const { boardAlias, gameCode } = useParams()

  const location = useLocation()
  const { t } = useTranslation()
  const editor = useRef(null)
  const history = useHistory()
  const editorColors = [
    '#D4B64F',
    '#AAAAAA',
    '#494B54',
    '#3D3F49',
    '#A08144',
    '#68924E',
    '#2D7B9D',
    '#1749AB',
    '#991ED4',
    '#E85C3E'
  ]

  const [selectedBoardAlias, setSelectedBoardAlias] = useState(
    defaultBoardAlias || gameCode + '_free'
  )

  const [init, setInit] = useState(false)
  const [title, setTitle] = useState('')

  const [getTokenTrigger] = useLazyGetFileTokenQuery()
  const [postUploadMediaTrigger] = usePostUploadMediaMutation()
  const [getMediaFileTrigger] = useLazyGetMediaFileQuery()
  const [unblock, setUnblock] = useState(() => () => {})

  const handleChangeTitle = useCallback((e) => {
    if (e.target.value.length > 100) {
      return
    }
    setTitle(e.target.value)
  }, [])

  const handleResetTitle = useCallback(() => {
    setTitle('')
  }, [])

  const isEmptyEditor = useCallback(() => {
    return (
      editor.current?.isEmptyContent() &&
      !editor.current?.getNode()?.querySelector('img') &&
      !title
    )
  }, [title])

  const handleBeforeUnload = useCallback(
    (event) => {
      if (!isEmptyEditor()) {
        event.preventDefault()
        return ''
      }
    },
    [isEmptyEditor]
  )

  const handleBeforeRouteChange = useCallback(
    (location, action) => {
      if ((action === 'POP' || action === 'PUSH') && !isEmptyEditor()) {
        return window.confirm(
          t('leave site? changes you made may not be saved')
        )
      }
    },
    [isEmptyEditor]
  )

  const handleSubmitForm = useCallback(async () => {
    // HTML 태그 제거
    const withoutTags = editor.current
      .getSubmitContents()
      .replace(/<[^>]*>/g, '')
    // &nbsp; 일반 공백으로 변환
    const normalizedSpaces = withoutTags.replace(/&nbsp;/g, ' ')
    // 공백 문자(스페이스, 탭, 줄바꿈)를 빈 문자열로 대체
    const withoutWhitespace = normalizedSpaces.replaceAll(/\s/g, '')

    if (!title?.trim() || withoutWhitespace.length === 0) {
      confirmPopup({
        msg: t('please enter title and content'),
        type: 'alert'
      })
      return
    }
    try {
      const { article_id } = await handleSubmit({
        title,
        selectedBoardAlias,
        contents: replaceNewLine({
          replaceString:
            '<div data-contents-type="text"><br class="ProseMirror-trailingBreak"></div>',
          origin: editor.current?.getSubmitContents()
        })
      })

      // 페이지 이동 시 alert 발생 이벤트 제거
      window.removeEventListener('beforeunload', handleBeforeUnload)
      unblock()

      history.push(
        moveBoard(
          { gameCode, boardAlias, articleId: article_id },
          boardType + '_VIEW'
        ) + location.search,
        { refetch: true }
      )
    } catch (error) {
      console.error(error)
      commonBoardError(error)
        .action(
          [
            BOARD_API_ERROR_TYPE.INVALID_COMMUNITY_AUTH,
            BOARD_API_ERROR_TYPE.NOT_FOUND_AUTHENTICATION,
            BOARD_API_ERROR_TYPE.FAILED_AUTHENTICATION
          ],
          () => {
            // 로그인 페이지 이동 전 작성된 form 저장
            addPrevBoardForm({
              title,
              contents: editor.current?.getSubmitContents()
            })

            // 로그인 페이지 이동 시 alert 발생 이벤트 제거
            window.removeEventListener('beforeunload', handleBeforeUnload)

            // 세션 만료 팝업
            commonSessionError({
              onLeft: () => {
                // 취소 시 다시 이벤트 설정
                window.addEventListener('beforeunload', handleBeforeUnload)
                removePrevBoardForm()
              }
            })
          }
        )
        .execute()
    }
  }, [
    title,
    t,
    handleSubmit,
    selectedBoardAlias,
    handleBeforeUnload,
    unblock,
    history,
    gameCode,
    boardAlias,
    boardType,
    location.search
  ])

  const handleImageUpload = useCallback(
    async (localImages = [], config) => {
      const mb10 = 1024 * 1024 * 10
      const uploadImages = []
      // 이미지 10개 이상 체크
      const imgNodes =
        editor.current.getNode().querySelectorAll('.fe-image-inner') || []

      if (localImages.length + imgNodes.length > 10) {
        confirmPopup({
          type: 'alert',
          msg: t('up to count images can be registered', { count: 10 })
        })
        return
      }

      // 이미지 크기 10MB 이상 체크
      if (localImages.find((image) => image.size > mb10)) {
        confirmPopup({
          type: 'alert',
          msg: t('please register an image within mb.', { maxMB: 10 })
        })
        return
      }

      // 이미지 확장자 체크
      if (
        localImages.find(
          (image) => !isAcceptImageExtension(image.type?.split('/')?.[1] || '')
        )
      ) {
        confirmPopup({
          type: 'alert',
          msg: t(
            'attachment is not possible with an extension that is not supported'
          )
        })
        return
      }
      for (let image of localImages) {
        const formData = new FormData()
        formData.append(config.attachmentPrefix, image)
        try {
          const token = await getTokenTrigger({
            boardAlias,
            serviceAlias
          }).unwrap()

          const uploadResponse = await postUploadMediaTrigger({
            url: `${token.uploadUrl}?json=true`,
            method: 'POST',
            body: formData
          }).unwrap()
          const fileInfo = await getMediaFileTrigger({
            url: token.fileInfoUrl,
            headers: {
              'Content-Type': 'application/json'
            }
          }).unwrap()
          const imageData = fileInfo?.find(
            (file) => file.id === uploadResponse.id
          )

          if (imageData) {
            image.url =
              imageData.fileUrl || imageData.downloadurl || imageData.url || ''
            image.width = imageData.width
            image.height = imageData.height
            uploadImages.push(image)
          }
        } catch (error) {
          console.error(error)
          commonBoardError(error).execute()
        }
      }

      return uploadImages
    },
    [
      t,
      postUploadMediaTrigger,
      getMediaFileTrigger,
      getTokenTrigger,
      boardAlias
    ]
  )

  useEffect(() => {
    if (!init) return
    if (defaultContents) {
      editor.current?.insert(defaultContents)
    }
    if (defaultTitle) {
      setTitle(defaultTitle)
    }
  }, [defaultContents, defaultTitle, init])

  useEffect(() => {
    if (isLauncher) {
      return
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    const unblockHandler = history.block(handleBeforeRouteChange)
    setUnblock(() => unblockHandler)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
      unblockHandler()
    }
  }, [history, title])

  // 이펙트 - 이미지 이벤트 바인딩
  useEffect(() => {
    if (editor.current) {
      const targetNode = editor?.current?.editorView?.dom

      const callback = (mutationsList, observer) => {
        for (const mutation of mutationsList) {
          if (mutation.type === 'childList') {
            const addedNodes = Array.from(mutation.addedNodes).filter(
              (node) => node.classList && node.classList.contains('fr-image')
            )
            const removedNodes = Array.from(mutation.removedNodes).filter(
              (node) => node.classList && node.classList.contains('fr-image')
            )
            const imgErrorEvent = (event) => onError(event, defaultNullImage)

            if (addedNodes.length > 0) {
              addedNodes.forEach((element) => {
                const getImage = element.querySelector('.fe-image')
                getImage.addEventListener('error', imgErrorEvent)
              })
            }

            if (removedNodes.length > 0) {
              removedNodes.forEach((element) => {
                const getImage = element.querySelector('.fe-image')
                getImage.removeEventListener('error', imgErrorEvent)
              })
            }
          }
        }
      }

      const observer = new MutationObserver(callback)

      const config = { childList: true, subtree: true }

      observer.observe(targetNode, config)

      return () => {
        observer.disconnect()
      }
    }
  }, [editor?.current?.getNode()])

  return (
    <Container layoutType={layoutType}>
      <DropdownWrapper>
        <Dropdown
          disabled={disabledDropdown}
          selected={
            categoryList.find(
              (category) => category.alias === selectedBoardAlias
            )?.name
          }
          allowScroll
        >
          {categoryList?.map((category, index) => (
            <button
              className={cn({
                select: selectedBoardAlias === category.alias
              })}
              onClick={() => setSelectedBoardAlias(category.alias)}
              key={index}
            >
              {category.name}
            </button>
          ))}
        </Dropdown>
      </DropdownWrapper>
      <InputWrapper>
        <Input
          value={title}
          type="text"
          onChange={handleChangeTitle}
          onResetInput={handleResetTitle}
          maxLength={100}
          placeholder={t('please enter the title')}
        />
      </InputWrapper>

      <AlphaEditor
        id={'alpha-editor'}
        editor={editor}
        options={{
          maxImages: 10,
          imageAcceptType,
          imageUpload: handleImageUpload,
          locale: i18n.language?.split('-')[0] || 'en', // ko, en, ja, zh, etc
          menus: [
            'image',
            'youtube',
            'textColor',
            'fontsize',
            'bold',
            'outdent'
          ],
          fontSize: [14, 18, 24],
          heightMin: layoutType === 'popup' ? 80 : 360,
          defaultFontSize: 14,
          colorsBackground: [
            '#D4B64F',
            '#AAAAAA',
            '#494B54',
            '#3D3F49',
            '#A08144',
            '#68924E',
            '#2D7B9D',
            '#1749AB',
            '#991ED4',
            '#E85C3E',
            'REMOVE'
          ],
          colorsText: [
            '#D4B64F',
            '#AAAAAA',
            '#494B54',
            '#3D3F49',
            '#A08144',
            '#68924E',
            '#2D7B9D',
            '#1749AB',
            '#991ED4',
            '#E85C3E',
            'REMOVE'
          ]

          // onCreateToken: getCreateToken
        }}
        handleInit={() => {
          setInit(true)
        }}
      />
      <ButtonContainer>
        <ContainedButton
          className="pc-btn"
          type="outlineSecondary"
          onClick={handleCancel}
          size="medium"
        >
          <span>{t('cancel')}</span>
        </ContainedButton>
        <ContainedButton
          className="mobile-btn"
          type={'tertiary'}
          onClick={handleCancel}
          size="medium"
        >
          <span>{cancelText ? cancelText : t('cancel')}</span>
        </ContainedButton>
        <ContainedButton
          type="primary"
          disabled={loading}
          onClick={handleSubmitForm}
          size="medium"
        >
          <span>{submitText ? submitText : t('register')}</span>
        </ContainedButton>
      </ButtonContainer>
    </Container>
  )
}

export default BoardForm
