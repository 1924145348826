import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { media } from 'assets/styles/media'
import { Icons } from '@purple/design/react'
import { position } from 'polished'

const List = styled.div`
  & .frame-enter {
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.32, 0, 0.67, 1);
  }
  & .frame-enter-active {
    opacity: 1;
  }
  & .frame-exit {
    opacity: 1;
    transition: opacity 0.3s cubic-bezier(0.33, 1, 0.68, 1);
  }
  & .frame-exit-active {
    opacity: 0;
  }
`

const Container = styled.div`
  overflow: hidden;
  text-align: center;
`

const ToastWrap = styled.li`
  margin-top: 15px;
  &:first-child {
    margin-top: 0;
  }
  text-align: center;
  position: absolute;
  /* bottom: 50%;
  left: 50%; */
  /* transform: translate(-50%, 50%); */
  z-index: 6000;
`

const ToastInfo = styled(ToastWrap)`
  ${({ $position }) => {
    if ($position === 'center') {
      return `
        bottom: 50%;
        left: 50%;
        transform: translate(-50%, 50%);
      `
    } else if ($position === 'top') {
      return `
        top: 10%;
        left: 50%;
        transform: translate(-50%, 0%);
      `
    } else if ($position === 'bottom') {
      return `
        bottom: 10%;
        left: 50%;
        transform: translate(-50%, 50%);
      `
    } else if ($position === 'left') {
      return `
        bottom: 50%;
        left: 0%;
        transform: translate(10%, 50%);
      `
    } else if ($position === 'right') {
      return `
        bottom: 50%;
        right: 0%;
        transform: translate(-10%, 50%);
      `
    }
  }};
`

const MessageBox = styled.div`
  display: block;
  align-items: center;
  background-color: ${({ theme }) => theme.color.gray900};
  ${({ theme }) => theme.elevation['12']};
  border-radius: 8px;
  padding: 24px;
  /* width: 200px; */
  border: 1px solid ${({ theme }) => theme.color.white300a};
`
const Message = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.color.glyphs600};
  ${({ theme }) => theme.typography.subtitle3};
  word-wrap: break-word;
  word-break: keep-all;

  .subtitle {
    display: block;
    margin-bottom: 8px;
    color: ${({ theme }) => theme.color.glyphs650};
  }
`

const Button = styled.button`
  color: ${({ theme }) => theme.color.point7};
  ${({ theme }) => theme.typography.button2};
  text-decoration: underline;
  margin-top: 12px;
`

const IconWrap = styled.div`
  height: 40px;
  margin-bottom: 12px;
  & > svg {
    fill: ${({ theme }) => theme.color.glyphs600};
  }
`

const ToastContent = ({ type, position, children }) => {
  switch (type) {
    case 'info':
      return <ToastInfo $position={position}>{children}</ToastInfo>
    default:
      return null
  }
}

function Toast({ toast, onRemoveToast }) {
  const {
    type,
    message,
    iconName,
    buttonName,
    timeOut = 1500,
    onClickButton,
    position = 'center'
  } = toast

  useEffect(() => {
    let failTimer
    if (failTimer) return
    failTimer = setTimeout(() => {
      onRemoveToast(toast)
      failTimer = null
    }, timeOut)
    return () => {
      if (failTimer) {
        clearTimeout(failTimer)
        failTimer = null
      }
    }
  }, [])

  return (
    <ToastContent type={type} position={position}>
      <MessageBox role="alert">
        {iconName && (
          <IconWrap>
            <Icons name={iconName} width={40} height={40} />
          </IconWrap>
        )}
        <Message dangerouslySetInnerHTML={{ __html: message }} />
        {buttonName && (
          <Button
            onClick={() => {
              onRemoveToast(toast)
              onClickButton()
            }}
          >
            {buttonName}
          </Button>
        )}
      </MessageBox>
    </ToastContent>
  )
}

function ToastList({ toasts, onRemoveToast }) {
  const nodeRef = useRef(null)
  return (
    <List>
      <TransitionGroup component="ul">
        {toasts.map((toast, i) => {
          return (
            <CSSTransition
              key={toast.id || i}
              nodeRef={nodeRef}
              classNames="frame"
              timeout={{ enter: 300, exit: 300 }}
            >
              <Toast toast={toast} onRemoveToast={onRemoveToast} />
            </CSSTransition>
          )
        })}
      </TransitionGroup>
    </List>
  )
}

function ToastContainer({ context: toasts, onRemoveToast }) {
  return (
    <Container>
      <ToastList toasts={toasts} onRemoveToast={onRemoveToast} />
    </Container>
  )
}

export default ToastContainer
