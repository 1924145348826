import { useCallback, useState } from 'react'

export default function useScrollPosition({ scrollRef, scrollViewport }) {
  const [showTopButton, setShowTopButton] = useState(false)

  const animateScrollY = useCallback(
    (scrollY) => {
      const scrollDom =
        scrollRef?.current?.osInstance()?.elements()?.viewport || scrollViewport
      const scrollTargetY = Math.ceil(scrollY)
      const isDownDirection = scrollDom?.scrollTop < scrollTargetY
      const delay = 3
      let check

      const scrollToTargetY = () => {
        const top = scrollDom?.scrollTop

        if (isDownDirection) {
          const distance = scrollTargetY - top

          const isBottom =
            Math.abs(
              scrollDom.scrollHeight -
                scrollDom?.scrollTop -
                scrollDom?.clientHeight
            ) === 0

          if (distance > 0 && !isBottom) {
            const targetY = Math.ceil(top + distance / delay)
            // MEMO: 무한호출 방어코드
            if (check === targetY) {
              return
            }
            check = targetY
            window.requestAnimationFrame(scrollToTargetY)
            scrollDom?.scrollTo(
              0,
              targetY > scrollTargetY ? scrollTargetY : targetY
            )
          }
        } else {
          const distance = top - scrollTargetY
          if (distance > 0) {
            window.requestAnimationFrame(scrollToTargetY)
            const targetY = distance - distance / delay
            scrollDom?.scrollTo(
              0,
              targetY < scrollTargetY ? scrollTargetY : targetY
            )
          }
        }
      }
      scrollToTargetY()
    },
    [scrollRef, scrollViewport]
  )

  const animateScrollTop = useCallback(() => {
    const isWindowTarget = window.scrollY > 0
    const scrollDom = isWindowTarget
      ? window
      : scrollRef?.current?.osInstance()?.elements()?.viewport || scrollViewport
    const scrollToTop = () => {
      const top = isWindowTarget ? scrollDom?.scrollY : scrollDom?.scrollTop
      const delay = 3
      if (top > 0) {
        window.requestAnimationFrame(scrollToTop)
        scrollDom?.scrollTo(0, top - top / delay)
      }
    }
    scrollToTop()
  }, [scrollRef, scrollViewport])

  const scrollTop = useCallback(
    (top) => {
      const scrollDom =
        scrollRef?.current?.osInstance()?.elements()?.viewport || scrollViewport
      scrollDom?.scroll({ top: top ? top : 0 })
    },
    [scrollRef, scrollViewport]
  )

  const checkScrollPosition = useCallback(() => {
    const scrollY = scrollRef.current?.osInstance()?.elements()
      ?.viewport?.scrollTop
    setShowTopButton(scrollY > 0)
  }, [scrollRef])

  return {
    animateScrollY,
    animateScrollTop,
    scrollTop,
    showTopButton,
    checkScrollPosition
  }
}
