import i18n from 'language/i18n'

// Components
import { confirmPopup } from 'components/Popup'

// utils
import { Config as config } from '@purple/common/utils/globalUtility'
import { NETWORK_TYPE } from 'constants/appTypes'
import { isWeb } from '@purple/common/utils/checkUtility'
import { goToStoreWebLogin } from '@purple/common/utils/commonUtility'
import SessionCheck from 'utils/sessionCheckUtility'
import store from 'config/storeConfig'

import { storeActions } from 'store/features/storeSlice'

// network 조회
export const getNetworkType = () =>
  config &&
  config.envName?.indexOf('rc') !== -1 &&
  config['build.buildNumber'] !== 'LOCAL_BUILD'
    ? window.NGP_VARS?.purple?.network
    : NETWORK_TYPE.INHOUSE

// RC망에서 접속 환경이 inhouse인지 여부
export const isInhouse = getNetworkType() === NETWORK_TYPE.INHOUSE

// 웹 접속 예외처리 조건
export const isWebException = config.envName?.indexOf('live') !== -1 && isWeb

// 공통 로그인 세션 에러 처리
export const commonSessionError = (props) => {
  if (isWeb) {
    // 상태 갱신
    store.dispatch(storeActions.setLoginStatus(false))

    // gnb에 세션 만료 상태 전송
    window.STORE?.setUserData()

    // 얼럿
    confirmPopup({
      msg: i18n.t('It is available after login.'),
      type: 'confirm',
      rightButtonLabel: i18n.t('login'),
      onRight: () => {
        // 웹: 로그인 페이지로 이동
        goToStoreWebLogin()
      },
      ...(props || {})
    })
  } else {
    // 얼럿
    confirmPopup(
      {
        msg: i18n.t('reconnect to the purple'),
        showCloseIcon: false,
        type: 'alert',
        onRight: () => {
          // 각 플랫폼에서 세션 복구
          SessionCheck.sessionRecovery()
        }
      },
      {
        stringKey: 'recoveryPopup'
      }
    )
  }
}
