// Utils
import {
  isJson,
  isObjectPropertyEqual,
  deepCopy
} from '@purple/common/utils/commonUtility'
import { defaultData } from 'config/storageConfig.js'

/**
 * key값에 해당되는 DefaultData를 반환합니다.
 * @param key: STORAGE_KEY
 */
export const getStorageDefaultData = (key) => {
  if (defaultData[key]) {
    return deepCopy(defaultData[key])
  }
}
const StorageUtility = {
  //공통검사
  _validate: (key) => {
    if (!window.localStorage) {
      console.log('localStroage not Support')
      return false
    } else if (!key || defaultData[key] === undefined) {
      console.log('[storageUtility] not exsit key')
      return false
    }
    return true
  },
  //set함수에서만 사용하는 검사
  _setValidate: (data) => {
    if (Array.isArray(data) === true) {
      console.log('[storageUtility] array is not support')
      return false
    }
    return true
  },

  //유효성검사를하는 localStorage의 setItem함수
  set: (key, data) => {
    if (
      StorageUtility._validate(key) === true &&
      StorageUtility._setValidate(data) === true
    ) {
      let localData = StorageUtility.get(key)
      if (typeof data === 'object') {
        Object.keys(data).forEach((property) => {
          if (localData[property] !== undefined) {
            localData[property] = data[property]
          } else {
            console.log('[storageUtility] miss property', property)
          }
        })
        localStorage.setItem(key, JSON.stringify(localData))
      } else if (typeof data === 'string') {
        localStorage.setItem(key, data)
      }
    }
  },
  //유효성검사를하는 localStorage의 getItem함수
  get: (key) => {
    if (StorageUtility._validate(key) === true) {
      let localData = localStorage.getItem(key)
      if (localData) {
        return isJson(localData) === true ? JSON.parse(localData) : localData
      } else if (typeof defaultData[key] === 'object') {
        return deepCopy(defaultData[key])
      } else {
        return defaultData[key]
      }
    }
    return null
  },
  //로컬스토리지의 값을 디폴트값으로 변경하는 함수
  reset: (key) => {
    if (StorageUtility._validate(key) === true) {
      if (typeof defaultData[key] === 'object') {
        let deepCopyDefaultData = deepCopy(defaultData[key])
        localStorage.setItem(key, JSON.stringify(deepCopyDefaultData))
      } else if (typeof defaultData[key] === 'string') {
        localStorage.setItem(key, defaultData[key])
      }
    }
  },
  //기존의 로컬스토리지를 확인하여 속성값이 다르면 초기화 하는 로직입니다.
  initKeyCheckValidate: () => {
    Object.keys(defaultData).forEach((key) => {
      let data = StorageUtility.get(key)
      if (data && typeof data === 'object') {
        let keyDefaultData = deepCopy(defaultData[key])
        if (isObjectPropertyEqual(data, keyDefaultData) === false) {
          if (typeof keyDefaultData === 'object') {
            let concat = {
              ...keyDefaultData,
              ...data
            }
            localStorage.setItem(key, JSON.stringify(concat))
          } else {
            localStorage.setItem(key, keyDefaultData)
          }
        }
      }
    })
  }
}

if (window && window.localStorage) {
  StorageUtility.initKeyCheckValidate()
}

export default StorageUtility
