import { PREHOME_PATH } from 'routes/preHome/path'
import { isWeb, isLauncher } from '@purple/common/utils/checkUtility'

export const APP_PATH = {
  // 퍼플스토어
  ROOT: '/',
  STORE: isWeb ? '/' : '/main',
  NOTICE: '/notice',
  FAQ: '/faq',
  GAME: '/game/:gameCode', // 게임 상세
  GOODS: '/goods/:gameCode/:goodsId', // dlc 상세
  BUNDLE: '/bundle/:goodsId', // 상품 상세
  FAVORITE: '/favorite', // 찜 목록
  CART: '/cart', // 장바구니
  ORDER: '/order/:orderKey', // 주문서
  RESULT: '/order/result', // 본인인증 팝업 리턴처리용
  // 런처
  INDEX: '/index',
  HOME: '/home',
  LOBBY: '/home/:gameCode/lobby',
  PUBLISH: '/publish',
  PUBLISH_LOBBY: '/publish/:gameCode/lobby',
  PUBLISH_BOARD:
    '/publish/:gameCode/board/:boardAlias?/:boardMenu?/:articleId?',
  COUNTDOWN: '/home/:gameCode/countdown',
  PREHOME: PREHOME_PATH.PREHOME_LOBBY,
  PREWEB: PREHOME_PATH.PREHOME_WEB,
  FGT: PREHOME_PATH.FGT_LOBBY,
  FGTWEB: PREHOME_PATH.FGT_WEB,
  // 알림
  NOTIFICATION: '/notification',
  // 로딩
  LOADING: '/loading',
  // 찾을 수 없는 페이지
  NOT_FOUND: '/not-found'
}
