/*
  //로컬스토리지 키
    PURPLE_INFORMATION: 퍼플 내에서 사용되는 유저 데이터.
    LOBBY_VOD_WATCHED : 로비 영상 시청 여부
    LOBBY_REVIEW_RECOMMEND: 로비에서 리뷰 작성 권장 노출 여부
    VIDEO_PLAYER_INFO: 비디오 플레이어 정보
    STORE_REVIEW_RECOMMEND: 리뷰 작성 권장 노출 여부
    OPTIN_NOT_DISPLAY: 홍보성 이메일 수신동의 안내 다시보지 않기
 */
export const STORAGE_KEY = {
  PURPLE_INFORMATION: 'purpleInformation',
  LOBBY_VOD_WATCHED: 'lobbyVodWatched',
  LOBBY_REVIEW_RECOMMEND: 'lobbyReviewRecommend',
  VIDEO_PLAYER_INFO: 'videoPlayerInfo',
  STORE_REVIEW_RECOMMEND: 'storeReviewRecommend',
  OPTIN_NO_DISPLAY: 'optinNotDisplay'
}
