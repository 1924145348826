import { combineReducers } from 'redux'

// toolkit
import appSlice from 'store/features/appSlice'
import storeSlice from 'store/features/storeSlice'
import lobbySlice from 'store/features/lobbySlice'
import userSlice from 'store/features/userSlice'

// queries
import { npApi } from 'store/services/npApi'
import { notiApi } from 'store/services/notiApi'
import { communityApi } from 'store/services/communityApi'
import { boardCommunityApi } from 'store/services/boardCommunityApi'
import { purpleApi } from 'store/services/purpleApi'

// combine Reducers
export default combineReducers({
  lobbySlice,
  appSlice,
  storeSlice,
  userSlice,
  [npApi.reducerPath]: npApi.reducer,
  [notiApi.reducerPath]: notiApi.reducer,
  [communityApi.reducerPath]: communityApi.reducer,
  [boardCommunityApi.reducerPath]: boardCommunityApi.reducer,
  [purpleApi.reducerPath]: purpleApi.reducer
})

// combine Query
export const rootQuery = [
  npApi.middleware,
  notiApi.middleware,
  communityApi.middleware,
  boardCommunityApi.middleware,
  purpleApi.middleware
]
