import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import styled, { useTheme } from 'styled-components'

import { Icons, Thumbnail, Input, Dropdown } from '@purple/design/react'

import { useTranslation } from 'react-i18next'
import cn from 'classnames'

// RTK
// MEMO: 게임 서버목록, 캐릭터 검색 api 확인 필요
// import {
//   useGetGameServerListQuery,
//   useSearchCharacterListQuery
// } from 'store/services/query/gameQuery'

// Style
import { media } from 'assets/styles/media'

// Utils
import {
  configGameCode,
  configGameCodeGroup
} from '@purple/common/utils/globalUtility'
import { onError, defaultProfileImage } from 'utils/imageUtility'
import { chatGameCodeList } from 'utils/gameUtility'
import { getGameNameLangKey } from '@purple/common/utils/gameUtility'

// Hooks
import useDebounce from 'hooks/common/useDebounce'

// Components
import OverlayScroll from 'components/Common/OverlayScroll'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ activeGuild }) =>
    !activeGuild &&
    `
  min-height: 0;
  flex: 1;
  `};
`
const InputContainer = styled.div`
  padding: 0 24px;
  margin-bottom: ${({ activeGuild }) => (activeGuild ? '16px' : 0)};
  ${media.small`
    padding: 0 16px;
  `};
`

const DropdownWrap = styled.div`
  display: flex;
  margin-bottom: 6px;

  & > div {
    width: calc(50% - 3px);
  }

  & > div:nth-of-type(2) {
    margin-left: 6px;
  }
`

const InputWrap = styled.div`
  flex: 1;
  position: relative;
`
const ScrollWrap = styled.div`
  flex: 1;
  padding: 0 16px;
  margin-top: 12px;
  position: relative;
  min-height: 100px;

  & > button {
    margin-top: 12px;
  }
`

const SearchList = styled.ul`
  width: 100%;
`

const Item = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  padding: 0 8px;
  border-radius: 4px;

  &:not(:last-child) {
    margin-bottom: 4px;
  }

  ${({ isDisabled, theme }) =>
    !isDisabled &&
    `
    cursor: pointer;
    &:hover {
      background-color: ${theme.color.bright100};
    }
  `};

  ${({ isDisabled, isSelected, theme }) =>
    isDisabled
      ? `
    & > svg {
      fill: ${theme.color.primaryDisable};
    }
  `
      : `
    & > svg {
      fill:  ${isSelected ? theme.color.primary : 'none'};
    }
    &:hover {
      & > svg {
        fill: ${isSelected ? theme.color.primary : theme.color.bright200};
      }
    }
  `}
`

const User = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
  opacity: ${({ isDisabled }) => (isDisabled ? 0.3 : 1)};
`

const Thumb = styled.div`
  display: flex;
  flex-shrink: 0;
  margin-right: 8px;
`

const Name = styled.span`
  ${({ theme }) => theme.typography.subtitle2};
  color: ${({ theme }) => theme.color.glyphs200};
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
  margin-right: 4px;

  & > strong {
    ${({ theme }) => theme.typography.subtitle2};
    color: ${({ theme }) => theme.color.primary};
  }
`

const Server = styled.span`
  ${({ theme }) => theme.typography.body3};
  color: ${({ theme }) => theme.color.glyphs300};
`

const CenterBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  padding: 0 16px;
`

const EmptySearchUser = styled(CenterBox)`
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme }) => theme.color.glyphs300};
`

const LoadingWrap = styled(CenterBox)``

const SearchItem = ({
  item,
  isSelected,
  isJoined = false,
  onSelectCharacter
}) => {
  return (
    <Item
      isSelected={isSelected}
      isDisabled={isJoined}
      onClick={isJoined ? null : () => onSelectCharacter(item)}
    >
      <User isDisabled={isJoined}>
        <Thumb>
          <Thumbnail
            type="character"
            size="200"
            imgBg="point7"
            alt=""
            onError={onError}
            src={item.profileImageUrl || defaultProfileImage}
          />
        </Thumb>
        <Name
          dangerouslySetInnerHTML={{
            __html: item.characterName || ''
          }}
        />
        <Server>{item.serverName}</Server>
      </User>
      <Icons name="Check" width="24" height="24" />
    </Item>
  )
}

const SearchUsers = (props) => {
  const {
    myUserInfo = {},
    selectedCharacters,
    activeGuild,
    onActiveGuild,
    onSelectCharacter,
    joinedUserList = []
  } = props

  const { t } = useTranslation()
  const theme = useTheme()

  const [text, setText] = useState('')
  const [gameCodeList, setGameCodeList] = useState([])
  const [selectedGameCode, setSelectedGameCode] = useState(null)
  const [selectedServer, setSelectedServer] = useState({
    serverId: '',
    serverName: ''
  })

  // 검색 요청할 파라미터
  const [searchParams, setSearchParams] = useState({
    query: '',
    gameCode: null,
    clientReferer: window.location.href,
    pageSize: 10,
    sort: '',
    serverId: null,
    pageNo: 1
  })

  // 게임별 서버리스트 요청
  // const { gameServerList = [] } = useGetGameServerListQuery(
  //   {
  //     gameCode: configGameCode(`${selectedGameCode}.lime`)
  //   },
  //   {
  //     selectFromResult: ({ data }) => ({
  //       gameServerList: data?.gameServerInfoList
  //     }),
  //     skip: !selectedGameCode
  //   }
  // )

  const trimQuery = text.trim()
  const debouncedSearch = useDebounce(trimQuery, 300)

  // 캐릭터 검색 요청
  // const {
  //   data: searchInfo = {
  //     searchCharacterList: [],
  //     totalCount: null,
  //     requestQuery: null
  //   },
  //   isFetching,
  //   currentData,
  //   isError
  // } = useSearchCharacterListQuery(searchParams, {
  //   skip: !text || searchParams.query.length === 0
  // })

  // const { searchCharacterList, totalCount, requestQuery } = searchInfo

  // const nextPageNo = useMemo(() => {
  //   const currentPage = searchCharacterList.length / 10
  //   const isMorePage = searchCharacterList.length < totalCount
  //   return isMorePage ? currentPage + 1 : null
  // }, [searchCharacterList, totalCount])

  const scrollRef = useRef()

  // 게임코드 초기 셋팅
  const initSearchGameCode = useCallback(() => {
    const gameCode = configGameCodeGroup(myUserInfo.gameCode)
    const gameCodeList = chatGameCodeList()
    const updateGameCode =
      gameCodeList.includes(gameCode) &&
      gameCode !== configGameCode('nc') &&
      gameCode !== configGameCode('ngp')
        ? gameCode
        : selectedGameCode || gameCodeList[0]

    setGameCodeList(gameCodeList)
    setSelectedGameCode(updateGameCode)
  }, [myUserInfo, selectedGameCode])

  // 검색할 게임 변경
  const handleChangeGame = useCallback((gameCode) => {
    setSelectedGameCode(gameCode)
  }, [])

  // 서버군 변경
  const handleChangeServer = useCallback((value) => {
    setSelectedServer(value)
  }, [])

  // 검색 초기화
  const handleResetSearch = useCallback(() => {
    text && setText('')
  }, [text])

  // 스크롤 초기화
  const initScrollPosition = useCallback(() => {
    scrollRef.current?.osInstance()?.elements()?.viewport?.scroll({ top: 0 })
  }, [])

  // 검색결과에서 초대할 캐릭터 선택 시 검색 초기화
  const handleSelectCharacter = useCallback(
    (item) => {
      onSelectCharacter(item)
      handleResetSearch()
    },
    [onSelectCharacter, handleResetSearch]
  )

  const handleChangeInput = useCallback((e) => {
    const inputValue = e.target.value
    setText(inputValue)
  }, [])

  // 캐릭터 초기셋팅, 변경
  useEffect(() => {
    if (Object.keys(myUserInfo).length > 0) {
      initSearchGameCode()
      handleResetSearch()
    }
  }, [myUserInfo])

  // 게임 변경 후 서버목록 응답 시 셋팅
  // useEffect(() => {
  //   gameServerList.length > 0 && setSelectedServer(gameServerList[0])
  // }, [gameServerList])

  useEffect(() => {
    setSearchParams((prevState) => ({
      ...prevState,
      query: debouncedSearch,
      gameCode: configGameCode(`${selectedGameCode}.lime`),
      serverId: selectedServer.serverId,
      pageNo: 1
    }))
  }, [debouncedSearch, selectedGameCode, selectedServer])

  // 첫페이지 검색시 스크롤 초기화
  // useEffect(() => {
  //   !currentData && initScrollPosition()
  // }, [currentData, initScrollPosition])

  useEffect(() => {
    initScrollPosition()
  }, [selectedServer])

  const textRef = useRef(text)
  textRef.current = text

  // text 빈값일 때 검색화면 초기화
  useEffect(() => {
    text && onActiveGuild(false) // 검색결과 화면으로 전환
    // MEMO: cef 웹뷰 인풋 버그 방어코드로 0.1초 후 text 값 비교 후 초기화
    const timeout = () =>
      setTimeout(() => {
        !textRef.current && onActiveGuild(true)
      }, 100)
    !text && !activeGuild && timeout()

    return () => clearTimeout(timeout)
  }, [text])

  return (
    <Wrapper activeGuild={activeGuild}>
      <InputContainer activeGuild={activeGuild}>
        <DropdownWrap>
          {/* 게임 선택 */}
          <Dropdown
            selected={t(getGameNameLangKey(selectedGameCode))}
            allowScroll
          >
            {gameCodeList.map((item, index) => {
              if (item === configGameCode('nc')) {
                return null
              }
              return (
                <button
                  key={index}
                  className={cn({
                    select: item === selectedGameCode
                  })}
                  onClick={() => handleChangeGame(item)}
                >
                  {t(getGameNameLangKey(item))}
                </button>
              )
            })}
          </Dropdown>

          {/* 서버군 선택 */}
          <Dropdown selected={selectedServer.serverName || ''} allowScroll>
            {/* {gameServerList &&
              gameServerList.map((item, index) => (
                <button
                  key={index}
                  className={cn({
                    select: item.serverId === selectedServer.serverId
                  })}
                  onClick={() => handleChangeServer(item)}
                >
                  {item.serverName}
                </button>
              ))} */}
          </Dropdown>
        </DropdownWrap>
        <InputWrap>
          <Input
            inputType="talk"
            type="text"
            name="title"
            placeholder={t(
              'please enter the name of the character to be invited'
            )}
            value={text}
            onChange={handleChangeInput}
            onResetInput={handleResetSearch}
            autoComplete="off"
          />
        </InputWrap>
      </InputContainer>

      {!activeGuild && (
        <OverlayScroll ref={scrollRef} element={ScrollWrap}>
          {/* {requestQuery === debouncedSearch && // 디바운스 걸리는 동안 이전 검색결과 안보이도록 처리
            searchCharacterList.length > 0 && (
              <>
                <SearchList>
                  {searchCharacterList.map((item, index) => {
                    const isSelected = selectedCharacters.some((prop) => {
                      return prop.characterKey === item.characterKey
                    })
                    const isJoined =
                      joinedUserList.some((prop) => {
                        return (
                          prop.gameCode === item.gameCode &&
                          prop.characterId === item.characterId
                        )
                      }) ||
                      (myUserInfo.gameCode === item.gameCode &&
                        myUserInfo.characterId === item.characterId)

                    return (
                      <SearchItem
                        key={index}
                        isSelected={isSelected}
                        isJoined={isJoined}
                        item={item}
                        onSelectCharacter={handleSelectCharacter}
                        theme={theme}
                      />
                    )
                  })}
                </SearchList>
                {/* 더보기 버튼 */}
          {/* {nextPageNo && (
                  <TextButton
                    type="basic"
                    size="medium"
                    width="100%"
                    onClick={() =>
                      setSearchParams((prevState) => ({
                        ...prevState,
                        pageNo: nextPageNo
                      }))
                    }
                  >
                    <span>{t('view more')}</span>
                    <Icons name="Add" />
                  </TextButton>
                )} 
              </>
          )}*/}
          {/* 결과값 없음 or 에러 */}
          {/* {!isFetching && (isError || searchCharacterList.length === 0) && (
            <EmptySearchUser>{t('no results found')}</EmptySearchUser>
          )} */}
          {/* 로딩 */}
          {/* {isFetching && <ContentLoading isLoading={true} />} */}
        </OverlayScroll>
      )}
    </Wrapper>
  )
}

export default SearchUsers
