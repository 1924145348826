import React, { memo, useRef, useLayoutEffect } from 'react'
import styled from 'styled-components'
import { createPopper } from '@popperjs/core'
import { createPortal } from 'react-dom'

// Utils
import { debounce } from '@purple/common/utils/eventsUtility'

// Hooks
import usePrevious from 'hooks/common/usePrevious'
import usePortal from 'hooks/common/usePortal'

const PopperContainer = styled.div`
  display: none;
  z-index: 2000;
  &[data-show] {
    display: block;
  }
`
const Arrow = styled.div`
  position: absolute;
  z-index: -1;
  border-bottom-color: ${({ color }) => color}; // rgba 용
  border-image: ${({ color }) => color}; // linear-gradient 용

  &::before {
    content: '';
    display: flex;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: ${({ size }) =>
      size ? `0 0 ${size * 2}px ${size * 2}px` : '0 0 8px 8px'};
    border-color: transparent;
    border-bottom-color: inherit;
    border-image: inherit;
  }

  [data-popper-placement^='top'] & {
    &:before {
      transform: rotate(45deg);
    }
  }

  [data-popper-placement^='bottom'] & {
    top: ${({ size }) => (size ? size * -1 + 'px' : '-8px')};
    &:before {
      transform: rotate(225deg);
    }
  }

  [data-popper-placement^='left'] & {
    right: ${({ size }) => (size ? size * -1 + 'px' : '-8px')};
    &:before {
      transform: rotate(315deg);
    }
  }

  [data-popper-placement^='right'] & {
    left: ${({ size }) => (size ? size * -1 + 'px' : '-8px')};
    &:before {
      transform: rotate(135deg);
    }
  }
`

function Popper({
  open,
  target,
  data,
  style,
  arrow,
  portal = true,
  portalId = 'poppers',
  children,
  passRefUpward,
  placement = 'left',
  strategy = 'fixed',
  modifiers = [
    {
      name: 'offset',
      options: {
        offset: [0, 4]
      }
    }
  ],
  preventElements,
  onClose,
  onLeave
}) {
  const popperRef = useRef(null)
  const popperInstance = useRef(null)
  const prevProps = usePrevious({
    open,
    target,
    data
  })
  const { portalElement } = usePortal(portalId)

  useLayoutEffect(() => {
    const evtPopperClose = (event) => {
      if (event?.target?.dontWantPopperClose) {
        return
      }
      const isPopperCheck = document.getElementsByClassName('popper-container')
        ? Array.from(document.getElementsByClassName('popper-container')).some(
            (item) => {
              return item.contains(event.target)
            }
          )
        : false
      const isPreventCheck = preventElements
        ? Array.from(preventElements).some((item) => {
            return item.contains(event.target)
          })
        : false
      const isLayerCheck = document.querySelector('.full-popup-layer')
        ? document.querySelector('.full-popup-layer').contains(event.target)
        : false
      const isPopperinLayerCheck = isLayerCheck
        ? document
            .querySelector('.full-popup-layer')
            .contains(document.getElementsByClassName('popper-container')[0])
        : false

      if (
        !isPopperCheck &&
        (!isLayerCheck || isPopperinLayerCheck) &&
        !isPreventCheck
      ) {
        onClose && onClose(event)
        onLeave && onLeave(event)
      }
    }

    const evtOnClick = (event) => {
      evtPopperClose(event)
    }

    const evtOnLeave = debounce((event) => evtPopperClose(event), 200)

    const destroyPopper = () => {
      if (popperInstance.current) {
        popperRef.current.removeAttribute('data-show')
        popperInstance.current.destroy()
        popperInstance.current = null
        passRefUpward && passRefUpward(popperInstance.current)
      }
    }

    const initPopper = (el) => {
      destroyPopper()
      popperInstance.current = createPopper(el, popperRef.current, {
        placement,
        strategy,
        modifiers
      })
      passRefUpward && passRefUpward(popperInstance.current)
      popperRef.current.setAttribute('data-show', '')

      onClose && document.addEventListener('click', evtOnClick)
      onLeave && document.addEventListener('mousemove', evtOnLeave)
      onClose && window.addEventListener('blur', onClose)
    }

    if (prevProps) {
      if (
        prevProps.open !== open ||
        prevProps.target !== target ||
        prevProps.data !== data
      ) {
        if (open) {
          initPopper(target)
        } else {
          destroyPopper()
        }
      }
    }

    return () => {
      onClose && document.removeEventListener('click', evtOnClick)
      onLeave && document.removeEventListener('mousemove', evtOnLeave)
      onClose && window.removeEventListener('blur', onClose)
      evtOnLeave.cancel()
    }
  }, [prevProps, open, target, data])

  return portal ? (
    portalElement &&
      createPortal(
        <PopperContainer
          ref={popperRef}
          className="popper-container"
          style={{ ...style }}
        >
          {data === undefined ? children() : data && children(data)}
          {arrow && (
            <Arrow
              data-popper-arrow
              size={arrow.size || 8}
              color={arrow.color || 'transparent'}
            />
          )}
        </PopperContainer>,
        portalElement
      )
  ) : (
    <PopperContainer
      ref={popperRef}
      className="popper-container"
      style={{ ...style }}
    >
      {data === undefined ? children() : data && children(data)}
      {arrow && (
        <Arrow
          data-popper-arrow
          size={arrow.size || 4}
          color={arrow.color || 'transparent'}
        />
      )}
    </PopperContainer>
  )
}

export default memo(
  Popper,
  (prevProps, nextProps) =>
    prevProps.open === nextProps.open &&
    prevProps.target === nextProps.target &&
    prevProps.data === nextProps.data
)
