// these sizes are arbitrary and you can set them to whatever you wish
import { css } from 'styled-components'
import { styleConfig } from 'config/designConfig'

export const sizes = {
  '4k': 3839 - styleConfig.native.launcher.solidLnb.width,
  max: 2559,
  jumbo: 1919,
  giant: 1439,
  desktop: 1259,
  tablet: 959,
  medium: 811,
  phone: 639,
  small: 359
}

export const verticalSizes = {
  small: 799,
  verySmall: 600
}

// iterate through the sizes and create a media template
export const media = Object.keys(sizes).reduce((accumulator, label) => {
  // use em in breakpoints to work properly cross-browser and support users
  // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
  const emSize = sizes[label] / 16
  accumulator[label] = (...args) => css`
    @media (max-width: ${sizes[label]}px) {
      ${css(...args)};
    }
  `
  return accumulator
}, {})

// iterate through the sizes and create a media template
export const verticalMedia = Object.keys(verticalSizes).reduce(
  (accumulator, label) => {
    // use em in breakpoints to work properly cross-browser and support users
    // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
    const emSize = verticalSizes[label] / 16
    accumulator[label] = (...args) => css`
      @media (max-height: ${emSize}em) {
        ${css(...args)};
      }
    `
    return accumulator
  },
  {}
)
