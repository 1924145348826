import React, { useMemo, useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { Icons, ActionButton } from '@purple/design/react'
import { useTranslation } from 'react-i18next'

// Constants
import { MEDIA_DIRECTION_TYPE, MEDIA_TYPE } from 'constants/mediaTypes'
import { CUSTOM_EVENT_KEY } from 'constants/customEventTypes'

// Utils
import { isPurpleApp, isLauncher } from '@purple/common/utils/checkUtility'
import { compareVersion } from '@purple/common/utils/commonUtility'
import { dayjs } from '@purple/common/utils/dayjsUtility'
import { mobileScheme } from '@purple/common/utils/mobileUtility'

// Style
import { media } from 'assets/styles/media'

// Components
import usePrevious from 'hooks/common/usePrevious'
import VideoPlayer from 'components/Common/VideoPlayer'
import YoutubePlayer from 'components/Common/YoutubePlayer'

const Header = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;

  ${({ isTouchDevice }) =>
    isTouchDevice
      ? `
    position: relative;
  `
      : `
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.1s;
  `}

  ${media.tablet`
    height: 44px;
  `};

  svg {
    fill: ${({ theme }) => theme.color.glyphs600};
  }
`

const PrevButton = styled.div`
  position: absolute;
  top: 50%;
  left: 8px;
  width: 48px;
  height: 48px;
  opacity: 0;
  transition: opacity 0.1s;
  transform: translateY(-50%);
  z-index: 3;

  button {
    width: 100%;
    height: 100%;
  }

  svg {
    width: 32px;
    height: 32px;
    fill: ${({ theme }) => theme.color.white1000a};
  }

  button:hover,
  button:active {
    svg {
      fill: ${({ theme }) => theme.color.white};
    }
  }
  button:disabled {
    cursor: default;
    svg {
      fill: ${({ theme }) => theme.color.white400a};
    }
  }
`

const NextButton = styled.div`
  position: absolute;
  top: 50%;
  right: 8px;
  width: 48px;
  height: 48px;
  opacity: 0;
  transition: opacity 0.1s;
  transform: translateY(-50%);
  z-index: 3;

  svg {
    width: 32px;
    height: 32px;
    fill: ${({ theme }) => theme.color.white1000a};
  }

  button {
    width: 100%;
    height: 100%;
  }

  button:hover,
  button:active {
    svg {
      fill: ${({ theme }) => theme.color.white};
    }
  }
  button:disabled {
    cursor: default;
    svg {
      fill: ${({ theme }) => theme.color.white400a};
    }
  }
`

const MediaBox = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  overflow: hidden;
  transform-origin: center center;

  @media (hover: hover) and (pointer: fine) {
    & > div:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(0, 0, 0, 0) 28.65%,
        rgba(0, 0, 0, 0) 71.35%,
        rgba(0, 0, 0, 0.6) 100%
      );
      opacity: 0;
      transition: 0.1s;
      z-index: 2;
    }
  }

  /* youtube 용 style */
  & > section {
    aspect-ratio: 3 / 2;
    @supports not (aspect-ratio: 3 / 2) {
      &::before {
        float: left;
        padding-top: 66.66%;
        content: '';
      }

      &::after {
        display: block;
        content: '';
        clear: both;
      }
    }
    max-width: 100%;
    max-height: 100%;
    min-width: 100%;
    width: auto;
    height: auto;
  }
`

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  display: flex;
  flex-direction: column;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      & ${Header}, ${PrevButton}, ${NextButton}, ${MediaBox} > div:before {
        opacity: 1;
      }
    }
  }

  ${media.tablet`
    background-color: ${({ theme }) => theme.color.black};
  `};
`

const ToolBox = styled.div`
  position: absolute;
  display: inline-flex;
  align-items: center;
  top: 50%;
  left: 12px;
  height: 40px;
  transform: translateY(-50%);

  button:not(:last-child) {
    margin-right: 16px;
  }
`

const MediaInfo = styled.div`
  display: inline-flex;
  align-items: center;
  text-align: center;
`

const SenderInfo = styled.h6`
  ${({ theme }) => theme.typography.body3};
  font-weight: 400;
  color: ${({ theme }) => theme.color.glyphs600};
`

const SendedDate = styled.p`
  ${({ theme }) => theme.typography.caption1};
  font-weight: 400;
  color: ${({ theme }) => theme.color.glyphs650};
  margin-left: 8px;
`

const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  overflow: hidden;
`

const Slider = styled.ul`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  transition: transform 0.2s;
  overflow: hidden;
  z-index: 1;
`

const Slide = styled.li`
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  align-items: center;
  transition: transform 0.2s ease-in-out 0.1s;
  z-index: -1;

  &#prev,
  &#active,
  &#next {
    display: block;
    z-index: 1;
  }

  &#prev {
    transform: translateX(-100%);
  }

  &#active {
    transform: translateX(0%);
  }

  &#next {
    transform: translateX(100%);
  }
`

const ImageWrap = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    position: relative;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    transform: translateZ(0);
    transform-origin: 50% 50%;
  }
`

const ExpiresType = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    margin-top: 16px;
    ${({ theme }) => theme.typography.subtitle2};
    color: ${({ theme }) => theme.color.white500a};
  }

  svg {
    fill: ${({ theme }) => theme.color.white400a} !important;
  }
`

const CloseButton = styled.div`
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
`

class VideoController {
  static send = ({ id, subType }) => {
    const video = document.getElementById(id)
    let event = {
      detail: { id, subType }
    }
    let customeEvent = new CustomEvent(CUSTOM_EVENT_KEY.VIDEO_CONTROLLER, event)
    if (video) {
      video.dispatchEvent(customeEvent)
    } else {
      console.log('not find video id', id)
    }
  }
  static parse = (id) => {
    this.send({ id, subType: 'parse' })
  }
  static play = (id) => {
    this.send({ id, subType: 'play' })
  }
  static reset = (id) => {
    this.send({ id, subType: 'reset' })
  }
  static mute = (id) => {
    this.send({ id, subType: 'mute' })
  }
  static getVideo = (id) => {
    const video = document.getElementById(id)
    if (video) {
      return video
    } else {
      return null
    }
  }
}

const MediaSlide = ({ media, index, id }) => {
  const { t } = useTranslation()
  const [expired, setExpired] = useState(media.expired)

  return (
    <Slide index={index} id={id}>
      <MediaBox>
        {expired || media.blocked ? (
          <ExpiresType>
            <Icons
              name={
                media.blocked
                  ? 'BlockBold'
                  : media.mediaType === MEDIA_TYPE.IMAGE
                  ? 'PictureOffBold'
                  : 'VideoOffBold'
              }
              width={80}
              height={80}
            />
            <p>
              {t(
                `${media.blocked ? 'this is blocked message' : 'expires File'}`
              )}
            </p>
          </ExpiresType>
        ) : media.mediaType === MEDIA_TYPE.IMAGE ? (
          <ImageWrap>
            <img
              src={media.viewUrl || media.downloadUrl}
              alt="media"
              onError={() => {
                setExpired(true)
              }}
            />
          </ImageWrap>
        ) : media.mediaType === MEDIA_TYPE.YOUTUBE ? (
          <YoutubePlayer
            id={`viewer_player_${media.id}`}
            src={media.viewUrl || media.downloadUrl}
            autoplay={false}
            viewerStatus={id}
          />
        ) : (
          <VideoPlayer
            id={`viewer_player_${media.id}`}
            src={media.viewUrl || media.downloadUrl}
            type="video/mp4"
            autoplay={false}
            thumbnail={media.thumbnailUrl || ''}
            onError={() => {
              setExpired(true)
            }}
          />
        )}
      </MediaBox>
    </Slide>
  )
}

const MediaViewer = ({
  mediaList = [],
  index,
  onPrev,
  onNext,
  onClose,
  onOpenAlbum,
  layerClose,
  onSaveLog,
  options = {}
}) => {
  const [itemIndex, setItemIndex] = useState(index || 0)
  const [touchStart, setTouchStart] = useState(null)
  const [touchEnd, setTouchEnd] = useState(null)
  const minSwipeDistance = 50

  const checkDownloadAppVersion = useMemo(() => {
    const appVersionRegx = /ncmtalk\/([0-9.]+)/g.exec(navigator.userAgent)
    const appVersion = appVersionRegx ? appVersionRegx[1] : ''
    if (appVersion) {
      return !compareVersion('6.16.0.0', appVersion, 'BELOW')
    } else {
      return false
    }
  }, [])

  const option = useMemo(
    () => ({
      download: isLauncher
        ? false
        : isPurpleApp
        ? checkDownloadAppVersion
        : true, // 다운로드 버튼
      ...options
    }),
    [options, checkDownloadAppVersion]
  )

  const isTouchDevice = useMemo(
    () =>
      'ontouchstart' in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0,
    []
  )

  const { firstIndex, lastIndex } = useMemo(() => {
    return {
      firstIndex: mediaList[0]?.id || 0,
      lastIndex: mediaList[mediaList.length - 1]?.id || 0
    }
  }, [mediaList])

  const { characterName, dateCreated, expired, blocked, downloadUrl } = useMemo(
    () => mediaList.filter((item) => item.id === itemIndex)[0] || {},
    [mediaList, itemIndex]
  )

  const { prevIndex, nextIndex } = useMemo(() => {
    return { prevIndex: itemIndex - 1, nextIndex: itemIndex + 1 }
  }, [itemIndex])

  const prevProps = usePrevious({ itemIndex, firstIndex, lastIndex })

  const handleClickPrev = useCallback(async () => {
    if (!onPrev && itemIndex === firstIndex) {
      return
    }
    if (itemIndex === firstIndex && !!onPrev) {
      await onPrev()
    } else {
      setItemIndex(itemIndex - 1)
    }
  }, [itemIndex, firstIndex, onPrev])

  const handleClickNext = useCallback(async () => {
    if (!onNext && itemIndex === lastIndex) {
      return
    }
    if (itemIndex === lastIndex && !!onNext) {
      await onNext()
    } else {
      setItemIndex(itemIndex + 1)
    }
  }, [itemIndex, lastIndex, onNext])

  const handleSave = useCallback(async () => {
    const idIndex = mediaList.findIndex((item) => item.id === itemIndex)
    const { downloadUrl, filename } = mediaList[idIndex]
    onSaveLog && onSaveLog(idIndex)
    try {
      const loc = new URL(downloadUrl, window.location)
      loc.searchParams.set('download', true)

      if (isPurpleApp) {
        mobileScheme.saveMediaToAlbum({
          downloadUrl: downloadUrl,
          type: 'image',
          filename: filename || 'PURPLE'
        })
      } else {
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = loc
        link.click()
      }
    } catch (error) {
      console.log(error)
    }
  }, [mediaList, itemIndex, onSaveLog])

  const handleClose = useCallback(() => {
    if (onClose) onClose()
    layerClose()
  }, [onClose, layerClose])

  const handleKeyDown = useCallback(
    (e) => {
      if (e.keyCode === 27) {
        handleClose()
      }
    },
    [handleClose]
  )

  const onTouchStart = useCallback((e) => {
    setTouchEnd(null)
    setTouchStart(e.targetTouches[0].clientX)
  }, [])
  const onTouchMove = useCallback(
    (e) => setTouchEnd(e.targetTouches[0].clientX),
    []
  )
  const onTouchEnd = useCallback(() => {
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance

    if (isLeftSwipe) {
      handleClickNext()
    }
    if (isRightSwipe) {
      handleClickPrev()
    }
  }, [handleClickNext, handleClickPrev, touchEnd, touchStart])

  useEffect(() => {
    // prev 방향으로 리스트가 추가된 경우
    if (prevProps && prevProps.firstIndex !== firstIndex) {
      setTimeout(() => setItemIndex((index) => index - 1), 100)
    }
  }, [prevProps, firstIndex])

  useEffect(() => {
    // next 방향으로 리스트가 추가된 경우
    if (prevProps && prevProps.lastIndex !== lastIndex) {
      setTimeout(() => setItemIndex((index) => index + 1), 100)
    }
  }, [prevProps, lastIndex])

  // 뷰어 오픈 시 동영상일 경우 재생
  useEffect(() => {
    const video = VideoController.getVideo(`viewer_player_${index}`)

    if (video) {
      VideoController.send({
        id: video?.id,
        subType: 'play'
      })
    }
  }, [])

  // 동영상 active/inactive 처리
  useEffect(() => {
    if (prevProps && prevProps.itemIndex !== itemIndex) {
      const prevVideo = VideoController.getVideo(
        `viewer_player_${prevProps.itemIndex}`
      )
      const video = VideoController.getVideo(`viewer_player_${itemIndex}`)

      if (prevVideo) {
        setTimeout(() => {
          VideoController.send({
            id: prevVideo?.id,
            subType: 'reset'
          })
        }, 300)
      }
      if (video) {
        // 슬라이드 완전히 넘어간 후 플레이
        setTimeout(() => {
          VideoController.send({
            id: video?.id,
            subType: 'play'
          })
        }, 300)
      }
    }
  }, [prevProps, itemIndex])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [])

  return (
    <Wrapper
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
    >
      <Header
        isTouchDevice={isTouchDevice}
        onTouchStart={(e) => e.stopPropagation()}
        onTouchMove={(e) => e.stopPropagation()}
        onTouchEnd={(e) => e.stopPropagation()}
      >
        <MediaInfo>
          {characterName && <SenderInfo>{characterName}</SenderInfo>}
          {dateCreated && (
            <SendedDate>{dayjs(dateCreated).format('YYYY-MM-DD')}</SendedDate>
          )}
        </MediaInfo>
        <ToolBox>
          {onOpenAlbum && (
            <div onClick={onOpenAlbum}>
              <ActionButton type="basicSub" size="large">
                <Icons name="Album" width={24} height={24} />
              </ActionButton>
            </div>
          )}
          {!expired && !blocked && downloadUrl && option.download && (
            <div onClick={handleSave}>
              <ActionButton type="basicSub" size="large">
                <Icons name="Download" width={24} height={24} />
              </ActionButton>
            </div>
          )}
        </ToolBox>
        <CloseButton onClick={handleClose}>
          <ActionButton type="basicSub" size="large">
            <Icons name="Close" width={24} height={24} />
          </ActionButton>
        </CloseButton>
      </Header>
      <Content>
        <Slider>
          {mediaList.map((media, index) => {
            return (
              media && (
                <MediaSlide
                  key={index}
                  index={index}
                  media={media}
                  id={
                    media.id === itemIndex
                      ? 'active'
                      : media.id === prevIndex
                      ? MEDIA_DIRECTION_TYPE.PREV
                      : media.id === nextIndex
                      ? MEDIA_DIRECTION_TYPE.NEXT
                      : ''
                  }
                />
              )
            )
          })}
        </Slider>
        <PrevButton>
          <ActionButton
            type="light"
            size="huge"
            bgNormal="gray300"
            bgHover="gray500"
            bgTouch="gray500"
            bgDisable="gray300"
            onClick={handleClickPrev}
            disabled={!onPrev && itemIndex === firstIndex}
          >
            <Icons name="ChevronLeftBold" width={32} height={32} />
          </ActionButton>
        </PrevButton>
        <NextButton>
          <ActionButton
            type="basic"
            size="large"
            onClick={handleClickNext}
            bgNormal="gray300"
            bgHover="gray500"
            bgTouch="gray500"
            bgDisable="gray300"
            disabled={!onNext && itemIndex === lastIndex}
          >
            <Icons name="ChevronRightBold" width={32} height={32} />
          </ActionButton>
        </NextButton>
      </Content>
    </Wrapper>
  )
}

export default MediaViewer
