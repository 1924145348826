import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'

// Component
import LayerWrapper from 'components/Popup/components/common/LayerWrapper'
import LayerHeader from 'components/Popup/components/common/LayerHeader'
import RouteContainer from 'components/Store/Game/Board/Common/RouteContainer'

// Routes
import { moveGameView } from 'routes/store/locations'

// Context
import { ToastProvider } from 'context/common/ToastContext'

// Style
import styled from 'styled-components'
import { media } from 'assets/styles/media'

//좌우 여백이 컨텐츠 안의 스크롤 좌우 12px을 뺀 수치입니다.
const LayerBody = styled.section`
  overflow: hidden;
  flex: 1;
  display: flex;
  transition-property: padding-bottom;
  transition-duration: 200ms;
  padding: 0 20px;
  min-height: min(636px, calc(100dvh - 48px - 84px));

  ${media.phone`
    padding: 20px 8px 0;
  `}
`

function GameBoardPopup({ layerClose, gameCode, boardAlias, boardConfig }) {
  const history = useHistory()

  const boardInfo = useMemo(
    () => boardConfig[boardAlias],
    [boardConfig, boardAlias]
  )

  return (
    <ToastProvider>
      <LayerWrapper width="888px" maxHeight="720px">
        <LayerHeader
          type="large-modal"
          title={boardInfo?.name || 'UNKNOWN'}
          onClickClose={() => {
            history.push({
              pathname: moveGameView({
                gameCode
              })
            })
          }}
        />
        <LayerBody maxHeight="720px">
          <RouteContainer
            options={{ ...boardInfo?.options, wideView: false }}
            gameCode={gameCode}
            boardAlias={boardAlias}
            layoutType="popup"
          />
        </LayerBody>
      </LayerWrapper>
    </ToastProvider>
  )
}

export default GameBoardPopup
