// Utils
import { Config as config } from '@purple/common/utils/globalUtility'
import { CountryCode } from '@purple/common/utils/locationUtility'
import { isWeb } from '@purple/common/utils/checkUtility'
import { isStorePage } from 'utils/storeServiceUtility'

// Constants
import { ACCOUNT_LOGIN_TYPE } from '@purple/common/constants/accountTypes'
import { COUNTRY_TYPE } from '@purple/common/constants/locationTypes'

/* eslint-disable regex/invalid */
class GoogleAnalytics {
  constructor() {
    this.GA4 = null
    this.config = {
      isNetEnvAllowed: [
        'live',
        'stage',
        'rc',
        'rc-milestone',
        'rc-milestone2'
      ].includes(config.envName),
      isCountryAllowed: [
        COUNTRY_TYPE.KR,
        COUNTRY_TYPE.TW,
        COUNTRY_TYPE.HK,
        COUNTRY_TYPE.MO,
        COUNTRY_TYPE.JP
      ].includes(CountryCode()),
      isPolicyAgreed: isWeb && isStorePage()
    }
  }

  /**
   * GTM 수동 연결 (동의 정책이 필요한 환경)
   *
   * @param {Object} options - GTM setup 매개변수
   * @property {string} [options.login_type=''] - 로그인 타입 (e.g., 'np_email', 'phone', etc.)
   * @property {string} [options.login_status=''] - 로그인 상태 (e.g., 'true', 'false')
   * @property {string} [options.user_user_agent=''] - UA
   * @property {string} [object.full_url=''] - URL (e.g., 'https://domain.com/subpath')
   * @property {string} [object.previous_visited_game_site=''] - 이전 방문 사이트의 쿠기가 있을 경우 (e.g., 'LineageW', 'Lineage2M')
   */
  initManualGTM = (
    options = {
      login_type: '',
      login_status: '',
      user_user_agent: '',
      full_url: '',
      previous_visited_game_site: ''
    }
  ) => {
    try {
      const { isNetEnvAllowed, isPolicyAgreed } = this.config

      // 1. 허용하는 네트워크 망에서만 연동
      // 2. 동의 정책이 필요한 환경
      if (isNetEnvAllowed && isPolicyAgreed) {
        const convertOptions = {
          ...options,
          login_status: String(options.login_status)
        }
        this.GA4 = new window.GoogleAnalytics(convertOptions)
        this.sendGAPage(convertOptions)
      }
    } catch (e) {
      console.log('initManualGTM 함수 ERROR')
      console.log(e.message)
    }
  }

  /**
   * GTM 초기 자동 연결
   *
   * @param {Object} options - GTM setup 매개변수
   * @property {string} [options.login_type=''] - 로그인 타입 (e.g., 'np_email', 'phone', etc.)
   * @property {string} [options.login_status=''] - 로그인 상태 (e.g., 'true', 'false')
   * @property {string} [options.user_user_agent=''] - UA
   * @property {string} [object.previous_visited_game_site=''] - 이전 방문 사이트의 쿠기가 있을 경우 (e.g., 'LineageW', 'Lineage2M')
   */
  initGTM(
    options = {
      login_type: '',
      login_status: '',
      user_user_agent: '',
      previous_visited_game_site: ''
    }
  ) {
    try {
      const { isNetEnvAllowed, isPolicyAgreed, isCountryAllowed } = this.config

      // 1. 허용하는 네트워크 망에서만 연동
      // 2. 동의 정책이 필요없는 환경
      // 3. 허용하는 Country만 연동 (수동으로 허용한 국가만 연동)
      if (isNetEnvAllowed && !isPolicyAgreed && isCountryAllowed) {
        const convertOptions = {
          ...options,
          login_status: String(options.login_status)
        }
        this.GA4 = new window.GoogleAnalytics(convertOptions)
      }
    } catch (e) {
      console.log('initGTM 함수 ERROR')
      console.log(e.message)
    }
  }

  /**
   * PV 전송
   *
   * @param {Object} object - PV 매개변수 객체
   * @property {string} [object.full_url=''] - URL (e.g., 'https://domain.com/subpath')
   */
  sendGAPage(
    object = {
      full_url: ''
    }
  ) {
    try {
      this.GA4.sendGAPage(object)
    } catch (e) {
      console.log('sendGAPage 함수 ERROR')
      console.log(e.message)
    }
  }

  /**
   * EVENT 전송 - 객체
   *
   * @param {Object} object - EVENT 매개변수 객체
   * @property {string} [object.event_name=''] - 이벤트 이름 (e.g., 'link_click')
   * @property {string} [object.event_category=''] - 이벤트 카테고리 (e.g., '클릭')
   * @property {string} [object.event_action=''] - 이벤트 액션 (e.g., '다크 모드 - 버튼 클릭')
   * @property {string} [object.event_label=''] - 이벤트 라벨 (e.g., '버튼 - ON', '버튼 - OFF')
   * @property {string} [object.event_parameter1=''] - 추가 변수 (e.g., '배너명 예시1')
   * @property {string} [object.event_button_code=''] - 버튼 코드 (e.g., 'pt_setting_noti')
   * @property {string} [object.event_game_code=''] - 게임 코드 (e.g., 'l2m', 'lms')
   */
  sendGAEvent(
    object = {
      event_name: 'link_click',
      event_category: '클릭',
      event_action: '',
      event_label: '',
      event_parameter1: '',
      event_button_code: '',
      event_game_code: ''
    }
  ) {
    try {
      this.GA4.sendGAEvent({
        ...object,
        event_name: 'link_click'
      })
    } catch (e) {
      console.log('sendGAEvent 함수 ERROR')
      console.log(e.message)
    }
  }

  /**
   * EVENT 전송 - 속성
   *
   * @param {Event} event - 이벤트 객체
   */
  sendGAAttrEvent(event) {
    try {
      this.GA4.sendGAAttrEvent(event)
    } catch (e) {
      console.log('sendGAAttrEvent 함수 ERROR')
      console.log(e.message)
    }
  }
}

if (!window.dataLayer) {
  window.dataLayer = window.dataLayer || []
}

export default new GoogleAnalytics()
