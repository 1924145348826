import React, { useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'

// Components
import BoardView from 'components/Board/Common/Core/BoardView'
import OverlayScroll from 'components/Common/OverlayScroll'
import TopButton from 'components/Popup/components/common/TopButton'

// hooks
import useScrollPosition from 'hooks/store/useScrollPosition'

const BoardViewContainer = ({
  boardType,
  layoutType = '',
  serviceAlias = 'purple',
  options
}) => {
  const { gameCode, boardAlias, articleId } = useParams()
  const scrollRef = useRef(null)

  const { checkScrollPosition, showTopButton, scrollTop } = useScrollPosition({
    scrollRef
  })

  useEffect(() => {
    scrollTop()
  }, [articleId])

  return (
    <>
      <OverlayScroll
        ref={scrollRef}
        events={{
          scroll: checkScrollPosition
        }}
      >
        <BoardView
          boardType={boardType}
          gameCode={gameCode}
          boardAlias={boardAlias}
          layoutType={layoutType}
          serviceAlias={serviceAlias}
          options={options}
        />
      </OverlayScroll>
      <TopButton scrollRef={scrollRef} showTopButton={showTopButton} />
    </>
  )
}

export default BoardViewContainer
