import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Icons, Pubbie, ContainedButton } from '@purple/design/react'

import { openOutLink } from '@purple/common/utils/commonUtility'
import { SUPPORT_PAGE_URL } from 'constants/appTypes'
import { getSSOurl } from 'api/npAPI'
import { logd } from '@purple/common/logd'
import { isLobby } from 'utils/homeUtility'

// GTM
import GoogleAnalytics from 'config/analyticsConfig'

const Container = styled.div`
  display: flex;
  height: 100%;
  background-color: ${({ theme }) => theme.color.bg100};
`

const Contents = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 24px;

  & ${ContainedButton} {
    min-width: 96px;
    margin: 24px 4px 0;
    vertical-align: middle;
  }
`

const Inner = styled(Contents)`
  margin: 8px;
  background-color: ${({ theme }) => theme.color.bg300};
  border-radius: 6px;
  ${({ theme }) => theme.elevation['2']};
`
const TextBox = styled.div`
  & > div {
    margin-bottom: 24px;
  }
  & > h2 {
    ${({ theme }) => theme.typography.headline6};
    color: ${({ theme }) => theme.color.glyphs100};
  }
  & > p {
    margin-top: 5px;
    ${({ theme }) => theme.typography.body4};
    color: ${({ theme }) => theme.color.glyphs300};
  }
`

const ComponentWrap = styled(Contents)`
  height: 100%;
`

const Content = ({
  service,
  onClickRefresh = null,
  onClickSupport = null,
  hasPubbie
}) => {
  const { t } = useTranslation()

  // 서비스별 기본 버튼 동작, onClickRefresh, onClickClose props가 있으면 우선 적용
  const defaultButtonFunc = useMemo(() => {
    switch (service) {
      default:
        return {
          refresh: () => {
            window.location.reload()
          },
          support: () => {
            console.log('openOutLink?')
            openOutLink(SUPPORT_PAGE_URL, getSSOurl)
            // getSSOurl({ url: SUPPORT_PAGE_URL }).then((res) =>
            //   window.open(
            //     res.status === 200 && res.data && res.data.ssoUrl
            //       ? res.data.ssoUrl
            //       : SUPPORT_PAGE_URL
            //   )
            // )
            // console.log('isPlaync', isPlaync(SUPPORT_PAGE_URL))
          }
        }
    }
  }, [service])

  const { buttonRefresh, supportClose } = useMemo(() => {
    return {
      buttonRefresh:
        onClickRefresh !== null ? onClickRefresh : defaultButtonFunc.refresh,
      supportClose:
        onClickSupport !== null ? onClickSupport : defaultButtonFunc.support
    }
  }, [onClickRefresh, onClickSupport, defaultButtonFunc])

  return (
    <>
      <TextBox>
        {hasPubbie && (
          <div>
            <Pubbie type="ERROR" />
          </div>
        )}
        <h2>{t('an error has occurred.')}</h2>
        <p>{t('try again later')}</p>
      </TextBox>
      <div>
        {buttonRefresh && (
          <ContainedButton
            type="outline"
            size="small"
            onClick={() => {
              if (isLobby()) {
                GoogleAnalytics.sendGAEvent({
                  event_category: '클릭',
                  event_action: '새로고침 - 버튼 클릭',
                  event_label: '버튼 - 새로고침',
                  event_button_code: 'lc_lobby_error2_refresh'
                })
                logd(
                  { type: 'lc_lobby', name: 'lc_lobby_error2_refresh' },
                  { forceCall: true }
                )
              }

              buttonRefresh()
            }}
          >
            <Icons name="Refresh" width="16" height="16" />
            <span>{t('refresh')}</span>
          </ContainedButton>
        )}
        {supportClose && (
          <ContainedButton
            type="outline"
            size="small"
            onClick={() => {
              if (isLobby()) {
                GoogleAnalytics.sendGAEvent({
                  event_category: '클릭',
                  event_action: '고객지원 - 버튼 클릭',
                  event_label: '버튼 - 고객지원',
                  event_button_code: 'lc_lobby_error2_cs'
                })
                logd(
                  { type: 'lc_lobby', name: 'lc_lobby_error2_cs' },
                  { forceCall: true }
                )
              }
              supportClose()
            }}
          >
            <Icons name="SupportHeadset" width="16" height="16" />
            <span>{t('customer service')}</span>
          </ContainedButton>
        )}
      </div>
    </>
  )
}

const CommonError = (props) => {
  const { type, service } = props

  switch (type) {
    // MEMO: 현재 보스타이머에서만 사용중입니다.
    case 'component':
      return (
        <ComponentWrap>
          <Content {...props} />
        </ComponentWrap>
      )
    case 'page':
    default:
      return (
        <Container>
          <Inner>
            <Content {...props} />
          </Inner>
        </Container>
      )
  }
}

export default CommonError
