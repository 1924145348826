import React from 'react'

// Style
import styled from 'styled-components'

import { media } from 'assets/styles/media'

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.color.bg300};
  color: ${({ theme }) => theme.color.glyphs200};
  position: relative;
  width: 100%;
  max-width: ${({ layerWidth }) => layerWidth};
  max-height: ${({ layerMaxHeight }) =>
    `min(calc(100% - 48px), ${layerMaxHeight})`};
  transition: max-height 0.2s, background-color 0.3s;
  min-width: 320px;
  min-height: 336px;
  border-radius: 8px;
  ${({ theme }) => theme.elevation['24']};

  ${media.tablet`
    border-radius: 0px;
    margin: 0;
    min-height: 100%;
    min-width: 100%;
  `};
`

// MEMO: width 기본값은 모달 팝업 가이드 max값 기준입니다.
const LayerWrapper = ({
  children,
  type = 'modal',
  width = '440px',
  maxHeight = '536px',
  style = {}
}) => {
  switch (type) {
    case 'modal':
    default:
      return (
        <Wrapper layerWidth={width} layerMaxHeight={maxHeight} style={style}>
          {children}
        </Wrapper>
      )
  }
}

export default LayerWrapper
