import React, { useRef, useState, useCallback } from 'react'
// Style
import styled from 'styled-components'

import { media } from 'assets/styles/media'
import { Icons, CircleButton } from '@purple/design/react'

// hooks
import useScrollPosition from 'hooks/store/useScrollPosition'

// Component
import OverlayScroll from 'components/Common/OverlayScroll'
import TopButton from 'components/Popup/components/common/TopButton'

const CommonWrapper = styled.div`
  position: relative;
  flex: 1;
  transition-property: max-height;
  transition-duration: 200ms;
`
const Wrapper = styled(CommonWrapper)`
  margin-bottom: 16px;
  &:not(:last-child) {
    margin-bottom: 0;
  }
`
const LargeWrapper = styled(CommonWrapper)`
  margin: 0 20px;
  ${media.tablet`
    margin: 0 12px;
  `}
  ${media.phone`
    margin: 0 4px;
  `}
`

const Content = styled.div`
  flex: 1;
  overflow: hidden;

  transition-property: margin-bottom;
  transition-duration: 200ms;

  ${({ scroll, activeButton }) =>
    scroll
      ? `
      padding: 0 16px;
      margin-bottom: ${activeButton ? '80px' : '0'};
      `
      : `
      display: flex;
      flex-direction: column;
      margin-bottom: ${activeButton ? '96px' : '16px'};
      &:not(:last-child) {
        margin-bottom:  ${
          activeButton === null ? 0 : activeButton ? '96px' : '16px'
        };
      }
    `}
`

const LargeContent = styled.div`
  flex: 1;
  overflow: hidden;
  transition-property: margin-bottom;
  transition-duration: 200ms;
  padding: 0 12px;

  &:last-child {
    margin-bottom: 40px;
    ${media.phone`
      margin-bottom: 20px;
    `}
  }
`

const LayerBody = ({
  children,
  scroll = true,
  forwardedRef,
  activeButton = null,
  animationHeight = null,
  style,
  ...otherProps
}) => {
  return scroll ? (
    <OverlayScroll ref={forwardedRef} element={Wrapper} {...otherProps}>
      <Content
        activeButton={activeButton}
        animationHeight={animationHeight}
        scroll={scroll}
        style={style}
      >
        {children}
      </Content>
    </OverlayScroll>
  ) : (
    <Content
      activeButton={activeButton}
      animationHeight={animationHeight}
      scroll={scroll}
      ref={forwardedRef}
      style={style}
      {...otherProps}
    >
      {children}
    </Content>
  )
}

const LargeLayerBody = ({
  children,
  scroll = true,
  animationHeight = null,
  style,
  hideTopButton = false,
  ...otherProps
}) => {
  const scrollRef = useRef(null)
  const { checkScrollPosition, showTopButton } = useScrollPosition({
    scrollRef
  })

  return (
    <>
      <OverlayScroll
        ref={scrollRef}
        events={{
          scroll: checkScrollPosition
        }}
        element={LargeWrapper}
        {...otherProps}
      >
        <LargeContent
          animationHeight={animationHeight}
          scroll={scroll}
          style={style}
        >
          {children}
        </LargeContent>
      </OverlayScroll>
      {!hideTopButton && (
        <TopButton scrollRef={scrollRef} showTopButton={showTopButton} />
      )}
    </>
  )
}

const LayerBodySwitch = ({ type = 'modal', ...rest }) => {
  switch (type) {
    case 'large-modal':
      return <LargeLayerBody type={type} {...rest} />
    case 'modal':
    default:
      return <LayerBody type={type} {...rest} />
  }
}

export default LayerBodySwitch
