import React, { useCallback, useEffect } from 'react'
import { acticleSharePopup } from 'components/Popup'
import { copyText } from 'utils/popupUtility'

const useArticleShare = () => {
  const copyUrlInfo = useCallback((shareUrl = window?.location?.href) => {
    copyText(shareUrl)
  }, [])

  const onShare = useCallback(
    ({ url, option }) => {
      if (option?.arr?.length === 1 && option?.arr[0] === 'url') {
        copyUrlInfo(url)
      } else {
        acticleSharePopup({ url, arr: option?.arr })
      }
    },
    [copyUrlInfo]
  )

  return { onShare }
}

export default useArticleShare
