import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

// rtk
// import { useGetInvitationUserListQuery } from 'store/services/query/inviteQuery'

// Component
import GuildUserList from 'components/Store/Game/Search/GuildUserList'

// Utils
import { configGameCode } from '@purple/common/utils/globalUtility'
import {
  convertNotJoinGuild,
  convertGuildMemberName
} from '@purple/common/utils/gameUtility'

const GuildForm = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
`

const GuildName = styled.h3`
  padding: 10px 24px;
  ${({ theme }) => `${theme.typography.subtitle6}`};
  color: ${({ theme }) => theme.color.glyphs200};
  height: 40px;
`

const NotJoinGuildInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.color.glyphs300};
`

const Empty = styled.div`
  flex: 1;
`

const initGuildInfo = {
  guildName: '',
  groupUserInfoList: []
}

const GuildContainer = (props) => {
  const { t } = useTranslation()
  const [guildInfo, setGuildInfo] = useState(initGuildInfo)

  const {
    groupUserId,
    type = 'create',
    selectedCharacters,
    onSelectCharacter,
    activeGuild = true,
    myUserInfo,
    isShowGuildTab = true,
    isEmptyBlank = false,
    notJoinStyle = {}
  } = props

  const isGameProfile = useMemo(
    () =>
      myUserInfo.gameCode !== configGameCode('nc') &&
      myUserInfo.gameCode !== configGameCode('ngp'),
    [myUserInfo]
  )

  const { data: guildUserListInfo, isError: guildUserListError } =
    // useGetInvitationUserListQuery(
    //   { gameUserId: myUserInfo.gameUserId, groupUserId },
    //   {
    //     skip: !isGameProfile,
    //     refetchOnMountOrArgChange: type === 'searchInvite'
    //   }
    // )

    // mount, 선택된 캐릭터 변경시
    useEffect(() => {
      const { gameCode } = myUserInfo
      if (
        gameCode === configGameCode('nc') ||
        gameCode === configGameCode('ngp')
      ) {
        // 초기값으로 셋팅
        setGuildInfo(initGuildInfo)
      }
    }, [myUserInfo])

  // 혈맹원 정보 받을때 업데이트
  useEffect(() => {
    if (guildUserListError) {
      setGuildInfo(initGuildInfo)
    } else if (guildUserListInfo) {
      const updateGuildUserListInfo = guildUserListInfo || initGuildInfo
      setGuildInfo(updateGuildUserListInfo)
    }
  }, [guildUserListInfo, guildUserListError])

  if (!activeGuild || !guildInfo) {
    return null
  }
  const { guildName, groupUserInfoList } = guildInfo

  return (
    <>
      {isGameProfile && groupUserInfoList.length > 0 ? (
        <GuildForm>
          {isShowGuildTab && <GuildName>{guildName}</GuildName>}

          {/* 혈맹원 목록 */}
          <GuildUserList
            type={type}
            myUserInfo={myUserInfo}
            guildUserList={groupUserInfoList}
            selectedList={selectedCharacters}
            onSelectCharacter={onSelectCharacter}
            isEmptyBlank={isEmptyBlank}
          />
        </GuildForm>
      ) : (
        <Empty />
      )}
    </>
  )
}

export default GuildContainer
