import React, { useCallback } from 'react'
import { Rating } from 'react-simple-star-rating'
import styled, { useTheme } from 'styled-components'

// MEMO: 별점 svg 동적 로딩 이슈로 내부 파일로 변경
// import { Icons } from '@purple/design/react'
import { ReactComponent as StarFilled } from 'assets/media/images/svg/StarFilled.svg'

const RatingView = styled.span`
  display: inline-flex;
  align-items: center;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`
const StarWrap = styled.span`
  display: inline-block;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  position: relative;

  & svg {
    position: absolute;
    left: 0;
    top: 0;

    &:last-of-type > path {
      clip-path: inset(0 ${({ point }) => 100 - point * 100}% 0 0);
    }
  }
`

export function StarRating(props) {
  const { size = 20, initialValue = 0, readonly, disabled = false } = props
  const theme = useTheme()
  const readonlyStars = useCallback(() => {
    const fillCount = Math.floor(initialValue)
    const point = (initialValue - fillCount).toFixed(1)
    const result = []

    Array(5)
      .fill()
      .forEach((item, index) => {
        if (index < fillCount) {
          result.push(
            <StarFilled
              key={index}
              // name="StarFilled"
              fill={theme.color.warning}
              width={size}
              height={size}
            />
          )
        } else if (index === fillCount && point > 0) {
          result.push(
            <StarWrap size={size} point={point} key={index}>
              <StarFilled
                // name="StarFilled"
                fill={theme.color.warningDisable}
                width={size}
                height={size}
              />
              <StarFilled
                // name="StarFilled"
                fill={theme.color.warning}
                width={size}
                height={size}
              />
            </StarWrap>
          )
        } else {
          result.push(
            <StarFilled
              // name="StarFilled"
              key={index}
              fill={theme.color.warningDisable}
              width={size}
              height={size}
            />
          )
        }
      })

    return result
  }, [initialValue, size, theme])

  return readonly || disabled ? (
    <RatingView size={size} disabled={disabled}>
      {readonlyStars()}
    </RatingView>
  ) : (
    <Rating
      allowTitleTag={false}
      allowFraction={false}
      fillIcon={
        <StarFilled
          // name="StarFilled"
          fill={theme.color.warning}
          width={size}
          height={size}
        />
      }
      emptyIcon={
        <StarFilled
          // name="StarFilled"
          fill={theme.color.successDisable}
          width={size}
          height={size}
        />
      }
      {...props}
    />
  )
}

export default StarRating
