import { lazyRetry } from '@purple/common/utils/lazyUtility'
import { APP_PATH } from 'routes/path'
import {
  isLauncher,
  isWeb,
  isPurpleApp
} from '@purple/common/utils/checkUtility'
import { isInhouse, getNetworkType, isWebException } from 'utils/appUtility'
import { NETWORK_TYPE } from 'constants/appTypes'

const IndexPage = lazyRetry(() => import('views/IndexPage'))
const HomePage = lazyRetry(() => import('views/HomePage'))
const PublishPage = lazyRetry(() => import('views/PublishPage'))
const PreHomePage = lazyRetry(() => import('views/PreHomePage'))
const StorePage = lazyRetry(() => import('views/StorePage'))
const ResultPage = lazyRetry(() => import('views/ResultPage'))
const NotifyPage = lazyRetry(() => import('views/NotifyPage'))
const LoadingPage = lazyRetry(() => import('views/LoadingPage'))
const NotFoundPage = lazyRetry(() => import('views/NotFoundPage'))

const launcherRoutes = [
  // 로비
  {
    redirect: false,
    path: APP_PATH.HOME,
    component: HomePage,
    network: NETWORK_TYPE.INHOUSE
  },
  {
    redirect: false,
    path: [APP_PATH.PREHOME, APP_PATH.PREWEB],
    component: PreHomePage,
    network: NETWORK_TYPE.INHOUSE
  },
  {
    redirect: false,
    path: [APP_PATH.FGT, APP_PATH.FGTWEB],
    component: PreHomePage,
    network: NETWORK_TYPE.INHOUSE
  },
  {
    redirect: false,
    path: APP_PATH.PUBLISH,
    component: PublishPage,
    network: NETWORK_TYPE.SIE
  },
  // 알림
  {
    redirect: false,
    path: APP_PATH.NOTIFICATION,
    component: NotifyPage,
    network: NETWORK_TYPE.INHOUSE
  }
]

// 스토어 공통 path
const storeCommonPaths = [
  APP_PATH.STORE,
  APP_PATH.NOTICE,
  APP_PATH.FAQ,
  APP_PATH.GAME,
  APP_PATH.GOODS,
  APP_PATH.BUNDLE,
  APP_PATH.FAVORITE,
  APP_PATH.CART,
  APP_PATH.ORDER
]

const getNetworkRoutes = () => {
  const networtType = getNetworkType()
  return isWebException
    ? []
    : isInhouse
    ? launcherRoutes
    : launcherRoutes.filter((item) => {
        return item.network === networtType
      })
}

let routes = [
  // root 주소로 접근 시 런처일때만 /index 로 리다이렉트
  {
    redirect: isLauncher,
    path: APP_PATH.ROOT,
    to: APP_PATH.INDEX
  },

  // 런처
  {
    redirect: isWebException,
    path: APP_PATH.INDEX,
    to: APP_PATH.ROOT,
    component: IndexPage
  },
  ...getNetworkRoutes(),

  // 로딩
  {
    redirect: isWebException,
    path: APP_PATH.LOADING,
    to: APP_PATH.ROOT,
    component: LoadingPage
  },

  // 정의되지 않은 페이지
  { redirect: false, path: APP_PATH.NOT_FOUND, component: NotFoundPage },

  // 결제관련 서비스 동의 팝업 처리 페이지
  { redirect: false, path: APP_PATH.RESULT, component: ResultPage },

  // 기존 브랜드웹 주소로 접속 시 리다이렉트
  {
    redirect: isWeb,
    path: '/:region/intro/notice/:articleId?',
    to: APP_PATH.NOTICE
  },
  {
    redirect: isWeb,
    path: '/:region/intro/faq/:category?/:articleId?',
    to: APP_PATH.FAQ
  },
  {
    redirect: isWeb,
    path: '/:region/intro/:featureId?',
    to: APP_PATH.STORE
  },
  {
    redirect: isWeb,
    path: '/intro',
    to: APP_PATH.STORE
  },

  // 퍼플 스토어
  {
    redirect: false,
    path: isPurpleApp
      ? [APP_PATH.ROOT, ...storeCommonPaths] // 퍼플앱에서 / 패스 허용
      : [...storeCommonPaths],
    component: StorePage
  },

  { redirect: true, to: APP_PATH.NOT_FOUND }
]

export default routes
