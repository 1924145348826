import { useEffect, useState } from 'react'

// Contants
import { PAGE_STATUS } from 'constants/appTypes'

// Utils
import { checkLoginFailed, isLauncher } from '@purple/common/utils/checkUtility'
import { hasLimeStatus } from 'utils/storeUtility'

function usePageStatus(isMaintenanceStatus = false) {
  const currentPageStatus = () =>
    hasLimeStatus() || isMaintenanceStatus
      ? PAGE_STATUS.MAINTENANCE
      : checkLoginFailed()
      ? PAGE_STATUS.ERROR
      : PAGE_STATUS.NORMAL

  const [pageStatus, setPageStatus] = useState(currentPageStatus())

  // 이펙트 - 페이지 상태 체크
  useEffect(() => {
    if (pageStatus !== currentPageStatus()) {
      setPageStatus(currentPageStatus())
    }
  })

  return { pageStatus }
}

export function usePageStatusSimple(
  isMaintenanceStatus = false,
  isError = false
) {
  const currentPageStatus = () => {
    //라임점검 or 직접에러 or 런처이면서 로그인 안되어 있으면 에러
    if (isError) {
      return PAGE_STATUS.ERROR
    }
    if (isMaintenanceStatus) {
      return PAGE_STATUS.MAINTENANCE
    }
    return PAGE_STATUS.NORMAL
  }

  const [pageStatus, setPageStatus] = useState(currentPageStatus())

  // 이펙트 - 페이지 상태 체크
  useEffect(() => {
    if (pageStatus !== currentPageStatus()) {
      setPageStatus(currentPageStatus())
    }
  })

  return { pageStatus }
}

export default usePageStatus
