import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

// RTK
import {
  usePutArticleMutation,
  useGetArticleDetailQuery,
  useGetSubCategoriesQuery
} from 'store/services/query/boardQuery'

// Components
import BoardForm from 'components/Board/Common/Core/BoardForm'

// Hooks
import { useParams, useHistory, useLocation } from 'react-router-dom'
import useSearchParams from 'hooks/common/useSearchParams'

// Utils
import { commonBoardError, removePrevBoardForm } from 'utils/boardUtilsForStore'
import { getLocale } from '@purple/common/utils/commonUtility'

const BoardEditContainer = ({
  boardType,
  serviceAlias = 'purple',
  layoutType = ''
}) => {
  const searchParams = useSearchParams()
  const { boardAlias, gameCode, articleId } = useParams()
  const history = useHistory()
  const { t } = useTranslation()

  const boardForm = useMemo(() => {
    const boardForm = JSON.parse(sessionStorage.getItem('board-form') || '{}')
    if (searchParams.get('isExpiredSession')) {
      removePrevBoardForm()
      return boardForm
    }
  }, [])

  // 상세 호출
  const {
    data: articleData,
    error: articleDetailError,
    isError: isArticleDetailError,
    isSuccess: isArticleDetailSuccess
  } = useGetArticleDetailQuery({
    boardAlias,
    articleId,
    serviceAlias
  })

  // query
  const {
    data: categoryData,
    error: categoryError,
    isSuccess: isCategorySuccess,
    isError: isCategoryError
  } = useGetSubCategoriesQuery({
    serviceAlias,
    boardAlias,
    categoryAlias: `${boardAlias}_${gameCode}` || ''
  })

  const [putArticleTrigger, result] = usePutArticleMutation()

  const categoryList = useMemo(() => {
    return (
      categoryData?.map((category) => {
        return {
          alias: category.board?.board_alias,
          name:
            category.board?.board_name_i18_n?.[getLocale()] ||
            category.board?.board_name_i18_n?.['en-US'],
          id: category.board?.id
        }
      }) || []
    )
  }, [categoryData])

  const handleSubmit = useCallback(
    async ({ title, contents, selectedBoardAlias }) => {
      const category = categoryList.find(
        (category) => category.alias === selectedBoardAlias
      )

      if (!category) {
        throw new Error('not defined category_id')
      }

      return await putArticleTrigger({
        articleId,
        serviceAlias,
        boardAlias,
        params: {
          article: {
            title,
            contents
          },
          category_id: category.id,
          parent_article_id: null
        }
      }).unwrap()
    },
    [putArticleTrigger, serviceAlias, boardAlias, isCategorySuccess]
  )

  const handleCancel = useCallback(() => {
    history.goBack()
  }, [])

  useEffect(() => {
    if (isArticleDetailError || isCategoryError) {
      commonBoardError(articleDetailError || categoryError).execute()
      history.goBack()
    }
  }, [isArticleDetailError, isCategoryError])

  return (
    <>
      {isArticleDetailSuccess && isCategorySuccess && (
        <BoardForm
          layoutType={layoutType}
          boardType={boardType}
          disabledDropdown={true}
          categoryList={categoryList}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          defaultTitle={boardForm?.title || articleData?.content_meta?.title}
          defaultBoardAlias={
            articleData?.content_meta?.category_board?.board_alias
          }
          defaultContents={boardForm?.contents || articleData?.content?.content}
          loading={result.isLoading}
          submitText={t('modify')}
        />
      )}
    </>
  )
}

export default BoardEditContainer
