import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

// Routes
import { APP_PATH } from 'routes/path'
import { STORE_PATH } from 'routes/store/path'
import {
  PURPLE_BOARD_NOTICE_PATH,
  PURPLE_BOARD_FAQ_PATH
} from 'routes/board/path'

// Contants
import { GAME_CODE } from '@purple/common/constants/gameTypes'
import {
  LANG_TYPE,
  LANG_TYPE_KO,
  LANG_CODE_TYPE
} from '@purple/common/constants/locationTypes'

// Utils
import { hasGameCode } from '@purple/common/utils/gameUtility'
import { goToWebLogin } from '@purple/common/utils/commonUtility'
import { dateFormat } from '@purple/common/utils/dayjsUtility'

import OverlayScroll from 'components/Common/OverlayScroll'

// styles
const Container = styled.div`
  position: fixed;
  left: ${({ navPosition }) => (navPosition === 'left' ? 0 : 'initial')};
  right: ${({ navPosition }) => (navPosition === 'right' ? 0 : 'initial')};
  top: 0;
  bottom: 0;
  padding-bottom: 10px;
  width: 170px;
  display: flex;
  z-index: 1000;
  flex-direction: column;
  flex-shrink: 0;
  contain: layout;
  background: rgba(0, 0, 0, 0.32);
  color: #ddd;
  font-size: 13px;
  height: ${({ isOpenNav }) => (isOpenNav === true ? 'initial' : '36px')};
  overflow: hidden;
  max-height: 100%;
  & a,
  & span {
    cursor: pointer;
    padding: 5px;
    & > em {
      padding: 2px 5px;
      background-color: #fa21eb;
      border-radius: 9px;
      font-size: 10px;
      font-weight: bold;
      margin-left: 5px;
      vertical-align: text-bottom;
    }
  }

  .subMenu {
    padding-left: 20px;
    display: none;
    & a,
    & span {
      display: block;
      cursor: pointer;
      padding: 5px;
      font-size: 11px;
    }
    &.open {
      display: block;
    }
  }
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const Toggle = styled.div`
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  padding: 10px;
`

const NavPosition = styled.div`
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  text-align: center;
  padding: 10px;
`
const LeftPosition = styled.div`
  flex: 1;
`
const RightPosition = styled.div`
  flex: 1;
  text-align: center;
`
function Nav(props) {
  const [isOpenNav, setIsOpenNav] = useState(
    localStorage.getItem('nav:open') === null
      ? true
      : localStorage.getItem('nav:open') === 'true'
  )
  const [navPosition, setNavPosition] = useState(
    localStorage.getItem('nav:position') || 'left'
  )
  const selectRef = useRef(null)
  const locale = localStorage.getItem('i18nextLng')

  const onToggleOpenClose = () => {
    setIsOpenNav(!isOpenNav)
    localStorage.setItem('nav:open', !isOpenNav)
  }

  const onClickPosition = (position) => {
    setNavPosition(position)
    localStorage.setItem('nav:position', position)
  }

  const changeLocale = () => {
    const locale = selectRef.current.value
    const url = new URL(window.location.href)

    url.searchParams.set('locale', locale)
    window.location.href = url.toString()
  }

  const handleSubmenu = (e) => {
    const opendEl = document.querySelector('.subMenu.open')
    if (opendEl) opendEl.classList.remove('open')
    e.target.nextSibling.classList.add('open')
  }

  const makeSitemap = () => {
    const doc = document.implementation.createDocument('', '', null)
    const root = doc.createElement('urlset')
    root.setAttribute('xmlns', 'http://www.sitemaps.org/schemas/sitemap/0.9')
    root.setAttribute('xmlns:xhtml', 'http://www.w3.org/1999/xhtml')

    const domains = ['https://purple.plaync.com']
    const paths = ['/', '/notice', '/faq']
    const hreflangs = [
      '',
      ...Object.keys(LANG_CODE_TYPE).map((item) => item.toLowerCase())
    ]
    const locales = [
      '',
      ...Object.values(LANG_CODE_TYPE).map((item) => LANG_TYPE[item])
    ]

    domains.forEach((domain) => {
      paths.forEach((path, pathIndex) => {
        hreflangs.forEach((lang, index) => {
          let defaultTemplate = lang === ''
          let urlElem = doc.createElement('url')
          let locElem = doc.createElement('loc')
          locElem.innerHTML = defaultTemplate
            ? `${domain}${path}`
            : `${domain}${path}?locale=${lang}`

          if (defaultTemplate) {
            locElem.innerHTML = `${domain}${path}`

            let modElem = doc.createElement('lastmod')
            modElem.innerHTML = dateFormat(new Date())

            let freqElem = doc.createElement('changefreq')
            freqElem.innerHTML = 'monthly'
            let priorityElem = doc.createElement('priority')
            priorityElem.innerHTML = pathIndex === 0 ? 1 : 0.8

            urlElem.appendChild(locElem)
            urlElem.appendChild(modElem)
            urlElem.appendChild(freqElem)
            urlElem.appendChild(priorityElem)
          } else {
            locElem.innerHTML = `${domain}${path}?locale=${locales[index]}`
            urlElem.appendChild(locElem)
          }

          hreflangs.forEach((otherLang, index) => {
            if (otherLang === '') {
              return
            }
            let linkElem = doc.createElement('xhtml:link')
            linkElem.setAttribute('rel', 'alternate')
            linkElem.setAttribute('hreflang', otherLang)
            linkElem.setAttribute(
              'href',
              `https://purple.plaync.com${path}?locale=${locales[index]}`
            )
            urlElem.appendChild(linkElem)
            if (index === hreflangs.length - 1) {
              let linkElem = doc.createElement('xhtml:link')
              linkElem.setAttribute('rel', 'alternate')
              linkElem.setAttribute('hreflang', 'x-default')
              linkElem.setAttribute('href', `https://purple.plaync.com${path}`)
              urlElem.appendChild(linkElem)
            }
          })
          root.appendChild(urlElem)
        })
      })
    })

    doc.appendChild(root)

    var serializer = new XMLSerializer()
    var xmlString = serializer.serializeToString(doc)

    const returnValue = `<?xml version="1.0" encoding="UTF-8"?>${xmlString}`
    navigator.clipboard.writeText(returnValue).then(() => {
      console.log('복사되었습니다. sitemap.xml 에 붙여넣으세요')
    })
  }

  return (
    <Container className="nav" isOpenNav={isOpenNav} navPosition={navPosition}>
      <Toggle onClick={onToggleOpenClose}>
        {isOpenNav ? '접기' : '펼치기'}
      </Toggle>
      <NavPosition>
        <LeftPosition
          onClick={() => {
            onClickPosition('left')
          }}
        >
          Left
        </LeftPosition>
        <RightPosition
          onClick={() => {
            onClickPosition('right')
          }}
        >
          Right
        </RightPosition>
      </NavPosition>

      <i>---------------------------------</i>

      <OverlayScroll element={Section}>
        <Section>
          <Link to={APP_PATH.INDEX}>런처 INDEX</Link>
          {hasGameCode(GAME_CODE.L2M) && (
            <>
              <Link to="/home/l2m/lobby">L2M</Link>
            </>
          )}
          <>
            <a href="/home/l2mru/lobby?countryCode=RU">L2M RU</a>
          </>
          <>
            <a href="/home/l2mnu/lobby?countryCode=US">L2M NU</a>
          </>
          <a href="/home/l2mnct/lobby?countryCode=TW">L2M NCT</a>
          <a href="/home/l2mncj/lobby?countryCode=JP">L2M NCJ</a>
          {hasGameCode(GAME_CODE.LMS) && (
            <>
              <Link to="/home/lms/lobby">LM홈</Link>
            </>
          )}
          <a href="/home/lmj/lobby?countryCode=JP">LMJ</a>
          {hasGameCode(GAME_CODE.TRM) && (
            <>
              <Link to="/home/tricksterm/lobby">트릭스터홈</Link>
            </>
          )}
          {hasGameCode(GAME_CODE.BNS2) && (
            <>
              <Link to="/home/bns2/lobby">B2</Link>
            </>
          )}
          <a href="/home/bns2/lobby?countryCode=TW">BNS2 TW</a>
          <a href="/home/bns2/lobby?countryCode=JP">BNS2 JP</a>
          <Link to="/home/linw/lobby">LW</Link>
          {hasGameCode(GAME_CODE.H3) && (
            <>
              <Link to="/home/h3/lobby">H3</Link>
            </>
          )}
          <Link to="/home/tl/lobby">TL</Link>
          <a href="/home/tl/lobby?countryCode=SG">TL SG</a>
          <a href="/home/tl/lobby?countryCode=ID">TL ID</a>

          <Link to="/home/battlecrush/lobby">BATTLE CRUSH</Link>

          {hasGameCode(GAME_CODE.LIN) && (
            <>
              <Link to="/home/lin/lobby">Lin</Link>
            </>
          )}
          <Link to="/home/linncj/lobby?countryCode=JP">LIN NCJ</Link>
          {hasGameCode(GAME_CODE.LIN2) && (
            <>
              <Link to="/home/lin2/lobby">Lin2</Link>
            </>
          )}
          <Link to="/home/lin2nct/lobby?countryCode=TW">Lin2 NCT</Link>
          <Link to="/home/lin2ncj/lobby?countryCode=JP">Lin2 NCJ</Link>
          {hasGameCode(GAME_CODE.AION) && (
            <>
              <Link to="/home/aion/lobby">AION</Link>
            </>
          )}
          <Link to="/home/aionnct/lobby?countryCode=TW">AION NCT</Link>
          <Link to="/home/aionncj/lobby?countryCode=JP">AION NCJ</Link>
          {hasGameCode(GAME_CODE.BNS) && (
            <>
              <Link to="/home/bns/lobby">BNS</Link>
            </>
          )}
          <Link to="/home/bnsnct/lobby?countryCode=TW">BNS NCT</Link>
          <Link to="/home/bnsncj/lobby?countryCode=JP">BNS NCJ</Link>
          {hasGameCode(GAME_CODE.LOVEBEAT) && (
            <>
              <Link to="/home/lovebeat/lobby">LOVEBEAT</Link>
            </>
          )}
          <Link to="/home/hoyeon/lobby">HOYEON</Link>
          <a href="/home/hoyeon/lobby?countryCode=TW">HOYEON TW</a>
          <a href="/home/hoyeon/lobby?countryCode=JP">HOYEON JP</a>
          <Link to="/home/nlw/lobby">NLW</Link>
          <a href="/home/nlw/lobby?countryCode=TW">NLW TW</a>
          <a href="/home/nlw/lobby?countryCode=JP">NLW JP</a>
          <a href="/home/nlw/lobby?countryCode=US">NLW US(ETC)</a>
          <Link to="/home/janryumonJP/lobby?countryCode=JP">작룡문M JP</Link>
          <Link to="/home/bnsncw/lobby?countryCode=US" onClick={handleSubmenu}>
            BNS NCA
          </Link>
          <Link to="/home/aionncw/lobby?countryCode=US" onClick={handleSubmenu}>
            AION NCA
          </Link>
          <Link to="/home/lin2ncw/lobby?countryCode=US" onClick={handleSubmenu}>
            LIN2 NCA
          </Link>
          <Link to="/publish/p2plu/lobby">p2plu</Link>
          <Link to="/publish/p2smm/lobby">p2smm</Link>
          <i>---------------------------------</i>
          <Link to={APP_PATH.NOTIFICATION}>알림</Link>
          <i>---------------------------------</i>
          <Link to={STORE_PATH.INDEX}>스토어 메인</Link>
          <Link to={PURPLE_BOARD_NOTICE_PATH.LIST}>소식</Link>
          <Link to={PURPLE_BOARD_FAQ_PATH.LIST}>자주 묻는 질문</Link>
          {process.env.NODE_ENV !== 'production' && (
            <span onClick={makeSitemap}>사이트맵 만들기</span>
          )}
          <i>---------------------------------</i>
          {'\u00a0 0. 공통(개발)'}
          <Link
            to={{
              pathname: '/game/p2plu/board/news/list',
              search: '?isGameBoard=true',
              state: { refetch: true }
            }}
          >
            - 소식 게시판
          </Link>
          <Link
            to={{
              pathname: '/game/p2plu/board/game/list',
              search: '?isGameBoard=true',
              state: { refetch: true }
            }}
          >
            - 커뮤니티 게시판
          </Link>
          {'\u00a0 1. 런처'}
          <Link
            to={{
              pathname: '/publish/p2plu/board/news/list',
              state: { refetch: true }
            }}
          >
            - 소식 게시판
          </Link>
          <Link
            to={{
              pathname: '/publish/p2plu/board/game/list',
              state: { refetch: true }
            }}
          >
            - 커뮤니티 게시판
          </Link>
          {'\u00a0 2. 스토어'}
          <Link
            to={{
              pathname: '/game/p2plu/board/news/list',
              state: { refetch: true }
            }}
          >
            - 메인게임: 소식 게시판
          </Link>
          <Link
            to={{
              pathname: '/game/p2plu/board/game/list',
              state: { refetch: true }
            }}
          >
            - 메인게임: 커뮤니티 게시판
          </Link>
          <Link
            to={{
              pathname: '/game/p2smm/board/news/list',
              state: { refetch: true }
            }}
          >
            - 기본게임: 소식 게시판
          </Link>
          <Link
            to={{
              pathname: '/game/p2smm/board/game/list',
              state: { refetch: true }
            }}
          >
            - 기본게임: 커뮤니티 게시판
          </Link>
          <i>---------------------------------</i>
          <div
            style={{
              cursor: 'pointer',
              padding: '5px'
            }}
            onClick={() => {
              goToWebLogin()
            }}
          >
            로그인
          </div>
          <a href="/logout?returnUrl=/home/l2m/lobby">로그아웃</a>
          <select onChange={changeLocale} ref={selectRef} defaultValue={locale}>
            {Object.keys(LANG_TYPE).map((key) => (
              <option value={LANG_TYPE[key]} key={key}>
                {LANG_TYPE_KO[key]}
              </option>
            ))}
          </select>
        </Section>
      </OverlayScroll>
    </Container>
  )
}

export default Nav
