import React, { forwardRef } from 'react'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { ClickScrollPlugin, OverlayScrollbars } from 'overlayscrollbars'
import { isMobile } from '@purple/common/utils/checkUtility'

// clickScroll 옵션 사용하기 위해 플러그인 적용
OverlayScrollbars.plugin(ClickScrollPlugin)

export const OverlayScroll = forwardRef(
  (
    {
      children,
      options = {
        scrollbars: {
          autoHide: isMobile ? 'scroll' : 'leave',
          theme: 'os-theme-purple',
          autoHideDelay: 700,
          dragScroll: true,
          clickScroll: true // 트랙에서 클릭하여 스크롤 이동 가능
        },
        update: {
          elementEvents: [['img', 'load']],
          debounce: [0, 33],
          attributes: null,
          ignoreMutation: null
        }
      },
      events = null,
      element = 'div',
      disabled = false
    },
    ref
  ) => {
    return disabled ? (
      children
    ) : (
      <OverlayScrollbarsComponent
        ref={ref}
        options={options}
        events={events}
        element={element}
      >
        {children}
      </OverlayScrollbarsComponent>
    )
  }
)

export default OverlayScroll
