import React, { useEffect, Suspense, useMemo, useCallback } from 'react'
import styled from 'styled-components'
import {
  Route,
  Switch,
  Redirect,
  matchPath,
  useLocation
} from 'react-router-dom'
import Cookies from 'js-cookie'

// router
import AppRoutes from 'routes'
import { APP_PATH } from 'routes/path'

// rtk
import { useDispatch } from 'react-redux'
import { appActions } from 'store/features/appSlice'

// hooks
import { useAppProcess } from 'hooks/app/useAppAsync'

// utils
import { Config as config } from '@purple/common/utils/globalUtility'
import {
  globalCheckSSOLink,
  goToWebLogin,
  setSsoUrl
} from '@purple/common/utils/commonUtility'
import {
  isWeb,
  isPurpleApp,
  isMobile,
  isLauncher
} from '@purple/common/utils/checkUtility'
import {
  withScope,
  captureException
} from '@purple/common/utils/errorReportUtility'
import usePrevious from 'hooks/common/usePrevious'

// components
import Nav from 'components/Native/Nav'
import PopupManagerContainer from 'components/Popup/PopupManagerContainer'
import IndexPage from 'views/IndexPage'
import LoadingPage from 'views/LoadingPage'
import CommonError from './components/Error/CommonError'
import { ErrorBoundary } from 'react-error-boundary'
import { ToastProvider } from 'context/common/ToastContext'

import { useGetSSOUrlMutation } from 'store/services/query/npQuery'
import { isInhouse } from 'utils/appUtility'

// GTM
import GoogleAnalytics from 'config/analyticsConfig'

// 공홈 UI
import AlphaEditorPopup from 'components/Board/Common/Core/AlphaEditorPopup'

const Main = styled.main.attrs({
  id: 'app'
})`
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  position: relative;
`
const RouterWrap = styled.div.attrs({
  id: 'pageWrap'
})`
  display: flex;
  overflow: hidden;
  flex-wrap: nowrap;
  flex-direction: column;
  flex: 1;
`

function App() {
  const location = useLocation()
  const { appProcessTrigger } = useAppProcess()
  const [getSSOUrlTrigger] = useGetSSOUrlMutation()

  const showNavigation =
    isWeb &&
    config?.envName !== 'live' &&
    !isMobile &&
    !isPurpleApp &&
    isInhouse

  const dispatch = useDispatch()

  const fallbackPage = useMemo(() => {
    if (isLauncher) {
      if (
        !matchPath(window.location.pathname, {
          path: APP_PATH.NOTIFICATION
        }) &&
        !matchPath(window.location.pathname, {
          path: APP_PATH.LOADING
        })
      ) {
        return <IndexPage />
      }
    } else if (isPurpleApp) {
      return null
    } else {
      return <LoadingPage />
    }
  }, [])

  useEffect(() => {
    appProcessTrigger()
    if (isLauncher) {
      window.addEventListener('click', globalCheckSSOLink)
      return () => {
        window.removeEventListener('click', globalCheckSSOLink)
      }
    }
  }, [])

  //openOutLink에 getSSOurl 등록
  useEffect(() => {
    !isWeb && setSsoUrl(getSSOUrlTrigger)
  }, [])

  // 핸들러 - 에러 바운더리 로그 전송
  const logError = useCallback((error, info) => {
    withScope((scope) => {
      scope.setExtras(info)
      captureException(error)
    })
  }, [])

  useEffect(() => {
    if (GoogleAnalytics.GA4) {
      GoogleAnalytics.sendGAPage({
        full_url: window.location.href,
        previous_visited_game_site: Cookies.get('visitedGame') ?? ''
      })
    }
  }, [location.pathname])

  return (
    <Main>
      {showNavigation && <Nav />}
      <RouterWrap>
        <ErrorBoundary
          onError={logError}
          fallbackRender={() => <CommonError type="page" />}
        >
          <Suspense fallback={fallbackPage}>
            <ToastProvider>
              <Switch>
                {AppRoutes.map((prop, index) => {
                  if (prop.redirect) {
                    return (
                      <Redirect
                        exact
                        from={prop.path}
                        to={prop.to}
                        key={index}
                      />
                    )
                  }
                  if (prop.component) {
                    return (
                      <Route
                        key={index}
                        path={prop.path}
                        component={prop.component}
                      />
                    )
                  }
                  return ''
                })}
              </Switch>
            </ToastProvider>
          </Suspense>
        </ErrorBoundary>
        <PopupManagerContainer />
      </RouterWrap>
    </Main>
  )
}

export default App
