import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// RTK
import {
  boardQuery,
  usePostReactionMutation,
  useDeleteReactionMutation,
  useGetArticleExtraQuery
} from 'store/services/query/boardQuery'

// Style
import { Icons } from '@purple/design/react'
import { ExtraContainer } from 'components/Board/Common/Core/BoardStyled'

// Utils
import { commonBoardError } from 'utils/boardUtilsForStore'

function BoardViewExtra({
  serviceAlias = 'purple',
  likeCount,
  commentCount,
  options
}) {
  const { boardAlias, articleId } = useParams()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  // 쿼리 - 게시글 상세 정보 조회 (좋아요 여부)
  const { data: extra } = useGetArticleExtraQuery({
    boardAlias,
    serviceAlias,
    articleId
  })

  // 뮤테이션 - 리액션 정보 전송
  const [postReactionTrriger] = usePostReactionMutation()

  // 뮤테이션 - 리액션 정보 삭제
  const [deleteReactionTrriger] = useDeleteReactionMutation()

  // 핸들러 - 좋아요 등록/해제
  const postReaction = useCallback(
    async (actionStatus, action = 'LIKE') => {
      try {
        if (actionStatus) {
          const res = await deleteReactionTrriger({
            serviceAlias,
            boardAlias,
            articleId,
            action
          }).unwrap()
          console.log('res', res)

          dispatch(
            boardQuery.util.updateQueryData(
              'getArticleDetail',
              {
                serviceAlias,
                boardAlias,
                articleId
              },
              (draft) => {
                draft.content_meta.reactions.like_count = res.count
              }
            )
          )
        } else {
          const res = await postReactionTrriger({
            serviceAlias,
            boardAlias,
            articleId,
            action
          }).unwrap()

          dispatch(
            boardQuery.util.updateQueryData(
              'getArticleDetail',
              {
                serviceAlias,
                boardAlias,
                articleId
              },
              (draft) => {
                draft.content_meta.reactions.like_count = res.count
              }
            )
          )
        }

        dispatch(
          boardQuery.util.updateQueryData(
            'getArticleExtra',
            {
              serviceAlias,
              boardAlias,
              articleId
            },
            (draft) => {
              draft.reactions.LIKE = !actionStatus
            }
          )
        )
      } catch (error) {
        console.error(error)
        commonBoardError(error).execute()
      }
    },
    [
      dispatch,
      serviceAlias,
      boardAlias,
      articleId,
      postReactionTrriger,
      deleteReactionTrriger
    ]
  )

  return (
    <ExtraContainer>
      {!!options.like && (
        <span>
          <button onClick={() => postReaction(extra?.reactions?.LIKE)}>
            <Icons
              name={extra?.reactions?.LIKE ? 'HeartFilled' : 'Heart'}
              width="20"
              height="20"
            />
            <span>{t('favorite')}</span>
            <em>{likeCount}</em>
          </button>
        </span>
      )}
      {!!options.comment && (
        <span>
          <span>{t('comment')}</span>
          <em>{commentCount}</em>
        </span>
      )}
    </ExtraContainer>
  )
}

export default BoardViewExtra
