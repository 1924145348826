import React from 'react'
import styled from 'styled-components'
import { ActionLoading, ContainedButton } from '@purple/design/react'
import { media } from 'assets/styles/media'

const FooterWrap = styled.div`
  display: flex;
  padding: 24px;
  width: 100%;
  flex-shrink: 0;
  ${media.tablet`
    padding: 16px;
  `};

  & > button {
    width: 100%;
    ${media.tablet`
      ${({ theme }) => theme.button.contained.primary.size.medium};
    `}
  }

  & > button:nth-of-type(2) {
    margin-left: 8px;
  }
`

const ActionFooterWrap = styled(FooterWrap)`
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  height: ${({ activeButton }) => (activeButton ? 'auto' : 0)};
  padding: ${({ activeButton }) => (activeButton ? '24px' : 0)};
  overflow: hidden;

  & > button {
    transform: ${({ activeButton }) =>
      activeButton ? 'translateY(0)' : 'translateY(100%)'};
    transition: transform 0.2s;
  }
`

// 비활성화 => 활성화 버튼
const LayerFooter = ({
  leftButtonLabel,
  rightButtonLabel,
  onClickLeft,
  onClickRight,
  submitDisabled,
  leftButtonType,
  rightButtonType,
  footerWrapStyle,
  rightIsLoading = false,
  leftIsLoading = false
}) => {
  return (
    <FooterWrap style={footerWrapStyle}>
      {leftButtonLabel && (
        <ContainedButton
          type={leftButtonType ? leftButtonType : 'tertiary'}
          size="large"
          onClick={onClickLeft}
        >
          {leftIsLoading ? (
            <ActionLoading isLoading={true} />
          ) : (
            <span>{leftButtonLabel}</span>
          )}
        </ContainedButton>
      )}
      {rightButtonLabel && (
        <ContainedButton
          type={rightButtonType ? rightButtonType : 'primary'}
          size="large"
          onClick={onClickRight}
          disabled={submitDisabled}
        >
          {rightIsLoading ? (
            <ActionLoading isLoading={true} />
          ) : (
            <span>{rightButtonLabel}</span>
          )}
        </ContainedButton>
      )}
    </FooterWrap>
  )
}

// 비노출 => 노출 버튼
const LayerActionFooter = ({
  leftButtonLabel,
  rightButtonLabel,
  onClickLeft,
  onClickRight,
  activeButton,
  submitDisabled
}) => {
  return (
    <ActionFooterWrap activeButton={activeButton}>
      {leftButtonLabel && (
        <ContainedButton type="tertiary" size="large" onClick={onClickLeft}>
          <span>{leftButtonLabel}</span>
        </ContainedButton>
      )}
      {rightButtonLabel && (
        <ContainedButton
          type="primary"
          size="large"
          onClick={onClickRight}
          disabled={submitDisabled}
        >
          <span>{rightButtonLabel}</span>
        </ContainedButton>
      )}
    </ActionFooterWrap>
  )
}

export { LayerFooter, LayerActionFooter }
