import React from 'react'

/*
복붙해서 사용하면 편합니다.
  import TemplateFC from 'components/Common/TemplateFC'
  
  <TemplateFC
    isInspection={false}
    isLoading={false}
    isEmpty={false}
    isAPIError={false}
    component={()=>{
      return <div>string function class 모두 가능</div>
    }}
    loadingComponent={
      <div>
        로딩
      </div>
    }
    emptyComponent={() => <div>비어있습니다.</div>}
    inspectionComponent={<div>점검 페이지</div>}
    apiErrorComponent={()=><div>api Error</div>}
  />
*/

const _checkComponentType = (component) => {
  if (typeof component === 'string' || !component) {
    return component
  } else if (typeof component === 'function') {
    return component()
  } else if (React.isValidElement(component)) {
    return React.cloneElement(component)
  }
}

const templateFC = ({
  isInspection = false,
  isAPIError = false,
  isLoading = false,
  isEmpty = false,
  inspectionComponent,
  loadingComponent,
  apiErrorComponent,
  emptyComponent,
  component = <div>필수값입니다.</div>
}) => {
  if (isInspection) {
    //점검
    return _checkComponentType(inspectionComponent)
  } else if (isLoading) {
    //로딩
    return _checkComponentType(loadingComponent)
  } else if (isAPIError) {
    //api에러
    return _checkComponentType(apiErrorComponent)
  } else if (isLoading === false && isEmpty) {
    //비어있음
    return _checkComponentType(emptyComponent)
  } else {
    return _checkComponentType(component)
  }
}

export default templateFC
