import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'
import { Config as config } from '@purple/common/utils/globalUtility'

const baseQuery = fetchBaseQuery({
  baseUrl:
    config['static.board.community.api.url'] ||
    'https://api-community.plaync.com',
  prepareHeaders: (headers, { getState }) => {
    return headers
  }
})

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 })

export const boardCommunityApi = createApi({
  reducerPath: 'boardCommunityApi',
  baseQuery: baseQueryWithRetry,
  endpoints: () => ({})
})
