import * as pathToRegexp from 'path-to-regexp'

//path
import {
  PURPLE_BOARD_FAQ_PATH,
  PURPLE_BOARD_NOTICE_PATH,
  GAME_BOARD_STORE_PATH,
  GAME_BOARD_LAUNCHER_PATH
} from 'routes/board/path'
import { BOARD_TYPE } from 'constants/boardTypes'

// locations path
const locationPurpleBoard = {
  faqList: pathToRegexp.compile(PURPLE_BOARD_FAQ_PATH.LIST),
  faqView: pathToRegexp.compile(PURPLE_BOARD_FAQ_PATH.VIEW),
  noticeList: pathToRegexp.compile(PURPLE_BOARD_NOTICE_PATH.LIST),
  noticeView: pathToRegexp.compile(PURPLE_BOARD_NOTICE_PATH.VIEW)
}

// locations path
const locationStoreGameBoard = {
  list: pathToRegexp.compile(GAME_BOARD_STORE_PATH.LIST),
  view: pathToRegexp.compile(GAME_BOARD_STORE_PATH.VIEW),
  write: pathToRegexp.compile(GAME_BOARD_STORE_PATH.WRITE),
  edit: pathToRegexp.compile(GAME_BOARD_STORE_PATH.EDIT)
}

// locations path
const locationLauncherGameBoard = {
  list: pathToRegexp.compile(GAME_BOARD_LAUNCHER_PATH.LIST),
  view: pathToRegexp.compile(GAME_BOARD_LAUNCHER_PATH.VIEW),
  write: pathToRegexp.compile(GAME_BOARD_LAUNCHER_PATH.WRITE),
  edit: pathToRegexp.compile(GAME_BOARD_LAUNCHER_PATH.EDIT)
}

// move location
export const moveBoard = (param, type) => {
  switch (type) {
    // 퍼플 게시판
    case `${BOARD_TYPE.FAQ}_LIST`:
      return locationPurpleBoard.faqList(param)
    case `${BOARD_TYPE.FAQ}_VIEW`:
      return locationPurpleBoard.faqView(param)
    case `${BOARD_TYPE.NOTICE}_LIST`:
      return locationPurpleBoard.noticeList(param)
    case `${BOARD_TYPE.NOTICE}_VIEW`:
      return locationPurpleBoard.noticeView(param)

    // 스토어 게임 게시판
    case `${BOARD_TYPE.NEWS}_LIST`:
    case `${BOARD_TYPE.COMMUNITY}_LIST`:
      return locationStoreGameBoard.list(param)
    case `${BOARD_TYPE.NEWS}_VIEW`:
    case `${BOARD_TYPE.COMMUNITY}_VIEW`:
      return locationStoreGameBoard.view(param)
    case `${BOARD_TYPE.NEWS}_WRITE`:
    case `${BOARD_TYPE.COMMUNITY}_WRITE`:
      return locationStoreGameBoard.write(param)
    case `${BOARD_TYPE.NEWS}_EDIT`:
    case `${BOARD_TYPE.COMMUNITY}_EDIT`:
      return locationStoreGameBoard.edit(param)

    // 런처 게시판
    case `${BOARD_TYPE.LNEWS}_LIST`:
    case `${BOARD_TYPE.LCOMMUNITY}_LIST`:
      return locationLauncherGameBoard.list(param)
    case `${BOARD_TYPE.LNEWS}_VIEW`:
    case `${BOARD_TYPE.LCOMMUNITY}_VIEW`:
      return locationLauncherGameBoard.view(param)
    case `${BOARD_TYPE.LNEWS}_WRITE`:
    case `${BOARD_TYPE.LCOMMUNITY}_WRITE`:
      return locationLauncherGameBoard.write(param)
    case `${BOARD_TYPE.LNEWS}_EDIT`:
    case `${BOARD_TYPE.LCOMMUNITY}_EDIT`:
      return locationLauncherGameBoard.edit(param)
    default:
      return '/'
  }
}
