import api from 'config/axiosNpConfig'
import { Config as config } from '@purple/common/utils/globalUtility'

/**
 * 유저 캐릭터 목록 조회
 * @param {object} params {
 *  all: Boolean,
 *  mode: String
 *  intro: Boolean
 * }
 */
export const getMyCharacterList = (params) => {
  const path = '/api/lime/chat/getMyCharacterList'

  return api
    .post(path, params, { timeout: config.apiTimeoutNP || 10000 })
    .then((res) => res)
    .catch((error) => {
      throw error
    })
}

/**
 * sso url 조회
 * @param {object} params {
 *  url: String
 * }
 */
export const getSSOurl = (params) => {
  const path = '/api/session/sso'

  return api
    .post(path, params)
    .then((res) => res)
    .catch((error) => {
      throw error
    })
}
