import React, { useMemo, useCallback, useState, useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'

import { Icons, ContainedButton } from '@purple/design/react'

// Routes
import { moveBoard } from 'routes/board/locations'

// Constants
import { CUSTOM_EVENT_KEY } from 'constants/customEventTypes'

// Style
import styled from 'styled-components'
import { BottomButtonWrap } from 'components/Board/Common/Core/BoardStyled'
import { ActionButton, Thumbnail, ContentLoading } from '@purple/design/react'
import { lineClamp, ellipsis } from 'assets/styles/mixin'
import { media } from 'assets/styles/media'

// Utils
import { sendCustomEvent } from 'utils/customEventUtility'
import { onError, profileThumbnailUrl } from 'utils/imageUtility'
import { getLocale } from '@purple/common/utils/commonUtility'
import { isAfter24Hours, dateFormat } from '@purple/common/utils/dayjsUtility'
import { getBoardType, getGameCode } from 'utils/boardUtilsForStore'
import { commonSessionError } from 'utils/appUtility'

// Hooks
import useArticleShare from 'hooks/common/useArticleShare'
import { shallowEqual, useSelector } from 'react-redux'

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const Loading = styled.div`
  position: relative;
  flex: 1;
  min-height: 200px;
  height: 100%;
`

const EmptyList = styled.div`
  margin: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: 120px;
  & > p {
    ${({ theme }) => theme.typography.body3};
    color: ${({ theme }) => theme.color.glyphs300};
  }
  & > button {
    margin-top: 16px;
  }
`

const ArticleList = styled.ul`
  display: flex;
  flex-direction: column;
`

const ArticleItem = styled.li`
  padding: 16px;
  min-height: 25px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 8px;
  border-radius: 4px;
  background: ${({ theme, isTopNotice }) =>
    isTopNotice ? theme.color.highlight3 : 'none'};

  &:last-child {
    margin-bottom: 0px;
  }
  &:hover {
    background-color: ${({ theme }) => theme.color.bright100};

    ${media.phone`
        background: none;
    `};
  }

  .board-wideView & {
    margin-bottom: 16px;
    ${media.desktop`
      margin-bottom: 8px;
    `};
  }

  ${media.phone`
    padding: ${({ isTopNotice }) => (isTopNotice ? '12px 8px;' : '8px 0;')} 
  `};
`

const Title = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme }) => theme.color.glyphs200};

  & > div {
    display: flex;
    align-items: center;
    min-width: 0px;
  }

  & > div > i {
    margin-left: 4px;
    color: ${({ theme }) => theme.color.glyphs250};
  }

  & > div > p {
    ${ellipsis}
  }

  svg + p {
    margin-left: 4px;
  }

  .board-postDate {
    display: none;
    ${({ theme }) => theme.typography.body4};
    color: ${({ theme }) => theme.color.glyphs250};
    margin-left: auto;
    padding-left: 50px;
    flex-shrink: 0;

    .board-wideView & {
      display: block;
      ${media.desktop`
        display: none;
      `};
    }
  }

  svg {
    margin-left: 4px;
  }

  ${media.phone`
    flex-wrap: wrap;
    ${({ theme }) => theme.typography.body4};
  `};
`

const Category = styled.em`
  ${({ theme }) => theme.typography.subtitle2};
  color: ${({ theme }) => theme.color.point9};
  margin-right: 8px;
  flex-shrink: 0;

  &:empty {
    display: none;
  }

  ${media.phone`
    ${({ theme }) => theme.typography.caption2};
    width: 100%;
    margin-right: 0;
    margin-bottom: 4px;
  `};
`

const Meta = styled.div`
  ${({ theme }) => theme.typography.body4};
  color: ${({ theme }) => theme.color.glyphs250};
  white-space: nowrap;
  display: flex;
  margin-top: 4px;

  & > span {
    display: flex;
    align-items: center;
    & > i {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      background-color: ${({ theme }) => theme.color.button100};
      margin-left: 4px;
      border-radius: 50%;
      & > svg {
        fill: ${({ theme }) => theme.color.white900a};
      }
    }
  }

  > span + span::before {
    content: '·';
    display: inline-block;
    margin: 0 4px;
  }

  .board-wideView & {
    .board-postDate {
      display: none;
      ${media.desktop`
        display: inline-block;
      `};
    }
  }

  ${media.phone`
    ${({ theme }) => theme.typography.body5};
    color: ${({ theme }) => theme.color.glyphs300};
    margin-top: 8px;
  `};
`
const Profile = styled.div`
  margin-right: 4px;
  border: 1px solid ${({ theme }) => theme.color.bright100};
  border-radius: 50%;
`

const ResetWrap = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 0px;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.color.glyphs200};
  white-space: nowrap;
  word-wrap: break-word;
  ${({ theme }) => theme.typography.body2};
  strong {
    ${({ theme }) => theme.typography.subtitle5};
    margin-left: 2px;
  }
`

const SearchNotFindContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  ${({ theme }) => theme.typography.headline6};
  text-align: center;
  color: ${({ theme }) => theme.color.glyphs200};

  em {
    color: ${({ theme }) => theme.color.point9};
    word-break: break-all;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    button {
      margin-top: 24px;
    }
  }
`

const SearchSummary = styled.div`
  margin-top: 12px;
  word-wrap: break-word;
  word-break: keep-all;
  ${lineClamp(2)}
  color: ${({ theme }) => theme.color.glyphs300};
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;

  strong {
    font-weight: 400;
    color: ${({ theme }) => theme.color.point9};
  }

  ${media.phone`
      display:none;
  `};
`

function BoardList(props) {
  const {
    handleMoreList = () => {},
    data = {},
    topData = {},
    searchData,
    boardAlias = (() => {
      const { boardAlias } = useParams()
      return boardAlias
    })(),
    gameCode = (() => {
      const { gameCode } = useParams()
      return gameCode
    })(),
    boardType,
    isLoading,
    isFetching,
    isTotalListSuccess,
    isTotalListError = false,
    options = {}
  } = props

  const option = useMemo(
    () => ({
      postDate: true, //작성일  노출 여부
      category: true, // 카테고리  노출 여부
      writer: true, //작성자 노출 여부
      profile: false, //프로필 이미지  노출 여부
      newBadge: true, //새글 표시 여부
      hasMore: true, //더보기 버튼  노출 여부
      wideView: false, //큰화면에서 노출되는지
      notice: true, // 상단 공지 사용 여부
      comment: true, //댓글 사용할지
      ...(options || {})
    }),
    [options]
  )

  const { t } = useTranslation()
  const history = useHistory()
  const { search } = useLocation()
  const { onShare } = useArticleShare()

  const { content_list = [], has_more = false, search_count = 0 } = data
  const { notices_list = [] } = topData
  const { npUserId } = useSelector(
    (state) => ({
      npUserId: state.storeSlice.npUserId
    }),
    shallowEqual
  )

  const handleClickNotice = useCallback(
    ({ boardAlias, articleId, categoryAlias }) => {
      history.push({
        pathname: moveBoard(
          {
            gameCode: getGameCode({ boardAlias, categoryAlias }),
            boardAlias,
            articleId
          },
          `${getBoardType({ boardAlias, boardType })}_VIEW`
        ),
        search
      })
    },
    [boardType, history, search]
  )

  const handleClickView = useCallback(
    ({ boardAlias, articleId }) => {
      history.push({
        pathname: moveBoard(
          {
            gameCode,
            boardAlias,
            articleId
          },
          `${getBoardType({ boardAlias, boardType })}_VIEW`
        ),
        search
      })
    },
    [gameCode, boardType, history, search]
  )

  const onInitQuery = useCallback(() => {
    sendCustomEvent(CUSTOM_EVENT_KEY.BOARD_SEARCH, {
      type: 'initSearch'
    })
  }, [])

  const handleClickWrite = useCallback(() => {
    if (!npUserId) {
      commonSessionError()
      return
    }
    history.push({
      pathname: moveBoard(
        {
          gameCode,
          boardAlias
        },
        `${getBoardType({ boardAlias, boardType })}_WRITE`
      ),
      search
    })
  }, [history, boardAlias, boardType, gameCode, search, npUserId])

  if (isLoading) {
    return (
      <Loading>
        <ContentLoading isLoading={true} />
      </Loading>
    )
  }

  if (isTotalListError)
    return (
      <EmptyList>
        <p>{t('A temporary error occurred.')}</p>
      </EmptyList>
    )

  if (
    !searchData &&
    content_list.length === 0 &&
    notices_list.length === 0 &&
    isTotalListSuccess
  ) {
    return (
      <EmptyList>
        <p>{t('no article')}</p>
        {option.writable && (
          <ContainedButton
            type="outlineSecondary"
            size="small"
            onClick={handleClickWrite}
          >
            <Icons name="Pencil" width="20" height="20" />
            <span>{t('writing')}</span>
          </ContainedButton>
        )}
      </EmptyList>
    )
  }

  return (
    <Container className={option.wideView ? 'board-wideView' : ''}>
      {searchData && (
        <ResetWrap>
          <Trans i18nKey="board Search Results" count={search_count || 0}>
            검색결과 <strong>{{ search_count }}</strong> 건
          </Trans>
          <ActionButton size="small" type="basicSub" onClick={onInitQuery}>
            <Icons name="Clear" width={16} height={16} />
          </ActionButton>
        </ResetWrap>
      )}
      {searchData && content_list.length === 0 && isLoading === false ? (
        <SearchNotFindContent>
          <div>
            <div>
              <Trans i18nKey="No search results for">
                <span>"</span>
                <em>{{ search: searchData }}</em>
              </Trans>
            </div>
            <ContainedButton size="medium" type="outline" onClick={onInitQuery}>
              <span> {t('list')}</span>
            </ContainedButton>
          </div>
        </SearchNotFindContent>
      ) : (
        <ArticleList>
          <>
            {!searchData &&
              notices_list.map((item, index) => {
                return (
                  <ArticleItem
                    key={index}
                    onClick={() =>
                      handleClickNotice({
                        categoryAlias:
                          item?.article_meta?.category_board?.board_alias,
                        boardAlias:
                          item?.article_meta?.root_board?.board?.board_alias,
                        articleId: item?.article_meta?.id
                      })
                    }
                    isTopNotice={true}
                  >
                    <Title>
                      <Icons name="Megaphone" width="20" height="20" />
                      <p
                        dangerouslySetInnerHTML={{
                          __html: item.article_meta?.title
                        }}
                      />
                      {option.comment && item?.reactions?.comment_count ? (
                        <i>[{item?.reactions?.comment_count || ''}]</i>
                      ) : (
                        ''
                      )}
                      {option.newBadge &&
                        isAfter24Hours(
                          item.content_top_notice?.timestamps?.posted_at
                        ) === false && (
                          <Icons name="BadgeNew" width="16" height="16" />
                        )}
                    </Title>
                  </ArticleItem>
                )
              })}
            {content_list.map((item, index) => {
              return (
                <ArticleItem
                  key={index}
                  onClick={() =>
                    handleClickView({
                      boardAlias: item?.root_board?.board?.board_alias,
                      articleId: item?.id
                    })
                  }
                  isSearch={!!searchData}
                >
                  <Title>
                    {option.category && (
                      <Category>
                        {item.category_board?.board_name_i18_n?.[getLocale()] ||
                          item.category_board?.board_name_i18_n?.['en-US']}
                      </Category>
                    )}
                    <div>
                      <p dangerouslySetInnerHTML={{ __html: item.title }} />
                      {option.comment && item?.reactions?.comment_count ? (
                        <i>[{item?.reactions?.comment_count || ''}]</i>
                      ) : (
                        ''
                      )}
                      {option.newBadge &&
                        isAfter24Hours(item.timestamps?.posted_at) ===
                          false && (
                          <Icons name="BadgeNew" width="16" height="16" />
                        )}
                    </div>

                    {option.postDate && (
                      <span className="board-postDate">
                        {dateFormat(item.timestamps?.posted_at)}
                      </span>
                    )}
                  </Title>
                  <Meta>
                    {option.profile && (
                      <Profile>
                        <Thumbnail
                          alt=""
                          gameCode=""
                          size={100}
                          sources=""
                          src={profileThumbnailUrl(
                            item.writer?.login_user?.uid || ''
                          )}
                          type="character"
                          onError={onError}
                        />
                      </Profile>
                    )}
                    {option.writer && !item.writer?.admin && (
                      <span>
                        {item?.writer?.login_user?.name}
                        {item?.reserved_fields?.reserved1
                          ?.store_has_package && (
                          <i title={t('users who purchased the game')}>
                            <Icons
                              name="GameControllerFilled"
                              width="16"
                              height="16"
                            />
                          </i>
                        )}
                      </span>
                    )}
                    {option.postDate && (
                      <span className="board-postDate">
                        {dateFormat(item.timestamps?.posted_at)}
                      </span>
                    )}
                  </Meta>

                  {searchData && (
                    <SearchSummary>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: item.search_summary
                        }}
                      ></span>
                    </SearchSummary>
                  )}
                </ArticleItem>
              )
            })}
          </>
        </ArticleList>
      )}
      {option.hasMore !== false && has_more && (
        <BottomButtonWrap>
          <ContainedButton
            size="medium"
            type="outlineSecondary"
            onClick={() => {
              handleMoreList(content_list[content_list.length - 1].id)
            }}
          >
            <Icons name="Add" width="20" height="20" />
            <span>{t('To see more details')}</span>
          </ContainedButton>
        </BottomButtonWrap>
      )}
    </Container>
  )
}

export default BoardList
