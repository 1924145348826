import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'
import useSearchUsers from 'hooks/store/useSearchUsers'

// Redux
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

// Style
import styled from 'styled-components'

// Component
import LayerWrapper from 'components/Popup/components/common/LayerWrapper'
import LayerBody from 'components/Popup/components/common/LayerBody'
import LayerHeader from 'components/Popup/components/common/LayerHeader'
import { LayerFooter } from 'components/Popup/components/common/LayerFooter'
import GuildContainer from 'components/Store/Game/Search/GuildContainer'
import SelectedItemBox from 'components/Store/Game/Search/SelectedItemBox'
import SearchUsers from 'components/Store/Game/Search/SearchUsers'

const Empty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  padding: 0 56px;
  word-break: break-word;
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme }) => theme.color.glyphs400};
`

const SendGiftPopup = ({ layerClose }) => {
  const { t } = useTranslation()

  const { selectedProfile } = useSelector(
    (state) => ({
      selectedProfile: state.userSlice.selectedProfile
    }),
    shallowEqual
  )

  const {
    activeGuild,
    confirmDisabled,
    createDisabled,
    createChannelDisabled,
    selectedCharacters,
    setActiveGuild,
    handleClickSelectCharacter
  } = useSearchUsers(selectedProfile, 'CHATTING')

  return (
    <LayerWrapper style={{ height: '536px' }}>
      <LayerHeader
        title={'선물'}
        // isShowSelectProfile={true}
        onClickClose={layerClose}
      />
      <LayerBody scroll={false}>
        {createDisabled ? (
          <Empty>
            {t('it is not possible to create a new chat')}
            <br />
            {t('please select a different character')}
          </Empty>
        ) : createChannelDisabled ? (
          <Empty>{t('it is not possible to create a new chat')}</Empty>
        ) : (
          <>
            {/* 선택된 캐릭터 */}
            {selectedCharacters.length > 0 && (
              <SelectedItemBox
                selectedItems={selectedCharacters}
                deleteItem={handleClickSelectCharacter}
              />
            )}

            {/* 캐릭터 검색 */}
            <SearchUsers
              myUserInfo={selectedProfile}
              selectedCharacters={selectedCharacters}
              onSelectCharacter={handleClickSelectCharacter}
              activeGuild={activeGuild}
              onActiveGuild={setActiveGuild}
            />

            {/* 검색 및 선택 컴포넌트 */}
            {/* {Object.keys(selectedProfile).length > 0 && (
              <GuildContainer
                type={'create'}
                myUserInfo={selectedProfile}
                selectedCharacters={selectedCharacters}
                onSelectCharacter={handleClickSelectCharacter}
                activeGuild={activeGuild}
              />
            )} */}
          </>
        )}
      </LayerBody>

      {!createDisabled && !createChannelDisabled && (
        <LayerFooter
          leftButtonLabel={t('cancel')}
          rightButtonLabel={'선물'}
          onClickLeft={layerClose}
          // onClickRight={}
          submitDisabled={confirmDisabled || selectedCharacters.length === 0}
        />
      )}
    </LayerWrapper>
  )
}

export default SendGiftPopup
