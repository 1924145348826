import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Icons, ContainedButton, Pubbie } from '@purple/design/react'
import { useTranslation } from 'react-i18next'

// Utils
import { Config as config } from '@purple/common/utils/globalUtility'
import { dateTimeFormat } from '@purple/common/utils/dayjsUtility'
import { onReload } from '@purple/common/utils/commonUtility'

const MaintenanceWrap = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 24px;
  min-height: 380px;
  & ${ContainedButton} {
    margin: 24px 4px 0;
  }
`
const TextInfo = styled.div`
  ${({ theme }) => theme.typography.headline6};
  margin-top: 24px;
  color: ${({ theme }) => theme.color.glyphs100};
`
const DateInfoWrap = styled.div`
  ${({ theme }) => theme.typography.body2};
  margin-top: 24px;
  text-align: center;
  color: ${({ theme }) => theme.color.glyphs200};
`

function BoardMaintenance() {
  const { t } = useTranslation()
  const { startDate, endDate } = useMemo(() => {
    let startDate, endDate
    startDate = config['maintenance.community.purple.startDate']
    endDate = config['maintenance.community.purple.endDate']
    return {
      startDate,
      endDate
    }
  }, [])

  return (
    <MaintenanceWrap>
      <div>
        <Pubbie type="CHECK" />
        <TextInfo>
          {t('service is under maintenance', { name: t('board') })}
        </TextInfo>
        <DateInfoWrap>
          <div>{t('start date', { startDate: dateTimeFormat(startDate) })}</div>
          <div>{t('end date', { endDate: dateTimeFormat(endDate) })}</div>
        </DateInfoWrap>
      </div>
    </MaintenanceWrap>
  )
}

export default BoardMaintenance
