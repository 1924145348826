import React, { useState, useCallback } from 'react'
import { Config as config } from '@purple/common/utils/globalUtility'
import { Icons } from '@purple/design/react'

/** wstatic 버전 */
export const wstaticVersionUrl = (url) => {
  const loc = new URL(url, window.location)
  loc.searchParams.append('v', config.wstaticVersion || 0)

  return decodeURI(loc)
}

/** 프로필 썸네일 url */
export const profileThumbnailUrl = (uid) =>
  wstaticVersionUrl(
    `${config.profileDomain}/account_profile_images/${
      uid || ''
    }?imageSize=normal`
  )

/** 스토어 게임 썸네일 url */
export const storeGameThumbnailUrl = (url) => {
  if (!url) {
    return 'undefined'
  }
  const formatValue = config.gameThumbnailFormat
  if (formatValue) {
    const loc = new URL(url, window.location)
    loc.searchParams.set('format', formatValue)
    return decodeURI(loc)
  } else {
    return url
  }
}

/**
 *  스토어 등급분류 아이콘
 *  @param type : 이름,
 *  @param name : 타입(이용등급: usage/내용정보: contents)
 */
export const storeGameClassificationUrl = (type, name) => {
  return wstaticVersionUrl(
    `${config.staticResourceDomain}/purple/store/classification/${type}_${name}.png`
  )
}

/**
 *  on Error
 *  @param e : 이벤트,
 *  @param condition : 기본이미지 URL
 */
export function onError(
  e,
  defaultSrc = wstaticVersionUrl(
    `${config.staticResourceDomain}/purple/resources/default/img_general_default.png`
  )
) {
  if (!config || !config.wstaticDomain || !config.staticResourceDomain) return
  if (e.target.src !== defaultSrc) {
    e.target.onerror = null
    e.target.src = defaultSrc
  }
}

/**
 *  onError시 해당 img 태그 display:none
 *  @param e : 이벤트,
 */
export function onErrorNone(e) {
  e.target.onerror = null
  e.target.style.display = 'none'
}

/**
 *  onError시 해당 img 태그 visibility:hidden
 *  @param e : 이벤트,
 */
export function onErrorHidden(e) {
  e.target.onerror = null
  e.target.style.visibility = 'hidden'
}

/**
 *  on Animate
 *  @param e : 이벤트
 *  @param condition : 실행조건
 */
export function onAnimate(e, condition = true) {
  if (condition) e.target.src = e.target.getAttribute('src')
}

export function itemNullStyle(e, theme) {
  // e.currentTarget.style.backgroundColor = theme.color.glyphs300
  e.currentTarget.style.opacity = 0
  // e.currentTarget.src =
  //   'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
}

export const defaultGeneralImage = wstaticVersionUrl(
  `${config.staticResourceDomain}/purple/resources/default/img_general_default.png`
)

export const defaultEmogiImage = wstaticVersionUrl(
  `${config.staticResourceDomain}/purple/resources/default/img_emogi_default.png`
)
export const defaultNotifyImage = wstaticVersionUrl(
  `${config.staticResourceDomain}/purple/resources/default/img_notify_default.png`
)
export const defaultProfileImage = wstaticVersionUrl(
  `${config.staticResourceDomain}/purple/resources/default/img_profile_default.png`
)
export const defaultNullImage = wstaticVersionUrl(
  `${config.staticResourceDomain}/purple/resources/default/null_image.png`
)

/**
 *  add Timestamp
 *  @param src : 리소스,
 */
export function appendTimestampSrc(src) {
  if (src) {
    let imageUrl = new URL(src)
    imageUrl.searchParams.set('timeStamp', new Date().getTime())
    return imageUrl.href
  } else {
    return src
  }
}

const _checkComponentType = (component) => {
  if (typeof component === 'string' || !component) {
    return component
  } else if (typeof component === 'function') {
    return component()
  } else if (React.isValidElement(component)) {
    return React.cloneElement(component)
  }
}

export const ImageTemplateFC = ({
  src = defaultProfileImage,
  errorComponent = <Icons name="PictureOff" width="32px" />
}) => {
  const [isError, setIsError] = useState(false)
  const imgOnError = useCallback(() => {
    setIsError(true)
  }, [])

  if (isError === true) {
    return _checkComponentType(errorComponent)
  } else {
    return <img alt="" src={src} onError={imgOnError} />
  }
}

/**
 * 콘티 이미지 webp로 요청
 * URL searchParams에 format=webp 추가
 * 이미지 요청 시 잘못된 URL이 들어가면 안되므로 에러 시 빈문자열
 */
export const addSearchParamsFormatWebp = (url) => {
  try {
    const newUrl = new URL(url)
    newUrl.searchParams.append('format', 'webp')
    return newUrl.href
  } catch (e) {
    return ''
  }
}
