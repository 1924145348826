import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import styled from 'styled-components'
import { shallowEqual, useSelector } from 'react-redux'

// RTK
import {
  useLazyGetFileTokenQuery,
  usePostCommentMutation,
  usePutCommentMutation,
  useDeleteCommentMutation,
  useGetCommentListQuery,
  useLazyGetCommentListQuery,
  useLazyGetCommentQuery,
  useLazyGetMyBanQuery
} from 'store/services/query/boardQuery'
import {
  usePostUploadMediaMutation,
  useLazyGetMediaFileQuery
} from 'store/services/query/fileQuery'

// Utils
import { exquisiteClick } from '@purple/common/utils/eventsUtility'
import { htmlDecode } from '@purple/common/utils/commonUtility'
import { dateTimeFormat } from '@purple/common/utils/dayjsUtility'
import {
  onError,
  profileThumbnailUrl,
  defaultNullImage
} from 'utils/imageUtility'
import {
  commonBoardError,
  isAcceptImageExtension,
  replaceNewLine
} from 'utils/boardUtilsForStore'

// Style
import {
  Icons,
  Thumbnail,
  CircleButton,
  ActionButton,
  ContainedButton
} from '@purple/design/react'
import {
  BottomButtonWrap,
  PopperContent
} from 'components/Board/Common/Core/BoardStyled'

// Hooks
import useToast from 'hooks/common/useToast'

// Components
import Popper from 'components/Common/Popper'
import { confirmPopup, boardReportPopup } from 'components/Popup'

const CommentContainer = styled.div`
  margin-bottom: 24px;
`

const WriteForm = styled.div`
  border-radius: 8px;
  background-color: ${({ theme }) => theme.color.textfield};
  & > textarea {
    display: flex;
    ${({ theme }) => theme.typography.body2};
    padding: 16px;
    width: 100%;
    height: auto;
    min-height: 40px;
    line-height: 20px;
    color: ${({ theme }) => theme.color.glyphs100};

    &::placeholder {
      color: ${({ theme }) => theme.color.glyphs400};
    }
  }
  & > figure {
    position: relative;
    display: inline-flex;

    margin: 0 0 16px 16px;
    & > p {
      border-radius: 4px;
      border: 1px solid ${({ theme }) => theme.color.bright200};
      overflow: hidden;
      & > img {
        display: block;
        max-width: 100%;
        max-height: 120px;
        object-fit: contain;
      }
    }
    & > button {
      position: absolute;
      top: -8px;
      right: -8px;
      ${({ theme }) => theme.elevation[4]};
    }
  }
  & > div:last-child {
    padding: 8px;
    display: flex;
    align-items: center;
    border-top: 1px solid ${({ theme }) => theme.color.bright300};
    & > span {
      display: flex;
      ${({ theme }) => theme.typography.body4};
      color: ${({ theme }) => theme.color.glyphs300};
      &:first-child {
        flex: 1;
        align-items: center;
        justify-content: space-between;
        margin-right: 16px;
      }
      &:last-child {
        & > button:first-child:not(:last-child) {
          margin-right: 8px;
        }
      }
      & em {
        color: ${({ theme }) => theme.color.glyphs200};
      }
    }
  }
`
const UploadForm = styled.form.attrs(({ idPostFix }) => ({
  name: `uploadForm-${idPostFix}`,
  id: `uploadForm-${idPostFix}`,
  method: 'post',
  encType: 'multipart/form-data'
}))`
  display: flex;
  width: 100%;
  height: 100%;
`
const InputLabel = styled.label.attrs(({ idPostFix }) => ({
  htmlFor: `uploadInput-${idPostFix}`
}))`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
const InputFile = styled.input.attrs(({ idPostFix }) => ({
  id: `uploadInput-${idPostFix}`,
  type: 'file',
  name: 'file',
  accept: `${['.jpg', '.gif', '.png', '.jpeg', '.mp4'].join(',')}`
}))`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
`
const ListContainer = styled.ul``
const Item = styled.li`
  margin-top: 16px;
`
const CommentWrap = styled.div`
  border-radius: 8px;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.color.bright200};
`
const CommentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`
const CommentUtils = styled.div`
  margin: 8px 0;
`
const Writer = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ isForm }) => (isForm ? '16px 0 0 16px' : '4px 0')};
  ${({ theme }) => theme.typography.body5};
  & > figure {
    display: flex;
    border: 1px solid ${({ theme }) => theme.color.bright100};
    border-radius: 50%;
  }
  & > span {
    display: flex;
    align-items: center;
    margin: 0 4px;
  }
  & > figure + span {
    color: ${({ theme }) => theme.color.glyphs250};
    margin: 0 4px;
    & > i {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      background-color: ${({ theme }) => theme.color.button100};
      margin-left: 4px;
      border-radius: 50%;
      & > svg {
        fill: ${({ theme }) => theme.color.white900a};
      }
    }
  }
  & > span + span:last-child {
    color: ${({ theme }) => theme.color.glyphs300};
  }
`
const Content = styled.div`
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: keep-all;
  ${({ theme }) => theme.typography.body4};
  color: ${({ theme }) => theme.color.glyphs200};
  & > p {
    & > img {
      display: block;
      max-width: 100%;
      max-height: 120px;
      border-radius: 4px;
      overflow: hidden;
      margin-top: 10px;
      object-fit: contain;
    }
  }
`

const MoreButtonWrap = styled.div``

const initialParams = {
  previous_comment_id: undefined,
  more_size: 20,
  more_direction: undefined,
  sort: undefined
}

const Comment = ({
  comment,
  boardAlias,
  serviceAlias,
  articleId,
  categoryAlias,
  query,
  userInfo
}) => {
  const { t } = useTranslation()
  const [modifyActiveId, setModifyActiveId] = useState(null)
  const [recommentActiveId, setRecommentActiveId] = useState(null)
  const [popper, setPopper] = useState({
    open: false,
    target: null,
    preventElements: []
  })
  const innerHTMLRef = useRef(null)
  const onErrorHandlersRef = useRef([])

  const [deleteCommnentTrigger] = useDeleteCommentMutation()
  const [getMyBanTrigger] = useLazyGetMyBanQuery()

  const isRecomment = !!comment?.id_number_hierarchy
  const isActiveRecomment = recommentActiveId === comment?.content?.id
  const isActiveModify = modifyActiveId === comment?.content?.id
  const { onInfoToast } = useToast()

  const isMe = useMemo(
    () => comment?.content_meta?.writer?.login_user?.uid === userInfo.id,
    [comment, userInfo]
  )

  // 핸들러 - HTML 컨버팅
  const convertHTML = useCallback((content) => {
    const htmlEncode = (str) =>
      String(str).replace(
        /[&<>"'`=\/]/g,
        (s) =>
          ({
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            "'": '&#39;',
            '`': '&#96;',
            '=': '&#61;',
            '/': '&#47;'
          }[s])
      )

    const decodeHtml = (html) =>
      html.replace(
        /&amp;|&lt;|&gt;|&quot;|&#39;|&#96;|&#61;|&#47;/g,
        (s) =>
          ({
            '&amp;': '&',
            '&lt;': '<',
            '&gt;': '>',
            '&quot;': '"',
            '&#39;': "'",
            '&#96;': '`',
            '&#61;': '=',
            '&#47;': '/'
          }[s])
      )

    const input = decodeHtml(content || '')

    const tagRegex = /<img[^>]*>|<br\s*\/?>/g
    const parts = input.split(tagRegex)
    const tags = input.match(tagRegex) || []

    const encodedParts = parts.map(htmlEncode)

    let combinedString = ''
    for (let i = 0; i < encodedParts.length; i++) {
      combinedString += encodedParts[i]
      if (tags[i]) {
        combinedString += tags[i]
      }
    }

    return combinedString
  }, [])

  // 핸들러 - 코멘트 삭제
  const handleDeleteComment = useCallback(
    (commentId) => {
      confirmPopup({
        type: 'confirm',
        msg: t('are you sure you want to delete?'),
        onRight: async () => {
          try {
            const res = await deleteCommnentTrigger({
              boardAlias,
              serviceAlias,
              articleId,
              commentId,
              categoryAlias,
              query,
              listArg: initialParams
            }).unwrap()
            onInfoToast({
              message: t('deleted'),
              position: 'bottom'
            })
          } catch (error) {
            console.error(error)
            commonBoardError(error).execute()
          }
        }
      })
    },
    [deleteCommnentTrigger, onInfoToast, t, boardAlias, serviceAlias, articleId]
  )

  // 핸들러 - 메뉴 버튼 클릭
  const handleMoreClick = useCallback(
    (e) => {
      const target = e.currentTarget
      if (!popper.open) {
        setPopper((prev) => ({
          ...prev,
          preventElements: [target],
          open: true,
          target
        }))
      } else {
        setPopper((prev) => ({
          open: false,
          target: null,
          preventElements: []
        }))
      }
    },
    [popper]
  )

  // 핸들러 - 신고하기
  const handleReportClick = useCallback(
    async (name = '', commentId) => {
      try {
        const res = await getMyBanTrigger({ serviceAlias }).unwrap()
        boardReportPopup(
          { name, boardAlias, commentId },
          {
            allowLocationChangeNotClose: false,
            stringKey: 'BoardReport'
          }
        )
      } catch (error) {
        console.error(error)
        commonBoardError(error).execute()
      }
    },
    [getMyBanTrigger, boardAlias, serviceAlias]
  )

  // 이펙트 - 이미지 에러 이벤트 바인딩
  useEffect(() => {
    if (innerHTMLRef.current) {
      const elements = innerHTMLRef.current.querySelectorAll('.comment-image')

      if (!!elements?.length) {
        elements.forEach((element, index, array) => {
          const imgErrorEvent = (event) => onError(event, defaultNullImage)
          element.addEventListener('error', imgErrorEvent)
          onErrorHandlersRef.current[index] = imgErrorEvent
        })
      }

      return () => {
        if (!!elements?.length) {
          elements.forEach((element, index) => {
            element.removeEventListener(
              'error',
              onErrorHandlersRef.current[index]
            )
          })
        }
      }
    }
  }, [comment?.content?.content])

  return (
    <Item>
      {isActiveModify ? (
        <CommentsWrite
          key={comment?.content?.id}
          comment={comment}
          boardAlias={boardAlias}
          serviceAlias={serviceAlias}
          articleId={articleId}
          userInfo={{
            id: comment?.content_meta?.writer?.login_user?.uid,
            name: comment?.content_meta?.writer?.login_user?.name
          }}
          successCallback={() => setModifyActiveId(null)}
          onModifyModeCancel={() => setModifyActiveId((prevId) => null)}
        />
      ) : (
        <CommentWrap>
          <CommentHeader>
            <Writer>
              <figure>
                <Thumbnail
                  alt=""
                  gameCode=""
                  size={100}
                  sources=""
                  src={profileThumbnailUrl(
                    comment.content_meta?.writer?.login_user?.uid || ''
                  )}
                  type="character"
                  onError={onError}
                />
              </figure>
              <span>
                {comment?.content_meta?.writer?.login_user.name}
                {comment?.content?.service_reserved?.reserved1
                  ?.store_has_package && (
                  <i title={t('users who purchased the game')}>
                    <Icons name="GameControllerFilled" width="16" height="16" />
                  </i>
                )}
              </span>
              <span>
                {dateTimeFormat(comment.content_meta?.timestamps?.posted_at)}
              </span>
            </Writer>
            {!comment.content_meta.writer.admin && (
              <MoreButtonWrap onClick={handleMoreClick}>
                <ActionButton type="basicSub" size="medium">
                  <Icons name="MoreVert" width="20" height="20" />
                </ActionButton>
                <Popper
                  open={popper.open}
                  target={popper.target}
                  preventElements={popper.preventElements}
                  portal={false}
                  modifiers={[
                    {
                      name: 'offset',
                      options: {
                        offset: [0, 4]
                      }
                    },
                    {
                      name: 'preventOverflow',
                      options: {
                        boundary: document.getElementById('content'),
                        padding: 8
                      }
                    },
                    {
                      name: 'observeReferenceModifier',
                      enabled: true,
                      phase: 'main',
                      effect: ({ state, instance }) => {
                        const RO_PROP = '__popperjsRO__'
                        const { reference } = state.elements

                        reference[RO_PROP] = new ResizeObserver((entries) => {
                          instance.update()
                        })
                        reference[RO_PROP].observe(reference)

                        return () => {
                          reference[RO_PROP].disconnect()
                          delete reference[RO_PROP]
                        }
                      }
                    }
                  ]}
                  strategy={'absolute'}
                  placement={'bottom-end'}
                  onClose={() =>
                    setPopper((prev) => ({
                      open: false,
                      target: null,
                      preventElements: []
                    }))
                  }
                >
                  {() => (
                    <PopperContent>
                      {isMe ? (
                        <>
                          <button
                            onClick={() =>
                              setModifyActiveId((prevId) =>
                                prevId === comment.content.id
                                  ? null
                                  : comment.content.id
                              )
                            }
                          >
                            {t('modify')}
                          </button>
                          <button
                            onClick={() =>
                              handleDeleteComment(comment.content.id)
                            }
                          >
                            {t('delete')}
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            onClick={() =>
                              handleReportClick(
                                comment?.content_meta?.writer?.login_user?.name,
                                comment.content.id
                              )
                            }
                          >
                            {t('report')}
                          </button>
                        </>
                      )}
                    </PopperContent>
                  )}
                </Popper>
              </MoreButtonWrap>
            )}
          </CommentHeader>
          <Content>
            <p
              ref={innerHTMLRef}
              dangerouslySetInnerHTML={{
                __html: convertHTML(comment?.content?.content || '')
              }}
            />
          </Content>
          {/** 차후 댓글의 답글 스펙 추가 시 주석 해제
        {!isRecomment && (
        <CommentUtils>
          <ActionButton
            type="basic"
            size="small"
            onClick={() =>
              setRecommentActiveId((prevId) =>
                prevId === comment.content.id ? null : comment.content.id
              )
            }
          >
            <Icons
              name={isActiveRecomment ? 'PencilFilled' : 'Pencil'}
              width="24"
              height="24"
            />
          </ActionButton>
        </CommentUtils>
      )}
      {!isRecomment && isActiveRecomment && (
        <div>
          <CommentsWrite
            key={comment?.content?.id}
            boardAlias={boardAlias}
            serviceAlias={serviceAlias}
            comment={comment}
            articleId={articleId}
            isRecommentMode={true}
            npUserId={npUserId}
            successCallback={() => setRecommentActiveId(null)}
          />
        </div>
      )}
      */}
        </CommentWrap>
      )}
    </Item>
  )
}

const CommentsList = ({
  boardAlias,
  serviceAlias,
  articleId,
  categoryAlias,
  query,
  userInfo
}) => {
  const { t } = useTranslation()

  const {
    commnets = [],
    hasMore,
    totalCount,
    isLoading
  } = useGetCommentListQuery(
    {
      boardAlias,
      serviceAlias,
      articleId,
      params: initialParams
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ data, ...rest }) => ({
        commnets: data?.content_list,
        hasMore: data?.has_more,
        totalCount: data?.total_count,
        ...rest
      })
    }
  )
  const [getCommentListTrigger] = useLazyGetCommentListQuery()

  const lastParentsComment = useMemo(
    () =>
      commnets[commnets.length - 1]?.id_number_hierarchy === null
        ? commnets[commnets.length - 1]
        : commnets.findLast((comment) => comment.id_number_hierarchy === null),
    [commnets]
  )

  return (
    <ListContainer>
      {commnets.map((comment) => (
        <Comment
          key={comment.content.id}
          comment={comment}
          boardAlias={boardAlias}
          serviceAlias={serviceAlias}
          articleId={articleId}
          categoryAlias={categoryAlias}
          query={query}
          userInfo={userInfo}
        />
      ))}
      {hasMore && (
        <BottomButtonWrap size="narrow">
          <ContainedButton
            size="medium"
            type="outlineSecondary"
            onClick={() => {
              getCommentListTrigger({
                boardAlias,
                serviceAlias,
                articleId,
                params: {
                  ...initialParams,
                  previous_comment_id:
                    lastParentsComment?.content_meta?.id || undefined
                }
              })
            }}
          >
            <span>{t('To see more details')}</span>
          </ContainedButton>
        </BottomButtonWrap>
      )}
    </ListContainer>
  )
}

const CommentsWrite = ({
  userInfo,
  isLogin,
  comment,
  boardAlias,
  serviceAlias,
  articleId,
  categoryAlias,
  query,
  isRecommentMode = false,
  successCallback = null,
  onModifyModeCancel = null
}) => {
  const { imgString = '', textString = '' } = useMemo(() => {
    const input = htmlDecode(comment?.content?.content || '')
    const imgRegex = /<img[^>]*>/g
    const tags = input.match(imgRegex) || []
    const textString = input.split(imgRegex).join('')
    return { imgString: tags[0], textString }
  }, [comment?.content?.content])

  const { t } = useTranslation()
  const [text, setText] = useState(textString)
  const [htmlStringImg, setHtmlStringImg] = useState(imgString)
  const textareaRef = useRef(null)
  const formRef = useRef(null)
  const innerHTMLRef = useRef(null)
  const onErrorHandlersRef = useRef([])
  const { onInfoToast } = useToast()

  const [getTokenTrigger] = useLazyGetFileTokenQuery()
  const [postCommnentTrigger, { isLoading: isPostLoading }] =
    usePostCommentMutation()
  const [putCommnentTrigger, { isLoading: isPutLoading }] =
    usePutCommentMutation()
  const [postUploadMediaTrigger] = usePostUploadMediaMutation()
  const [getMediaFileTrigger] = useLazyGetMediaFileQuery()
  const [getCommentTrigger] = useLazyGetCommentQuery()
  const [getMyBanTrigger] = useLazyGetMyBanQuery()

  const isButtonDisabled = useMemo(
    () => !text?.length && !htmlStringImg?.length,
    [text, htmlStringImg]
  )

  const isModyfyMode = useMemo(() => !!comment, [comment])
  const isUtilsShow = useMemo(
    () => isLogin || isModyfyMode,
    [isLogin, isModyfyMode]
  )

  // 핸들러 - 코멘트 등록
  const handleWriteComment = useCallback(
    (e) => {
      exquisiteClick({
        e,
        onClick: async () => {
          try {
            if (isButtonDisabled || isPostLoading) return

            const omitText = replaceNewLine({
              replaceString: '<br/>',
              replaceCount: 4,
              origin: text
            })

            const params = {
              ...(isRecommentMode && {
                parent_comment_id: comment?.content?.id || ''
              }),
              comment: {
                contents: !!htmlStringImg.length
                  ? `${omitText}${htmlStringImg}`
                  : omitText
              }
            }

            const { comment_id } = await postCommnentTrigger({
              boardAlias,
              serviceAlias,
              articleId,
              params,
              categoryAlias,
              query
            }).unwrap()

            const res = await getCommentTrigger({
              boardAlias,
              serviceAlias,
              articleId,
              params: initialParams,
              commentId: comment_id
            }).unwrap()

            !!text && setText('')
            !!htmlStringImg && setHtmlStringImg('')
            !!successCallback && successCallback()

            return res
          } catch (error) {
            console.error(error)
            commonBoardError(error).execute()
          }
        }
      })
    },
    [
      getCommentTrigger,
      postCommnentTrigger,
      successCallback,
      isButtonDisabled,
      isPostLoading,
      text,
      htmlStringImg,
      isRecommentMode,
      comment,
      boardAlias,
      serviceAlias,
      articleId,
      categoryAlias,
      query
    ]
  )

  // 핸들러 - 코멘트 수정
  const handleModifyComment = useCallback(
    (e) => {
      exquisiteClick({
        e,
        onClick: async () => {
          try {
            if (isButtonDisabled || isPutLoading) return

            const omitText = replaceNewLine({
              replaceString: '<br/>',
              replaceCount: 4,
              origin: text
            })

            const params = {
              comment: {
                contents: !!htmlStringImg.length
                  ? `${omitText}${htmlStringImg}`
                  : omitText
              }
            }

            const { result } = await putCommnentTrigger({
              boardAlias,
              serviceAlias,
              articleId,
              commentId: comment?.content?.id || '',
              params,
              listArg: initialParams
            }).unwrap()

            !!successCallback && successCallback()

            return result
          } catch (error) {
            console.error(error)
            commonBoardError(error).execute()
          }
        }
      })
    },
    [
      putCommnentTrigger,
      successCallback,
      isButtonDisabled,
      isPutLoading,
      text,
      htmlStringImg,
      comment,
      boardAlias,
      serviceAlias,
      articleId
    ]
  )

  // 핸들러 - 텍스트 입력폼 포커스
  const handleFocusForm = useCallback(
    async (e) => {
      try {
        const res = await getMyBanTrigger({ serviceAlias }).unwrap()
        return res
      } catch (error) {
        console.error(error)
        commonBoardError(error).execute()
      }
    },
    [getMyBanTrigger, serviceAlias]
  )

  // 핸들러 - 이미지 파일 업로드
  const handleChangeUploadFile = useCallback(
    async (e) => {
      const res = await handleFocusForm()
      if (!res) return

      try {
        const file = e.target.files[0] ?? false

        if (file) {
          const mb10 = 1024 * 1024 * 10
          if (file.size > mb10) {
            confirmPopup({
              type: 'alert',
              msg: t('please register an image within mb.', { maxMB: 10 })
            })
            return
          }

          if (!isAcceptImageExtension(file.type?.split('/')?.[1] || '')) {
            confirmPopup({
              type: 'alert',
              msg: t(
                'attachment is not possible with an extension that is not supported'
              )
            })
            return
          }

          const token = await getTokenTrigger({
            boardAlias,
            serviceAlias
          }).unwrap()

          const formData = new FormData()
          formData.append('attachment', file)

          const uploadResuilt = await postUploadMediaTrigger({
            url: `${token.uploadUrl}?json=true`,
            method: 'POST',
            body: formData
          }).unwrap()

          const getMediaResuilt = await getMediaFileTrigger({
            url: token.fileInfoUrl,
            headers: {
              'Content-Type': 'application/json'
            }
          }).unwrap()

          setHtmlStringImg(
            getMediaResuilt[0]?.downloadurl
              ? `<img src="${getMediaResuilt[0]?.downloadurl}" class="comment-image" alt="" />`
              : ''
          )
        }
      } catch (error) {
        console.log('error', error)
        confirmPopup({
          type: 'alert',
          msg: error?.data?.message || t('invalid image file')
        })
      } finally {
        formRef?.current?.reset()
      }
    },
    [
      t,
      getTokenTrigger,
      getMediaFileTrigger,
      postUploadMediaTrigger,
      handleFocusForm,
      boardAlias,
      serviceAlias
    ]
  )

  // 핸들러 - 텍스트 입력창 변경
  const handleTextOnChange = useCallback(
    (e) => {
      const regex = /<img.*?>/g
      if (regex.test(htmlDecode(e.target.value))) {
        onInfoToast({
          message: t('please upload a file', { size: '10mb' }),
          position: 'bottom'
        })
        return
      }

      setText((prevValue) =>
        e.target.value.length <= 1000
          ? e.target.value?.replace(/\n/g, '<br/>')
          : prevValue
      )
    },
    [onInfoToast, t]
  )

  // 이펙트 - textarea 높이값 변경 이펙트
  useEffect(() => {
    const textarea = textareaRef.current
    textarea.style.height = 'auto'
    textarea.style.height = `${textarea.scrollHeight}px`
  }, [text, textareaRef])

  // 이펙트 - 이미지 에러 이벤트 바인딩
  useEffect(() => {
    if (innerHTMLRef.current) {
      const elements = innerHTMLRef.current.querySelectorAll('.comment-image')

      if (!!elements?.length) {
        elements.forEach((element, index, array) => {
          const imgErrorEvent = (event) => onError(event, defaultNullImage)
          element.addEventListener('error', imgErrorEvent)
          onErrorHandlersRef.current[index] = imgErrorEvent
        })
      }

      return () => {
        if (!!elements?.length) {
          elements.forEach((element, index) => {
            element.removeEventListener(
              'error',
              onErrorHandlersRef.current[index]
            )
          })
        }
      }
    }
  }, [htmlStringImg])

  return (
    <WriteForm>
      {isUtilsShow && (
        <Writer isForm>
          <figure>
            <Thumbnail
              alt=""
              gameCode=""
              size={100}
              sources=""
              src={profileThumbnailUrl(userInfo?.id || '')}
              type="character"
              onError={onError}
            />
          </figure>
          <span>{userInfo?.name || ''}</span>
          {isModyfyMode && (
            <span>
              {dateTimeFormat(comment?.content_meta?.timestamps?.posted_at)}
            </span>
          )}
        </Writer>
      )}
      <textarea
        ref={textareaRef}
        value={text?.replace(/<br\s*\/?>/gi, '\n')}
        onChange={handleTextOnChange}
        onFocus={handleFocusForm}
        placeholder={t('please leave a comment.')}
        maxLength="1000"
      />
      {!!htmlStringImg.length && (
        <figure>
          <p
            ref={innerHTMLRef}
            dangerouslySetInnerHTML={{
              __html: htmlDecode(htmlStringImg || '')
            }}
          />
          <CircleButton
            type="basic"
            size="small"
            onClick={() => setHtmlStringImg('')}
          >
            <Icons name="Close" />
          </CircleButton>
        </figure>
      )}
      {isUtilsShow && (
        <div>
          <span>
            <ActionButton type="basicSub" size="medium">
              <UploadForm ref={formRef} idPostFix={comment?.content?.id || '0'}>
                <InputLabel idPostFix={comment?.content?.id || '0'}>
                  <Icons name="Album" width="20" height="20" />
                </InputLabel>
                <InputFile
                  idPostFix={comment?.content?.id || '0'}
                  onChange={handleChangeUploadFile}
                />
              </UploadForm>
            </ActionButton>
            <span>
              <Trans
                i18nKey="character count status"
                count={text?.replace(/<br\s*\/?>/gi, ' ')?.length || 0}
                total={1000 || 0}
              >
                <em>{{ count: text?.length || 0 }}</em>/
                <i>{{ total: 1000 || 0 }}</i>자
              </Trans>
            </span>
          </span>
          <span>
            {isModyfyMode && (
              <ContainedButton
                type="tertiary"
                size="medium"
                onClick={onModifyModeCancel}
              >
                <span>{t('cancel')}</span>
              </ContainedButton>
            )}
            <ContainedButton
              type="primary"
              size="medium"
              onClick={isModyfyMode ? handleModifyComment : handleWriteComment}
              disabled={isButtonDisabled}
            >
              <span>{isModyfyMode ? t('modify') : t('register')}</span>
            </ContainedButton>
          </span>
        </div>
      )}
    </WriteForm>
  )
}

const Comments = (props) => {
  const { npUserId, npUserName } = useSelector(
    (state) => ({
      npUserId: state.storeSlice.npUserId,
      npUserName: state.storeSlice.npUserName
    }),
    shallowEqual
  )

  return (
    <CommentContainer>
      <CommentsWrite
        {...props}
        userInfo={{ id: npUserId, name: npUserName }}
        isLogin={!!npUserId}
      />
      <CommentsList {...props} userInfo={{ id: npUserId, name: npUserName }} />
    </CommentContainer>
  )
}

export default Comments
