import { useRef, useEffect, useState } from 'react'
import { AlphaBasicEditor } from 'alpha-editor'
import 'alpha-editor/dist/css/editor.css'
import { shallowEqual, useSelector } from 'react-redux'
import styled from 'styled-components'

const AlphaEditorContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.color.glyphs200};

  word-break: break-all;
  & ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  .alpha-editor-container {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .alpha-toolbar {
    background: ${({ theme }) => theme.color.textfield};
    border-top: 0px;
    flex-shrink: 0;
    border-radius: 4px 4px 0 0;
    border-color: ${({ theme }) => theme.color.bright200};
  }

  .ProseMirror.alpha-editor {
    flex: 1 1 0;
    overflow-y: scroll;
    color: ${({ theme }) => theme.color.glyphs200};
    ${({ theme }) => theme.typography.reading3};
    background: ${({ theme }) => theme.color.textfield};
    border-radius: 0 0 4px 4px;
  }

  .fe-btn-layer.is-fill {
    height: 40px;
    ${({ theme }) => theme.typography.Button2};
    color: ${({ theme }) => theme.color.glyphs600};
    background: ${({ theme }) => theme.color.primary};
  }
`

// TODO: 폴란드어 추가해야함. (에디터 json 파일명 보고 매핑 필요)
const EDITOR_LOCALE_TYPE = {
  ar: 'ar',
  en: 'en',
  id: 'id',
  ja: 'ja',
  ko: 'ko',
  ru: 'ru',
  vi: 'vi',
  th: 'th',
  pl: 'pl',
  zh: 'zh_Hant'
}

const AlphaEditor = ({
  editor = { current: null },
  id,
  options,
  handleInit = () => {}
}) => {
  const { storeTheme } = useSelector((state) => {
    return {
      storeTheme: state.storeSlice.storeTheme
    }
  }, shallowEqual)

  useEffect(() => {
    new AlphaBasicEditor(
      `#${id}`,
      'free',
      {
        ...options,
        locale: EDITOR_LOCALE_TYPE[options.locale || 'en']
      },
      '',
      (instance) => {
        editor.current?.destroy()
        editor.current = instance
        handleInit()
        console.log('editor created')
      }
    )

    return () => {
      if (editor.current) {
        editor.current.destroy()
        editor.current = null
      }
    }
  }, [])

  useEffect(() => {
    if (storeTheme === 'dark') {
      document.documentElement.classList.add('dark-mode')
    } else {
      document.documentElement.classList.remove('dark-mode')
    }
  }, [storeTheme])

  return (
    <>
      <AlphaEditorContainer>
        <div id={id}></div>
      </AlphaEditorContainer>
    </>
  )
}

export default AlphaEditor
