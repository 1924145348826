import React, { useEffect, useRef, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Icons, ActionButton } from '@purple/design/react'
// Utils
import { copyText } from 'utils/popupUtility'

const Container = styled.div`
  width: 320px;
  background: ${({ theme }) => theme.color.bg300};
  border-radius: 8px;
  text-align: center;
  margin: auto;
  overflow: hidden;
  ${({ theme }) => theme.elevation['24']};

  &:focus {
    outline: none;
  }
`

const Header = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 4px 4px 0 4px;
  padding: 8px 0;
`
const ContentWrap = styled.div`
  min-height: 120px;
  margin: 0 16px;
  color: ${({ theme }) => theme.color.glyphs200};
`
const ShareInfo = styled.div`
  margin-bottom: 6px;
  ${({ theme }) => theme.typography.body2};
`
const ShareIconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const ShareItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  border: 1px solid ${({ theme }) => theme.color.bright200};
  border-radius: 50%;
  margin: 8px 10px;
  cursor: pointer;
  user-select: none;
`

const ArticleSharePopup = (props) => {
  const { layerClose, arr = [], url = '' } = props

  const containerRef = useRef()
  const { t } = useTranslation()

  const onClickClose = useCallback(() => {
    layerClose()
  }, [layerClose])

  const onClickCopyUrl = useCallback(
    async (shareUrl = window.location.href) => {
      copyText(shareUrl)
      onClickClose()
    },
    [onClickClose]
  )

  const onClickFacebook = useCallback((url) => {
    if (!url) {
      console.error('URL Not Find ')
    } else {
      const loc = `https://www.facebook.com/sharer.php?u=${encodeURIComponent(
        url
      )}`
      onClickClose()
      window.open(loc)
    }
  }, [])

  const onClickX = useCallback((url) => {
    if (!url) {
      console.error('(URL or msg) Not Find ')
    } else {
      const loc = `https://x.com/intent/post?url=${encodeURIComponent(url)}`
      onClickClose()
      window.open(loc)
    }
  }, [])

  const iconSetData = useMemo(() => {
    let result = {}
    if (arr.length > 0) {
      arr.forEach((item) => {
        result[item] = true
      })
      return result
    }
    return {
      facebook: true,
      x: true,
      url: true
    }
  }, [arr])

  useEffect(() => {
    containerRef.current && containerRef.current.focus()
  }, [])

  return (
    <Container tabIndex="0" ref={containerRef} width="888px">
      <Header>
        <ActionButton type="basic" size="medium" onClick={onClickClose}>
          <Icons name="Close" />
        </ActionButton>
      </Header>
      <ContentWrap>
        <ShareInfo>{t('Share the article on social media.')}</ShareInfo>
        <ShareIconWrap>
          {iconSetData.facebook && (
            <ShareItem
              onClick={() => {
                onClickFacebook(url)
              }}
            >
              <Icons name="LogoFacebook" width={40} height={40} />
            </ShareItem>
          )}
          {iconSetData.x && (
            <ShareItem
              onClick={() => {
                onClickX(url)
              }}
            >
              <Icons name="LogoX" width={40} height={40} />
            </ShareItem>
          )}

          {iconSetData.url && (
            <ShareItem
              onClick={() => {
                onClickCopyUrl(url)
              }}
            >
              URL
            </ShareItem>
          )}
        </ShareIconWrap>
      </ContentWrap>
    </Container>
  )
}

export default ArticleSharePopup
