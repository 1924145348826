import { getLanguage } from '@purple/common/utils/locationUtility'
import { SUPPORT_STORE_BOARD_TAIL } from '@purple/common/constants/locationTypes'
import history from 'config/historyConfig'
import {
  BOARD_API_ERROR_TYPE,
  BOARD_ERROR_KEY,
  BOARD_TYPE
} from 'constants/boardTypes'
import { confirmPopup } from 'components/Popup'
import { commonSessionError } from './appUtility'
import i18n from 'language/i18n'

export const getBoardTail = (alias) => {
  let lang = getLanguage().toUpperCase()

  if (SUPPORT_STORE_BOARD_TAIL[lang]) {
    if (SUPPORT_STORE_BOARD_TAIL[lang] === SUPPORT_STORE_BOARD_TAIL.KO) {
      return alias
    }
    return `${alias}_${SUPPORT_STORE_BOARD_TAIL[lang]}`
  } else {
    return `${alias}_${SUPPORT_STORE_BOARD_TAIL.EN}`
  }
}

export const removePrevBoardForm = () => {
  sessionStorage.removeItem('board-form')
  const loc = new URL(window.location.href)
  loc.searchParams.delete('isExpiredSession')
  history.replace(loc.pathname + loc.search)
}

export const addPrevBoardForm = ({ title, contents }) => {
  sessionStorage.setItem(
    'board-form',
    JSON.stringify({
      title,
      contents
    })
  )
  const loc = new URL(window.location.href)
  loc.searchParams.set('isExpiredSession', true)
  history.replace(loc.pathname + loc.search)
}

export const isAcceptImageExtension = (
  extension,
  validList = ['gif', 'png', 'jpg', 'jpeg']
) => {
  // array to regex
  const validExtension = validList.join('|')

  return new RegExp(`(${validExtension})$`, 'i').test(extension)
}

/**
 * @typedef {Object} ConfirmProps
 * @property {string} msg
 * @property {string} type
 * @property {boolean} showCloseIcon
 * @property {function} layerClose
 * @property {function} onLeft
 * @property {function} onRight
 * @property {string} leftButtonLabel
 * @property {string} rightButtonLabel
 */

/**
 * @typedef {Object} Error
 * @property {number} status
 * @property {string} errorType
 * @property {string} message
 */

/**
 *
 * @param {Error} param
 * @returns
 */
const defaultErrorCommand = ({ message = '', status, errorType }) => {
  switch (errorType) {
    // 인증 에러 로그인 페이지 이동
    case BOARD_ERROR_KEY.NOT_FOUND_AUTHENTICATION:
    case BOARD_ERROR_KEY.FAILED_AUTHENTICATION:
      commonSessionError()
      break

    // 신고 제한
    case BOARD_ERROR_KEY.LIMIT_REPORT_COUNT:
      confirmPopup({
        msg: i18n.t('exceeded the number of declaration')
      })
      break
    case BOARD_ERROR_KEY.BANNED_ACCOUNT:
      confirmPopup({
        msg: i18n.t('this is a sanctioned account.')
      })
      break
    case BOARD_ERROR_KEY.INVALID_COMMUNITY_AUTH:
    case BOARD_ERROR_KEY.NO_PERMISSION:
    case BOARD_ERROR_KEY.NOT_FOUND_BOARD_ALIAS:
    case BOARD_ERROR_KEY.NOT_FOUND_CATEGORY:
    case BOARD_ERROR_KEY.NOT_FOUND_ARTICLE:
    case BOARD_ERROR_KEY.NOT_FOUND_COMMENT:
    case BOARD_ERROR_KEY.NOT_FOUND_SERVICE_ALIAS:
    case BOARD_ERROR_KEY.LIMIT_WRITE_ARTICLE_BY_RMS:
    case BOARD_ERROR_KEY.NOT_FOUND_REQUEST:
    case BOARD_ERROR_KEY.INVALID_REQUEST_BODY:
    case BOARD_ERROR_KEY.INVALID_REACTION:
    case BOARD_ERROR_KEY.NOT_FOUND_SCRAP:
    case BOARD_ERROR_KEY.NOT_FOUND_COMMUNITY_PROVIDER:
    case BOARD_ERROR_KEY.INVALID_PARAMETER:
    case BOARD_ERROR_KEY.NOT_DEFINED_ERROR:
      confirmPopup({
        msg: i18n.t(
          'an error has occurred. Please try again in a few minutes.'
        ),
        type: 'alert'
      })
      break
    default:
      if (status === 401) {
        commonSessionError()
        return
      }
      confirmPopup({
        msg: i18n.t(
          'an error has occurred. Please try again in a few minutes.'
        ),
        type: 'alert'
      })
  }
}

const findErrorType = (module) => {
  let errorType
  for (const errorKey in BOARD_API_ERROR_TYPE) {
    if (BOARD_API_ERROR_TYPE[errorKey].includes(module)) {
      errorType = errorKey
      break
    }
  }

  return errorType ?? ''
}

export const commonBoardError = (error) => {
  const { status } = error
  const { module, text } = error?.data || {}

  let actions = []
  let popups = []

  /**
   * @param {number[]} errorTypes
   * @param {function} callback
   * @returns
   */
  function action(errorTypes, callback) {
    const flatList = errorTypes.flat(Infinity)
    actions.push({ errorTypes: flatList, callback })
    return this
  }

  /**
   *
   * @param {number[]} errorTypes
   * @param {ConfirmProps} options
   * @returns
   */
  function popup(errorTypes, options) {
    const flatList = errorTypes.flat(Infinity)
    popups.push({ errorTypes: flatList, options })
    return this
  }

  function execute() {
    for (let action of actions) {
      if (action.errorTypes.includes(module)) {
        action.callback()
        return
      }
    }

    for (let popup of popups) {
      if (popup.errorTypes.includes(module)) {
        confirmPopup(popup.options)
        return
      }
    }

    defaultErrorCommand({
      errorType: findErrorType(module),
      message: text,
      status
    })
  }

  return {
    action,
    popup,
    execute
  }
}

/**
 * boardType 구성해서 가져오기
 * @param {string} boardAlias
 * @param {string} boardType
 */
export const getBoardType = ({ boardAlias, boardType }) => {
  const isLuancherType = [BOARD_TYPE.LNEWS, BOARD_TYPE.LCOMMUNITY].includes(
    boardType
  )

  switch (boardAlias) {
    case 'notice':
    case 'notice_en':
    case 'notice_ja':
    case 'notice_zh_tw':
    case 'notice_ru':
      return BOARD_TYPE.NOTICE
    case 'faq':
    case 'faq_en':
    case 'faq_ja':
    case 'faq_zh_tw':
    case 'faq_ru':
      return BOARD_TYPE.FAQ
    case 'news':
      return isLuancherType ? BOARD_TYPE.LNEWS : BOARD_TYPE.NEWS
    case 'game':
      return isLuancherType ? BOARD_TYPE.LCOMMUNITY : BOARD_TYPE.COMMUNITY
    default:
      return undefined
  }
}

/**
 * gameCode 구성해서 가져오기
 * @param {string} boardAlias
 * @param {string} categoryAlias
 */
export const getGameCode = ({ boardAlias, categoryAlias = '' }) => {
  const [gameCode, category] = categoryAlias.split('_')

  if (['news', 'game'].includes(boardAlias)) {
    return gameCode
  }

  return undefined
}

export const replaceNewLine = ({
  more = 5,
  replaceCount = 3,
  replaceString,
  origin
}) => {
  const regex = new RegExp(`(${replaceString}){${more},}`, 'gm')

  return origin.replaceAll(regex, replaceString.repeat(replaceCount))
}
