import React, { useState, useMemo, useCallback } from 'react'
import styled from 'styled-components'
import cn from 'classnames'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'
import { logd } from '@purple/common/logd'

// GTM
import GoogleAnalytics from 'config/analyticsConfig'

// Routes
import { moveBoard } from 'routes/board/locations'

// Style
import { Icons, ContainedButton } from '@purple/design/react'
import { media } from 'assets/styles/media'

// Components
import Search from 'components/Board/Common/Core/Search'

// RTK
import { useGetSubCategoriesQuery } from 'store/services/query/boardQuery'
import { shallowEqual, useSelector } from 'react-redux'

// Hooks
import useSearchParams from 'hooks/common/useSearchParams'

// Utils
import { getLocale } from '@purple/common/utils/commonUtility'
import { commonSessionError } from 'utils/appUtility'

const Container = styled.div`
  display: flex;
  width: ${({ width }) => width};
  ${({ align }) => (align === 'right' ? 'margin-left: auto;' : '')}
`
const SearchWrap = styled.div`
  flex: 1;
`
const ButtonWrap = styled.div`
  margin-left: 8px;

  .board-write-textButton {
    ${media.phone`
      display: none
    `};
  }
  .board-write-iconButton {
    display: none;
    ${media.phone`
        display: inline-flex;
     `};
  }
`

function SearchForm({
  boardAlias,
  serviceAlias = 'purple',
  boardType,
  gameCode,
  width = '100%',
  align = '',
  categoryAlias = `${boardAlias}_${gameCode}`,
  options = {}
}) {
  const option = {
    postDate: true,
    category: true,
    writer: true,
    profile: false,
    newBadge: true,
    hasMore: true,
    wideView: false,
    ...(options || {})
  }

  const { t } = useTranslation()
  const history = useHistory()
  const searchParams = useSearchParams()

  const { npUserId } = useSelector(
    (state) => ({
      npUserId: state.storeSlice.npUserId
    }),
    shallowEqual
  )

  // 쿼리 - 카테고리 조회
  const {
    categories = [],
    isLoading,
    ...rest
  } = useGetSubCategoriesQuery(
    {
      boardAlias,
      serviceAlias,
      categoryAlias
    },
    {
      selectFromResult: ({ data, ...rest }) => ({
        categories: data,
        ...rest
      })
    }
  )

  // Data - 카테고리 데이터
  const categoryList = useMemo(
    () =>
      categories.reduce(
        (acc, category) => {
          acc.push({
            category_id: category.board?.id,
            category_alias: category.board?.board_alias,
            category_name:
              category.board?.board_name_i18_n?.[getLocale()] ||
              category.board?.board_name_i18_n?.['en-US']
          })

          return acc
        },
        [
          {
            category_id: 0,
            category_alias: categoryAlias,
            category_name: t('all')
          }
        ]
      ),
    [t, categories, categoryAlias]
  )

  // 핸들러 - 글쓰기 이동
  const handleClickWrite = useCallback(() => {
    if (!npUserId) {
      commonSessionError()
      return
    }
    history.push({
      pathname: moveBoard(
        {
          gameCode,
          boardAlias
        },
        `${boardType}_WRITE`
      ),
      search: searchParams.get('isGameBoard') ? '?isGameBoard=true' : ''
    })
    GoogleAnalytics.sendGAEvent({
      event_category: '클릭',
      event_action: '게임 메인 커뮤니티 글쓰기 - 버튼 클릭',
      event_label: '버튼 - 글쓰기',
      event_button_code: 'pbw_games_main_community_write',
      event_game_code: gameCode
    })
    logd({
      type: 'pbw_games_main',
      name: 'pbw_games_main_community_write',
      value: gameCode,
      data: { gameCode }
    })
  }, [history, boardType, boardAlias, gameCode, npUserId, searchParams])

  return (
    <Container width={width} align={align}>
      <SearchWrap>
        <Search
          boardType={boardType}
          categoryList={categoryList}
          gameCode={gameCode}
          boardAlias={boardAlias}
          categoryAlias={categoryAlias}
          placeholder={t('search')}
        />
      </SearchWrap>
      {option.writable && (
        <ButtonWrap>
          <ContainedButton
            type="tertiary"
            size="medium"
            width="100px"
            className="board-write-textButton"
            onClick={handleClickWrite}
          >
            <span>{t('writing')}</span>
          </ContainedButton>
          <ContainedButton
            type="tertiary"
            size="medium"
            className="board-write-iconButton"
            onClick={handleClickWrite}
          >
            <Icons name="Pencil" width="20" height="20" />
          </ContainedButton>
        </ButtonWrap>
      )}
    </Container>
  )
}

export default SearchForm
