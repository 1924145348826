import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'
import { Config as config } from '@purple/common/utils/globalUtility'
import { CountryCode } from '@purple/common/utils/locationUtility'
import { configPreGameCodeList } from '@purple/common/utils/gameUtility'
import { matchPath } from 'react-router-dom'
import i18n from 'language/i18n'

function base64Encode(str) {
  return window.btoa(unescape(encodeURIComponent(str)))
}

const getGameCodeInPathname = () => {
  const result = matchPath(window.location.pathname, {
    path: ['/publish/:gameCode', '/game/:gameCode'],
    exact: false,
    strict: false
  })

  if (result) {
    return result?.params?.gameCode
  } else {
    return false
  }
}

const baseQuery = fetchBaseQuery({
  baseUrl: config['community.api.url'] || 'https://community-api.plaync.com/',
  prepareHeaders: (headers, { getState }) => {
    headers.set('Lime-Preservice-Game-Codes', configPreGameCodeList())
    headers.set('X-CountryCode', CountryCode())
    headers.set(
      'CommunityAuth',
      base64Encode(
        JSON.stringify({
          store_game_code: getGameCodeInPathname() || '',
          language: i18n.language?.split('-')[0] || 'ko',
          country: CountryCode() || 'KR'
        })
      )
    )
    return headers
  },
  credentials: 'include'
})

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 })

export const communityApi = createApi({
  reducerPath: 'communityApi',
  baseQuery: baseQueryWithRetry,
  endpoints: () => ({})
})
