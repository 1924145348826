import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'
import { Config as config } from '@purple/common/utils/globalUtility'

import {
  getCountryCode,
  getLocaleKey
} from '@purple/common/utils/locationUtility'
import {
  setRamdomTid,
  getLocale,
  getDeviceId
} from '@purple/common/utils/commonUtility'
import { LANG_TYPE } from '@purple/common/constants/locationTypes'

const baseQuery = fetchBaseQuery({
  baseUrl: config['purple.api.url'] || '',
  prepareHeaders: (headers, { getState }) => {
    headers.set('Content-Type', 'application/json')
    headers.set('X-CountryCode', getCountryCode())
    headers.set('User-Agent', window.navigator.userAgent)
    headers.set('Accept-Language', LANG_TYPE[getLocaleKey()])
    headers.set('Lime-API-Version', config.apiVersion || 140)
    headers.set('Lime-Device-Id', getDeviceId())
    headers.set('Lime-Locale', getLocale())
    headers.set('Lime-Trace-Id', setRamdomTid())
    // headers.set('Authorization', getToken(TOKENKEY_TYPE.LIME_JWT))
    return headers
  },
  credentials: 'include',
  timeout: config.apiTimeout || 5000
})

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 })

export const purpleApi = createApi({
  reducerPath: 'purpleApi',
  baseQuery: baseQueryWithRetry,
  endpoints: () => ({})
})
