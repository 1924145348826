import { GAME_CODE, GAME_CODE_UPPER } from '@purple/common/constants/gameTypes'
import i18n from 'i18next'
import { COUNTRY_TYPE, LANG_TYPE } from '@purple/common/constants/locationTypes'

//게임별 active code (런처팀과 협의필요)
//콘티 알리아스 기준
export const ACTIVEMENU = {
  L2M: GAME_CODE_UPPER.L2M, // TJNR 모두 L2M으로 활성화
  L2MRU: GAME_CODE_UPPER.L2M,
  L2MNU: GAME_CODE_UPPER.L2M,
  L2MNCT: GAME_CODE_UPPER.L2M,
  L2MNCJ: GAME_CODE_UPPER.L2M,
  H3: GAME_CODE_UPPER.H3,
  TRICKSTERM: GAME_CODE_UPPER.TRICKSTERM,
  BNS2: GAME_CODE_UPPER.BNS2,
  LINW: GAME_CODE_UPPER.LINW,
  LMS: GAME_CODE_UPPER.LMS,
  LMJ: GAME_CODE_UPPER.LMS,
  TL: GAME_CODE_UPPER.TL,
  LIN: GAME_CODE_UPPER.LIN,
  LIN2: GAME_CODE_UPPER.LIN2,
  BNS: GAME_CODE_UPPER.BNS,
  AION: GAME_CODE_UPPER.AION,
  LOVEBEAT: GAME_CODE_UPPER.LOVEBEAT,
  HOYEON: GAME_CODE_UPPER.HOYEON,
  NLW: GAME_CODE_UPPER.NLW,
  BATTLECRUSH: GAME_CODE_UPPER.BATTLECRUSH,
  LIN2NCT: GAME_CODE_UPPER.LIN2,
  AIONNCT: GAME_CODE_UPPER.AION,
  BNSNCT: GAME_CODE_UPPER.BNS,
  LINNCJ: GAME_CODE_UPPER.LIN,
  LIN2NCJ: GAME_CODE_UPPER.LIN2,
  AIONNCJ: GAME_CODE_UPPER.AION,
  BNSNCJ: GAME_CODE_UPPER.BNS,
  JANRYUMONJP: GAME_CODE_UPPER.JANRYUMONJP,
  BNSNCW: GAME_CODE_UPPER.BNS,
  AIONNCW: GAME_CODE_UPPER.AION,
  LIN2NCW: GAME_CODE_UPPER.LIN2,
  LMA: GAME_CODE_UPPER.LMA
}

//플랫폼별 게임 구분
export const PLATFORM_GAME_LIST = {
  MOBILE: [
    ACTIVEMENU.L2M,
    ACTIVEMENU.H3,
    ACTIVEMENU.TRICKSTERM,
    ACTIVEMENU.LINW,
    ACTIVEMENU.LMS,
    ACTIVEMENU.BNS2,
    ACTIVEMENU.JANRYUMONJP,
    ACTIVEMENU.LMA,
    ACTIVEMENU.HOYEON,
    ACTIVEMENU.BATTLECRUSH
  ],
  PC: [
    ACTIVEMENU.TL,
    ACTIVEMENU.LIN,
    ACTIVEMENU.LIN2,
    ACTIVEMENU.AION,
    ACTIVEMENU.BNS,
    ACTIVEMENU.LOVEBEAT,
    ACTIVEMENU.LIN2NCT,
    ACTIVEMENU.AIONNCT,
    ACTIVEMENU.BNSNCT,
    ACTIVEMENU.LINNCJ,
    ACTIVEMENU.AIONNCT,
    ACTIVEMENU.BNSNCT,
    ACTIVEMENU.LINNCJ,
    ACTIVEMENU.LIN2NCJ,
    ACTIVEMENU.AIONNCJ,
    ACTIVEMENU.BNSNCJ,
    ACTIVEMENU.LIN2NCW,
    ACTIVEMENU.AIONNCW,
    ACTIVEMENU.BNSNCW
  ]
}

//경험치 퍼센테이지 소숫점 자리
export const EXPRIENCE_FIXED_COUNT = {
  L2M: 5,
  H3: 0,
  TRICKSTERM: 5,
  BNS2: 4,
  LINW: 4,
  LMS: 5,
  TL: 5,
  HOYEON: 4
}

/*
  [TYPE CODE]
  ROOT : '홈'
  LOBBY : '로비'
  MEDIA : '영상'
  BOARD : '커뮤니티'
  NOTICE : '공지사항'
  UPDATE : '업데이트'
  SERVICE : 'FAQ'
 */

export const HOME_TYPE = {
  ROOT: 'ROOT',
  LOBBY: 'LOBBY',
  WEB: 'WEB',
  BOARD: 'BOARD',
  NOTICE: 'NOTICE',
  UPDATE: 'UPDATE',
  OPERATOR: 'OPERATOR',
  SERVICE: 'SERVICE',
  EVENT: 'EVENT'
}

// //로비 캐릭터 정보 에러 타입
// export const CHARACTER_ERROR_TYPE = {
//   AUTH: 'AuthError',
//   LIME: 'LimeMaintenance',
//   EMPTY: 'Empty',
//   DEFAULT: 'Error'
// }

//로비 캐릭터 정보 에러 타입
export const CHARACTER_ERROR_CODES = {
  AUTH: [401, 403],
  LIME: ['40904'], //라임점검
  EMPTY: ['40901', '40902', '40903', '40905']
}

export const LOBBY_LINK_TYPE = {
  BLANK: 'blank', // 새창열기
  LAUNCHER: 'launcher', // 런처 내부 웹
  BOARD_WEB_VIEW: 'boardWebView', // 게시판 웹뷰
  PURPLE_WEB: 'purpleWeb', // 퍼플웹
  MINI_GAME: 'miniGame',
  SCHEME: 'scheme'
}

export const AUTO_PLAY_GAME = {
  [GAME_CODE_UPPER.LMS]: i18n.t('Playing offline2'),
  [GAME_CODE_UPPER.L2M]: i18n.t('Playing offline2')
}

export const PC_CAFE_GAME_LIST = [
  GAME_CODE.TL,
  GAME_CODE.LIN,
  GAME_CODE.LIN2,
  GAME_CODE.AION,
  GAME_CODE.BNS,
  GAME_CODE.LOVEBEAT,
  GAME_CODE.L2M,
  GAME_CODE.BNS2,
  GAME_CODE.LMS,
  GAME_CODE.HOYEON,

  // NCJ
  GAME_CODE.AIONNCJ,
  GAME_CODE.LINNCJ,
  GAME_CODE.LIN2NCJ,
  GAME_CODE.BNSNCJ,
  GAME_CODE.JANRYUMONJP
]

export const BATTLECRUSH_COUNTRY_GROUP = {
  KR: ['KR'] //한국
}

export const BATTLECRUSH_CONTI_GROUP = {
  KR: ['KR'] //한국
}

export const TL_COUNTRY_GROUP = {
  KR: ['KR'], //한국
  SG: [
    COUNTRY_TYPE.SG,
    COUNTRY_TYPE.TW,
    COUNTRY_TYPE.HK,
    COUNTRY_TYPE.MO,
    COUNTRY_TYPE.ID,
    COUNTRY_TYPE.TH,
    COUNTRY_TYPE.MY,
    COUNTRY_TYPE.PH,
    COUNTRY_TYPE.KZ,
    COUNTRY_TYPE.UA,
    COUNTRY_TYPE.MD,
    COUNTRY_TYPE.RU,
    COUNTRY_TYPE.BY,
    COUNTRY_TYPE.AZ,
    COUNTRY_TYPE.AM,
    COUNTRY_TYPE.AE,
    COUNTRY_TYPE.QA,
    COUNTRY_TYPE.KW
  ]
}

export const TL_CONTI_GROUP = {
  KR: ['KR'], //한국
  SG: [
    COUNTRY_TYPE.SG,
    COUNTRY_TYPE.TW,
    COUNTRY_TYPE.HK,
    COUNTRY_TYPE.MO,
    COUNTRY_TYPE.ID,
    COUNTRY_TYPE.TH,
    COUNTRY_TYPE.MY,
    COUNTRY_TYPE.PH,
    COUNTRY_TYPE.KZ,
    COUNTRY_TYPE.UA,
    COUNTRY_TYPE.MD,
    COUNTRY_TYPE.RU,
    COUNTRY_TYPE.BY,
    COUNTRY_TYPE.AZ,
    COUNTRY_TYPE.AM,
    COUNTRY_TYPE.AE,
    COUNTRY_TYPE.QA,
    COUNTRY_TYPE.KW
  ]
}

export const LMS_COUNTRY_GROUP = {
  KR: ['KR'], //한국
  JP: ['JP'] //일본
}

export const L2M_COUNTRY_GROUP = {
  KR: ['KR'], //한국
  TW: ['TW', 'HK', 'MO'], //대만, 홍콩 , 마카오
  JP: ['JP'], //일본
  NU: [
    'US', //미국
    'CA', //캐나다
    'DE', //독일
    'GB', //영국
    'FR', //프랑스
    'BR', //브라질
    'IT', //이탈리아
    'ES', //스페인
    'CL', //칠레
    'NO', //노르웨이
    'SE', //스웨덴
    'IE', //아일랜드
    'PT', //포르투칼
    'FI', //핀란드
    'DK', //덴마크
    'CH', //스위스
    'AR', //아르헨티나
    'PE', //페루
    'MX', //멕시코
    'AA' // 언노운
  ],
  RU: [
    'RU', //러시아
    'AE', //아랍에미리트
    'UA', //우크라이나
    'PL', //폴란드
    'GR', //그리스
    'BY', //벨라루스
    'LT', //리투아니아
    'IL', //이스라엘
    'KW', //쿠웨이트
    'AT', //오스트리아
    'KZ', //카자흐스탄
    'UZ', // 우즈베키스탄
    'AZ', //아제르바이잔
    'GE', //조지아
    'MD', //몰도바
    'AM' //아르메니아
  ]
}

export const LMS_CONTI_GROUP = {
  KR: ['KR'], //한국
  JP: ['JP'] //일본
}

export const L2M_CONTI_GROUP = {
  KR: ['KR'], //한국
  TW: ['TW', 'HK', 'MO'], //대만, 홍콩 , 마카오
  JP: ['JP'], //일본
  NU: [
    'US', //미국
    'CA', //캐나다
    'DE', //독일
    'GB', //영국
    'FR', //프랑스
    'BR', //브라질
    'IT', //이탈리아
    'ES', //스페인
    'CL', //칠레
    'NO', //노르웨이
    'SE', //스웨덴
    'IE', //아일랜드
    'PT', //포르투칼
    'FI', //핀란드
    'DK', //덴마크
    'CH', //스위스
    'AR', //아르헨티나
    'PE', //페루
    'MX', //멕시코
    'AA' // 언노운
  ],
  'ru-RU': [
    'RU', //러시아
    'UA', //우크라이나
    'BY', //벨라루스
    'LT' //리투아니아
  ],
  'en-RU': [
    'AE', //아랍에미리트
    'PL', //폴란드
    'GR', //그리스
    'IL', //이스라엘
    'KW', //쿠웨이트
    'AT', //오스트리아
    'KZ', //카자흐스탄
    'UZ', // 우즈베키스탄
    'AZ', //아제르바이잔
    'GE', //조지아
    'MD', //몰도바
    'AM' //아르메니아
  ]
}

export const BNS2_COUNTRY_GROUP = {
  KR: ['KR'],
  JP: ['JP'],
  TW: ['TW', 'HK', 'MO']
}

export const BNS2_CONTI_GROUP = {
  KR: ['KR'],
  JP: ['JP'],
  TW: ['TW', 'HK', 'MO']
}

export const HOYEON_COUNTRY_GROUP = {
  KR: ['KR'],
  JP: ['JP'],
  TW: ['TW', 'HK', 'MO']
}

export const HOYEON_CONTI_GROUP = {
  KR: ['KR'],
  JP: ['JP'],
  TW: ['TW', 'HK', 'MO']
}

export const NLW_COUNTRY_GROUP = {
  KR: ['KR'],
  JP: ['JP'],
  TW: ['TW', 'HK', 'MO'],
  US: [] // etc 국가 US로
}

export const NLW_CONTI_GROUP = {
  KR: ['KR'],
  JP: ['JP'],
  TW: ['TW', 'HK', 'MO'],
  US: [] // etc 국가 US로
}

export const LIN_COUNTRY_GROUP = {
  KR: ['KR'],
  JP: ['JP']
}

export const LIN2_COUNTRY_GROUP = {
  KR: ['KR'],
  TW: ['TW', 'HK', 'MO'],
  JP: ['JP'],
  US: [
    'US',
    'CA',
    'MX',
    'BR',
    'CO',
    'AR',
    'PE',
    'CL',
    'EC',
    'AU',
    'NZ',
    'SG',
    'MY',
    'PH',
    'AA'
  ]
}

export const AION_COUNTRY_GROUP = {
  KR: ['KR'],
  TW: ['TW', 'HK', 'MO'],
  JP: ['JP'],
  US: ['US', 'CA', 'MX', 'BR', 'CO', 'AR', 'PE', 'CL', 'EC', 'AU', 'NZ', 'AA']
}

export const BNS_COUNTRY_GROUP = {
  KR: ['KR'],
  TW: ['TW', 'HK', 'MO'],
  JP: ['JP'],
  US: [
    'US',
    'CA',
    'MX',
    'BR',
    'CO',
    'AR',
    'PE',
    'CL',
    'EC',
    'AU',
    'NZ',
    'DE',
    'IE',
    'SE',
    'NL',
    'CH',
    'IT',
    'GR',
    'DK',
    'FI',
    'LT',
    'PT',
    'ES',
    'AT',
    'RO',
    'TR',
    'FR',
    'GB',
    'CZ',
    'BE',
    'AA'
  ]
}

export const LINW_COUNTRY_GROUP = {
  KR: [COUNTRY_TYPE.KR],
  JP: [COUNTRY_TYPE.JP],
  HK: [COUNTRY_TYPE.HK],
  TW: [COUNTRY_TYPE.TW],
  SG: [
    COUNTRY_TYPE.ID,
    COUNTRY_TYPE.TH,
    COUNTRY_TYPE.MY,
    COUNTRY_TYPE.SG,
    COUNTRY_TYPE.PH
  ],
  AE: [COUNTRY_TYPE.SA, COUNTRY_TYPE.AE, COUNTRY_TYPE.QA]
}

export const LIN_CONTI_GROUP = {
  KR: ['KR'],
  JP: ['JP']
}

export const LIN2_CONTI_GROUP = {
  KR: ['KR'],
  TW: ['TW', 'HK', 'MO'],
  JP: ['JP'],
  US: [
    'US',
    'CA',
    'MX',
    'BR',
    'CO',
    'AR',
    'PE',
    'CL',
    'EC',
    'AU',
    'NZ',
    'SG',
    'MY',
    'PH',
    'AA'
  ]
}

export const AION_CONTI_GROUP = {
  KR: ['KR'],
  TW: ['TW', 'HK', 'MO'],
  JP: ['JP'],
  US: ['US', 'CA', 'MX', 'BR', 'CO', 'AR', 'PE', 'CL', 'EC', 'AU', 'NZ', 'AA']
}

export const BNS_CONTI_GROUP = {
  KR: ['KR'],
  TW: ['TW', 'HK', 'MO'],
  JP: ['JP'],
  US: [
    'US',
    'CA',
    'MX',
    'BR',
    'CO',
    'AR',
    'PE',
    'CL',
    'EC',
    'AU',
    'NZ',
    'DE',
    'IE',
    'SE',
    'NL',
    'CH',
    'IT',
    'GR',
    'DK',
    'FI',
    'LT',
    'PT',
    'ES',
    'AT',
    'RO',
    'TR',
    'FR',
    'GB',
    'CZ',
    'BE',
    'AA'
  ]
}

export const LINW_CONTI_GROUP = {
  KR: [COUNTRY_TYPE.KR],
  JP: [COUNTRY_TYPE.JP],
  HK: [COUNTRY_TYPE.HK],
  TW: [COUNTRY_TYPE.TW],
  SG: [
    COUNTRY_TYPE.ID,
    COUNTRY_TYPE.TH,
    COUNTRY_TYPE.MY,
    COUNTRY_TYPE.SG,
    COUNTRY_TYPE.PH
  ],
  AE: [COUNTRY_TYPE.SA, COUNTRY_TYPE.AE, COUNTRY_TYPE.QA]
}

export const BATTLECRUSH_LANG_GROUP = {
  KR: LANG_TYPE.KO_KR //한국 - 한국어
}

export const TL_LANG_GROUP = {
  KR: LANG_TYPE.KO_KR, //한국 - 한국어
  SG: LANG_TYPE.EN_US //영어
}

export const BNS2_LANG_GROUP = {
  KR: LANG_TYPE.KO_KR, //한국 - 한국어
  TW: LANG_TYPE.ZH_TW, //대만 - 번체
  JP: LANG_TYPE.JA_JP //대만 - 번체
}

export const LMS_LANG_GROUP = {
  KR: LANG_TYPE.KO_KR,
  JP: LANG_TYPE.JA_JP
}

export const L2M_LANG_GROUP = {
  KR: LANG_TYPE.KO_KR, //한국
  TW: LANG_TYPE.ZH_TW, //대만
  JP: LANG_TYPE.JA_JP, //일본
  NU: LANG_TYPE.EN_US, //영어
  'ru-RU': LANG_TYPE.RU_RU, //러시아어
  'en-RU': LANG_TYPE.EN_US //영어
}

export const LIN_LANG_GROUP = {
  KR: LANG_TYPE.KO_KR, //한국 - 한국어
  JP: LANG_TYPE.JA_JP //일본
}

export const LIN2_LANG_GROUP = {
  KR: LANG_TYPE.KO_KR, //한국 - 한국어
  JP: LANG_TYPE.JA_JP, //일본
  TW: LANG_TYPE.ZH_TW, //대만 - 번체
  US: LANG_TYPE.EN_US,
  DE: LANG_TYPE.DE_DE, // 독일
  FR: LANG_TYPE.FR_FR // 불어
}

export const AION_LANG_GROUP = {
  KR: LANG_TYPE.KO_KR, //한국 - 한국어
  JP: LANG_TYPE.JA_JP, //일본
  TW: LANG_TYPE.ZH_TW, //대만 - 번체
  US: LANG_TYPE.EN_US,
  DE: LANG_TYPE.DE_DE, // 독일
  FR: LANG_TYPE.FR_FR // 불어
}

export const BNS_LANG_GROUP = {
  KR: LANG_TYPE.KO_KR, //한국 - 한국어
  JP: LANG_TYPE.JA_JP, //일본
  TW: LANG_TYPE.ZH_TW, //대만 - 번체
  US: LANG_TYPE.EN_US,
  DE: LANG_TYPE.DE_DE, // 독일
  FR: LANG_TYPE.FR_FR // 불어
}

export const LINW_LANG_GROUP = {
  KR: LANG_TYPE.KO_KR, //한국 - 한국어
  JP: LANG_TYPE.JA_JP, //일본
  TW: LANG_TYPE.ZH_TW, //대만 - 번체
  HK: LANG_TYPE.ZH_TW,
  SG: LANG_TYPE.EN_US, // 영어
  AE: LANG_TYPE.AR_SA // 아랍어
}

export const HOYEON_LANG_GROUP = {
  KR: LANG_TYPE.KO_KR, //한국 - 한국어
  TW: LANG_TYPE.ZH_TW, //대만 - 번체
  JP: LANG_TYPE.JA_JP //대만 - 번체
}

export const NLW_LANG_GROUP = {
  KR: LANG_TYPE.KO_KR, //한국 - 한국어
  TW: LANG_TYPE.ZH_TW, //대만 - 번체
  JP: LANG_TYPE.JA_JP, //대만 - 번체
  US: LANG_TYPE.EN_US
}

export const LOBBY_BG_KEY = {
  NCK: 'lobbyBg',
  NCT: 'lobbyBgNCT',
  NCJ: 'lobbyBgNCJ',
  NCW: 'lobbyBgNCW'
}

export const LOBBY_LOGO_KEY = {
  NCK: 'lobbyLogo',
  NCT: 'lobbyLogoNCT',
  NCJ: 'lobbyLogoNCJ',
  NCW: 'lobbyLogoNCW'
}
